import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IconBrandWhatsapp } from '@tabler/icons-react';
import NavbarCategories from './components/Navbarcategories';
import Footer from './components/Footer';

interface ProductCategory {
  m04_category_name: string;
}

interface Product {
  id: string;
  m06_thumbnail_image: string;
  m06_product_sku_name: string;
  m06_description: string;
  productCategories?: ProductCategory[];
  m06_price: number;
  m06_mrp: number;
}

const Allproducts: React.FC = () => {
  const { categoryId } = useParams<{ categoryId: string }>();
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();
  const whatsappNumber = '+971505411910'; // WhatsApp Number for Inquiry

  const handleChange = (id: string) => {
    navigate(`/product/${id}`);
  };

  useEffect(() => {
    // Scroll to the top when the route changes
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);

        const params: Record<string, any> = { page: 1, limit: 20 };
        if (categoryId) {
          params.categoryId = categoryId;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL_CUSTOMER}/product-sku`,
          { params }
        );

        if (response.data?.success) {
          setProducts(response.data.data.products_skus || []);
          setError(null);
        } else {
          throw new Error('Failed to fetch products');
        }
      } catch (error) {
        console.error('Error fetching products:', error);
        setError('Failed to load products. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [categoryId]);

  if (loading) {
    return <p className='min-h-[110vh]'>Loading products...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <>
    <div className="all-products bg-gray-50 p-6 relative min-h-[110vh] mt-10">

      {/* {categoryId ? ( */}
        <div className="md:py-5 py-2">
          <NavbarCategories />
        </div>
      {/* ) : ""} */}

      {/* Background Effects */}
      <div className='absolute md:block hidden w-40 h-40 bg-gray-500 z-30 rounded-full blur-[80px]'></div>
      <div className='absolute w-40 h-40 right-10 bottom-24 z-30 bg-gray-500 rounded-full blur-[80px]'></div>
      <div className='absolute w-40 h-40 right-[50%] top-[40%] z-30 bg-gray-500 rounded-full blur-[80px]'></div>
      <div className='absolute md:block hidden w-40 h-40 right-0 top-10 z-30 bg-gray-500 rounded-full blur-[80px]'></div>

      {/* Video and Header */}


      {/* Category Name Header */}
      {categoryId && products.length > 0 && (
        <h1 className="col-span-full text-3xl font-semibold text-center text-gray-800 mb-6">
          {products[0]?.productCategories?.[0]?.m04_category_name || 'Category Name'}
        </h1>
      )}

      <div
        className={`grid gap-4 md:gap-8 relative ${categoryId
          ? 'grid-cols-1 md:grid-cols-2 lg:grid-cols-4'
          : 'grid-cols-1 md:grid-cols-2 lg:grid-cols-4'
          }`}
      >
        {categoryId && (
          <div className='col-span-1 md:col-span-2 flex justify-center items-center'>
            <iframe
              width="100%"
              height="350"
              src="https://www.youtube.com/embed/ioZmKJG_SME?si=65d4WfODItlOPcTx&autoplay=1&mute=1&loop=1&playlist=ioZmKJG_SME"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
              className="rounded-lg"
            ></iframe>

          </div>
        )}
        {/* Product Cards */}
        {products.map((product) => (
          <div
            key={product.id}
            className="min-w-[280px] z-40 md:min-w-0 bg-blue-50 md:shadow-xl rounded-lg flex flex-col justify-between cursor-pointer snap-start"
            onClick={() => navigate(`/product/${product.id}`)}
          >
            <div className="w-full h-40 p-3">
              <img
                src={`${process.env.REACT_APP_BACKEND_URL}/${product.m06_thumbnail_image}`}
                alt={product.m06_product_sku_name}
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
            <div className="px-4 pb-3 flex-grow flex flex-col justify-between">
              <div>
                <h3 className="text-lg font-semibold text-gray-900 mb-1">
                  {product.m06_product_sku_name.substring(0, 20)}
                  {product.m06_product_sku_name.length > 20 ? '...' : ''}
                </h3>
                <p className="text-gray-700 my-2 text-sm">
                  {product.m06_description.substring(0, 70)}
                  {product.m06_description.length > 70 ? '...' : ''}
                </p>
              </div>
              <div>
                <div className="font-bold flex justify-between items-center mb-2 text-black">
                  <span className="text-sm text-gray-500 line-through">
                    AED {product.m06_mrp.toFixed(2)}
                  </span>
                  <p className="text-lg text-black">AED {product.m06_price.toFixed(2)}</p>
                </div>
                <Link
                  to={`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
                    `✨ *Product Inquiry* ✨\n\n📦 *Product Name:* ${product.m06_product_sku_name}\n🔗 *Product Link:* ${window.location.origin}/product/${product.id}\n\n❓ *Is this product still available?* \n🚚 *Could you let me know if there are any extra shipping charges?* \n\nLooking forward to your response! 😊`
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="px-4 py-2 flex justify-center items-center bg-red-800 text-white font-bold rounded-md hover:bg-opacity-80 text-base"
                >
                  <IconBrandWhatsapp size={20} className="mr-2" /> Order on WhatsApp
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Allproducts;

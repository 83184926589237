import React from 'react'
import HomeSection from './homeSection'

export default function NewHome() {
  return (
    <div>
        {/* <SubNavbar/> */}
        <HomeSection/>
    </div>
  )
}

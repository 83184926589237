import { useMutation, useQuery } from "@tanstack/react-query"
import { restAPI } from "./axiosAPI"
import { useState } from "react"
import { backendPagination } from "../../types/pagination"

export type ProductCategoryData = {
    id?: number,
    m04_category_name: string,
    m04_image: string|null|File,
    m04_m04_parent_category_id: string|null,
    m04_is_active: 1|0,
    m04_deleted_at?: null,
    m04_description?:string
    createdAt?: string,
    updatedAt?: string
}
const QUERY_KEY = 'product-category'

export function useProductCategoryGetAllAPI({
    page_size,
    page,
    retryCount,
    trigger = true
}: {
    page_size?: number,
    page?: number,
    retryCount?: number,
    trigger?: boolean
}) {
    const api = restAPI()
    const [totalProductCategoryCount,setTotalProductCategoryCount] = useState(0)

    const {
        data: productCategories,
        isLoading,
        isError,
        refetch: refetchProductCategories,
        isFetching,
        isFetched,
        error
    } = useQuery({
        queryKey: [QUERY_KEY, page_size, page],
        queryFn: async () => {
            const response = await api.get<{ data: {
                productCategories:ProductCategoryData[],
                pagination:backendPagination
            } }>({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/product-category`,
                mode: 'protected',
                params: {
                    limit:page_size,
                    page
                },
                withCredentials:true
            })
            if (response.success === false) {
                throw response.msg
            }
            setTotalProductCategoryCount(response.data.pagination.totalPages)
            return response.data.productCategories
        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger

    })

    return {
        isLoading,
        isError,
        productCategories,
        totalProductCategoryCount,
        refetch: refetchProductCategories,
        isFetching,
        isFetched,
        error
    }

}

export function useProductCategoryAPI({
    
}: {
}) {

    const api = restAPI()
    const [totalProductCategoryCount, setTotalProductCategoryCount] = useState(0)




    const { mutateAsync: createProductCategory } = useMutation({

        
        mutationFn: async (ProductCategory: ProductCategoryData) => {
            const formData = new FormData();
            Object.keys(ProductCategory).forEach(key => {
                if (key === 'm04_image') {
                    if (ProductCategory[key] instanceof File) {
                        formData.append(key, ProductCategory[key] as File);
                    }
                }  else {
                    // Append all other data as string
                    const value = ProductCategory[key as keyof ProductCategoryData];
                    formData.append(key, value !== null && value !== undefined ? String(value) : '');
                }
            })
            const response = await api.post<{ access: string, refresh: string }, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/product-category`,
                data: formData,
                mode: 'protected',
                withCredentials:true
            })


            if (response.success === false) {
                return response
            }
            return response
        }
    })
    const { mutateAsync: updatedProductCategory, isPending: isUpdating } = useMutation({
        mutationFn: async ({
            id,
            ProductCategory,
            isImageDeleted
        }:{
            id:string,
            ProductCategory:ProductCategoryData,
            isImageDeleted?:1|0
        }) => {

            const formData = new FormData();
            Object.keys(ProductCategory).forEach(key => {
                if (key === 'm04_image') {
                    if (ProductCategory[key] instanceof File) {
                        formData.append(key, ProductCategory[key] as File);
                    }
                }  else {
                    // Append all other data as string
                    const value = ProductCategory[key as keyof ProductCategoryData];
                    formData.append(key, value !== null && value !== undefined ? String(value) : '');
                }
            })

            if(isImageDeleted===1){
                formData.append('isImageDeleted',String(isImageDeleted))
            }

            const response = await api.put<{ access: string, refresh: string }, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/product-category/${id}`,
                data: formData,
                mode: 'protected',
                withCredentials:true
            })


            if (response.success === false) {
                return response
            }
            return response
        }
    })

    const { mutateAsync: deleteProductCategory,isPending:isDeleting } = useMutation({
        mutationFn: async (id: string) => {
            const response = await api.delete({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/product-category/${id}`,
                mode: 'protected',
                withCredentials:true
            })


            if (response.success === false) {
                return response
            }
            return response
        }
    })
    const { mutateAsync: readProductCategoryById,isPending:isReadingById } = useMutation({
        mutationFn: async ({
            id
        }:{
            id:string
        }) => {
            const response = await api.get<{data:ProductCategoryData}>({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/product-category/${id}`,
                mode: 'protected',
                withCredentials:true
            })


            if (response.success === false) {
                return response
            }
            return response
        }
    })


    return {
        totalProductCategoryCount,
        createProductCategory,
        updatedProductCategory,
        isUpdating,
        readProductCategoryById,
        isReadingById,
        deleteProductCategory,
        isDeleting,
    }


}


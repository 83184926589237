import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { IconBarcode, IconDeviceCctv, IconDeviceImac, IconDeviceLaptop, IconDeviceMobile, IconDeviceRemote, IconPrinter, IconQrcode, IconScan } from '@tabler/icons-react';

export default function HomeSection() {
    const [isMobile, setIsMobile] = useState(false);
    const [progress, setProgress] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);

    const Datas = [
        {
            content: 'Welcome to ',
            src: '/assets/product.mp4'
        },
        {
            src: {
                mobile: '/assets/mobile.mp4',
                desktop: '/assets/desktop.mp4'
            }
        },
        {
            src: '/assets/itinfra_01.mp4'
        }
    ];

    const number = "+971505411910";
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const textContainerRef = useRef<HTMLDivElement | null>(null);
    const sectionRef = useRef<HTMLDivElement | null>(null);
    const touchStartX = useRef<number | null>(null);
    const touchEndX = useRef<number | null>(null);

    useEffect(() => {
        const checkScreenSize = () => {
            setIsMobile(window.innerWidth < 640);
        };

        checkScreenSize();
        window.addEventListener('resize', checkScreenSize);
        return () => window.removeEventListener('resize', checkScreenSize);
    }, []);

    const getVideoSource = (data: any) => {
        if (data.src.mobile && data.src.desktop) {
            return isMobile ? data.src.mobile : data.src.desktop;
        }
        return data.src;
    };

    const handleVideoTransition = (nextIndex: number) => {
        if (isTransitioning) return;
    
        const currentVideo = videoRef.current;
    
        if (currentVideo) {
            currentVideo.src = getVideoSource(Datas[nextIndex]);
            currentVideo.play().catch((error) => {
                console.error("Error playing video:", error);
            });
        }
    
        setCurrentVideoIndex(nextIndex);
    };
    
    

    const handleNext = () => {
        if (!isTransitioning) {
            const nextIndex = (currentVideoIndex + 1) % Datas.length;
            handleVideoTransition(nextIndex);
        }
    };
    
    const handlePrev = () => {
        if (!isTransitioning) {
            const nextIndex = (currentVideoIndex - 1 + Datas.length) % Datas.length;
            handleVideoTransition(nextIndex);
        }
    };
    
    const handleProgressBarClick = (index: number) => {
        if (index !== currentVideoIndex && !isTransitioning) {
            handleVideoTransition(index);
        }
    };
    
    useEffect(() => {
        const videoElement = videoRef.current;

        const handleVideoTimeUpdate = () => {
            if (videoElement) {
                const progress = (videoElement.currentTime / videoElement.duration) * 100;
                setProgress(progress);

                // Preload next video when current video is near end
                const remainingTime = videoElement.duration - videoElement.currentTime;
                if (remainingTime <= 1.5 && !isTransitioning) {
                    const nextIndex = (currentVideoIndex + 1) % Datas.length;
                    const preloadVideo = document.createElement('video');
                    preloadVideo.src = getVideoSource(Datas[nextIndex]);
                    preloadVideo.load();
                }
            }
        };

        const handleVideoEnd = () => {
            if (!isTransitioning) {
                handleNext();
            }
        };

        if (videoElement) {
            videoElement.addEventListener('timeupdate', handleVideoTimeUpdate);
            videoElement.addEventListener('ended', handleVideoEnd);
        }

        return () => {
            if (videoElement) {
                videoElement.removeEventListener('timeupdate', handleVideoTimeUpdate);
                videoElement.removeEventListener('ended', handleVideoEnd);
            }
        };
    }, [currentVideoIndex, Datas, isMobile, isTransitioning]);

    useEffect(() => {
        if (currentVideoIndex === 0 && textContainerRef.current) {
            const textContainer = textContainerRef.current;
            const characters = Array.from(textContainer.querySelectorAll('.char'));

            const timeline = gsap.timeline();
            timeline.fromTo(
                characters,
                { opacity: 0, y: 20 },
                {
                    opacity: 1,
                    y: 0,
                    duration: 0.5,
                    stagger: 0.1,
                    ease: 'power3.out',
                }
            );
        }
    }, [currentVideoIndex]);

    useEffect(() => {
        if (currentVideoIndex === 1 && sectionRef.current) {
            const section = sectionRef.current;
            gsap.fromTo(
                section,
                { opacity: 0, y: 50 },
                {
                    opacity: 1,
                    y: 0,
                    duration: 1,
                    ease: 'power3.out',
                }
            );
        }
    }, [currentVideoIndex]);

    // const handleProgressBarClick = (index: number) => {
    //     if (index !== currentVideoIndex && !isTransitioning) {
    //         handleVideoTransition(index);
    //     }
    // };

    useEffect(() => {
        const handleTouchStart = (e: TouchEvent) => {
            touchStartX.current = e.touches[0].clientX;
        };

        const handleTouchMove = (e: TouchEvent) => {
            touchEndX.current = e.touches[0].clientX;
        };

        const handleTouchEnd = () => {
            if (touchStartX.current && touchEndX.current) {
                const diff = touchStartX.current - touchEndX.current;
                if (diff > 50) {
                    handleNext();
                } else if (diff < -50) {
                    handlePrev();
                }
            }
        };

        const section = sectionRef.current;

        if (section) {
            section.addEventListener('touchstart', handleTouchStart);
            section.addEventListener('touchmove', handleTouchMove);
            section.addEventListener('touchend', handleTouchEnd);
        }

        return () => {
            if (section) {
                section.removeEventListener('touchstart', handleTouchStart);
                section.removeEventListener('touchmove', handleTouchMove);
                section.removeEventListener('touchend', handleTouchEnd);
            }
        };
    }, [isTransitioning]);

    return (
        <div className="h-screen relative overflow-hidden">
            <div
                className='absolute md:right-10 h-10 w-10 cursor-pointer opacity-[0.8] right-3 z-50 md:bottom-10 bottom-16'
                onClick={() => {
                    const whatsappUrl = `https://wa.me/${number}?text=Hello`;
                    window.location.href = whatsappUrl;
                }}
            >
                <img src="/assets/whatsapp.png" className='w-full h-full' alt="WhatsApp" />
            </div>

            <video
                ref={videoRef}
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={getVideoSource(Datas[currentVideoIndex])}
                autoPlay
                loop={false}
                muted
                playsInline
            ></video>

            {/* Progress indicators */}
            <div className="absolute z-50 bg-transparent flex md:flex-row flex-col gap-2 transition-all duration-300"
                style={{
                    left: isMobile ? '97%' : '45%',
                    bottom: isMobile ? '50%' : '40px',
                    transform: isMobile ? 'translateY(50%)' : 'none'
                }}>
                {Datas.map((_, index) => (
                    <div
                        key={index}
                        onClick={() => handleProgressBarClick(index)}
                        className={`cursor-pointer rounded-full bg-red-900 relative overflow-hidden transition-all duration-300 ${isMobile ? 'w-1 h-8' : 'h-1 w-8'
                            }`}
                    >
                        {index === currentVideoIndex && (
                            <div
                                className={`absolute bg-gray-400 transition-all duration-300 ${isMobile ? 'w-full left-0 top-0' : 'h-full top-0 left-0'
                                    }`}
                                style={{
                                    [isMobile ? 'height' : 'width']: `${progress}%`
                                }}
                            ></div>
                        )}
                    </div>
                ))}
            </div>

            <div className="hidden md:flex absolute top-1/2 left-5 z-50 cursor-pointer justify-center items-center bg-[#ffffff35] p-2 rounded-full" onClick={handlePrev}>
                <button className="text-gray-200 text-xl">&#8592;</button>
            </div>
            <div className="hidden md:flex justify-center items-center p-2 rounded-full bg-[#ffffff35] absolute top-1/2 right-5 z-50 cursor-pointer" onClick={handleNext}>
                <button className="text-gray-200 text-xl">&#8594;</button>
            </div>

            <div
                className={`relative z-40 flex flex-col h-full transition-all duration-500 ${currentVideoIndex === 3 ? 'items-end pe-5' : 'items-center'} ${currentVideoIndex === 2 ? 'justify-end' : 'justify-center'}`}
            >
                <h1 className={`text-white opacity-[0.3] text-xl md:text-4xl z-40 font-bold mb-3 md:mb-0 ${currentVideoIndex === 0 ? 'mt-72 md:mt-0' : 'mt-0'}`}>
                    {Datas[currentVideoIndex].content || ""}
                </h1>

                {currentVideoIndex === 2 && (
                    <div
                        ref={sectionRef}
                        className="w-full flex min-h-screen justify-start ps-10 items-end"
                    >
                        <h1 className="md:text-5xl text-xl font-poppins mb-32 text-[#ffffffae]">"TRIOS International"</h1>
                    </div>
                )}

                {currentVideoIndex === 2 && (
                    <div className='md:block hidden'>
                        <div className='absolute top-52 left-28 text-[#ffffff28] z-40 animate-spin-slow'>
                            <IconDeviceImac stroke={2} size={80} />
                        </div>
                        <div className='absolute bottom-32 right-32 text-[#ffffff28] z-40 animate-bounce-custom'>
                            <IconDeviceLaptop stroke={2} size={80} />
                        </div>
                        <div
                            className="absolute top-16 right-[60%] text-[#ffffff28] z-40 transition-all animate-bounce-custom">
                            <IconDeviceMobile stroke={2} size={80} />
                        </div>

                        <div className='absolute bottom-16 left-[30%] text-[#ffffff28] z-40 transition-all animate-spin-slow'>
                            <IconDeviceRemote stroke={2} size={80} />
                        </div>

                        <div className='absolute bottom-72 left-[45%] text-[#ffffff28] z-40 transition-all animate-spin-slow'>
                            <IconDeviceCctv stroke={2} size={80} />
                        </div>

                        <div className='absolute bottom-96 left-[70%] text-[#ffffff28] z-40 transition-all animate-spin-slow'>
                            <IconPrinter stroke={2} size={80} />
                        </div>

                        <div className='absolute bottom-0 left-10 text-[#ffffff28] z-40 transition-all animate-spin-slow'>
                            <IconScan stroke={2} size={80} />
                        </div>

                        <div className='absolute right-16 top-20 text-[#ffffff28] z-40 transition-all animate-spin-slow'>
                            <IconBarcode stroke={2} size={80} />
                        </div>

                        <div className='absolute right-72 bottom-5 text-[#ffffff28] z-40 transition-all animate-spin-slow'>
                            <IconQrcode stroke={2} size={80} />
                        </div>
                    </div>
                )}

                {currentVideoIndex === 0 && (
                    <div
                        ref={textContainerRef}
                        className="text-white z-40 relative md:text-7xl text-3xl font-bold flex flex-col md:flex-row md:gap-10 items-center md:items-start"
                    >
                        <div className="flex space-x-2 opacity-[0.4]">
                            {"Trios".split("").map((char, index) => (
                                <span key={index} className="char">
                                    {char.trim() === "" ? "\u00A0" : char}
                                </span>
                            ))}
                        </div>
                        <div className="flex space-x-2 opacity-[0.4]">
                            {"International".split("").map((char, index) => (
                                <span key={index} className="char">
                                    {char.trim() === "" ? "\u00A0" : char}
                                </span>
                            ))}
                        </div>
                    </div>
                )}
            </div>

            <div className={`absolute top-0 left-0 w-full h-full ${currentVideoIndex === 3 ? 'bg-black/25' : 'bg-black/50'}`}></div>
        </div>
    );
}
"use client"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import "react-day-picker/style.css";
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { LayoutRoot } from './pages/LayoutRoot/layoutRoot';
import { ProductCategoryPage } from "./pages/LayoutRoot/pages/Master/Product_category/page.product_category";
import { ProductSKUPage } from "./pages/LayoutRoot/pages/Master/SKU/page.product_sku";
import LoginPage from './pages/loginPage/Loginpage';
import { FeaturedProductPage } from "./pages/LayoutRoot/pages/Master/featuredProducts/page.featuredProducts";
import { EnquiryPage } from "./pages/LayoutRoot/pages/Master/enquiry/page.enquiry";


const queryClient = new QueryClient();

export function AdminRoot() {

    // const configuration = useConfigurationRQ()
    const [documentAvailable, setDocAvailable] = useState(false)

    useEffect(() => {
        setDocAvailable(true)
    }, [])

    return (
        <QueryClientProvider client={queryClient}>

            {
                <Routes>
                    <Route index element={<Navigate to='home' replace />} />
                    <Route path="login" element={<LoginPage />} />
                    <Route path="home/*" element={<LayoutRoot />} >
                        <Route index element={<Navigate to={'dashboard'} replace />} />
                        <Route path='master/product-category' element={<ProductCategoryPage />} />
                        <Route path='master/product-sku' element={<ProductSKUPage />} />
                        <Route path='master/featured-products' element={<FeaturedProductPage />} />
                        <Route path='master/enquiry' element={<EnquiryPage />} />

                    </Route>

                </Routes>
            }
        </QueryClientProvider>
    );
}



import { IconX } from "@tabler/icons-react";
import { ButtonRounded } from "./buttons";

interface ImageBoxProps {
    imagelink: string;
    height: number;
    width: number;
    onDelete: () => void;
    disabled?: boolean
}

export const ImageBox: React.FC<ImageBoxProps> = ({
    imagelink,
    height,
    width,
    onDelete,
    disabled = false,
}) => {
    return (
        <div className="relative">
            <img
                src={imagelink}
                className="block w-full h-full"
                style={{ maxHeight: height, maxWidth: width,height,width }}
                alt=""
            />
            <ButtonRounded
                icon={<IconX />}
                onClick={(e)=>{
                    e?.preventDefault()
                    onDelete()
                }}
                disabled={disabled}
            />
        </div>
    );
}

import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function MissionVision() {
  const missionRef = useRef(null);
  const visionRef = useRef(null);

  useEffect(() => {
    // Animate the "Mission" section
    gsap.fromTo(
      missionRef.current,
      { y: 100, opacity: 1 }, // Start from below (y: 100)
      {
        y: 0,
        opacity: 1,
        duration: 1.5,
        ease: "power3.out",
        scrollTrigger: {
          trigger: missionRef.current,
          start: "top 80%",
          end: "bottom 20%",
          scrub: true, // Makes animation respond to scroll position
        },
      }
    );

    // Animate the "Vision" section
    gsap.fromTo(
      visionRef.current,
      { y: 100, opacity: 1 }, // Start from below (y: 100)
      {
        y: 0,
        opacity: 1,
        duration: 1.5,
        ease: "power3.out",
        scrollTrigger: {
          trigger: visionRef.current,
          start: "top 80%",
          end: "bottom 20%",
          scrub: true, // Makes animation respond to scroll position
        },
      }
    );
  }, []);

  return (
    <div className="max-w-7xl relative mx-auto px-5 md:px-20 py-12 md:py-10 bg-gray-200">
      <div className='absolute w-52 h-52 bg-gray-900 top-0 left-0 z-30 rounded-full blur-[120px]'></div>
      {/* <div className='absolute w-52 h-52 right-10 bottom-0 z-30 bg-gray-900 rounded-full blur-[100px]'></div> */}
      <div className='absolute w-52 h-52 right-[50%] top-[40%] z-30 bg-gray-900 rounded-full blur-[120px]'></div>
      <div className='absolute w-52 h-52 right-0 top-40 z-30 bg-gray-900 rounded-full blur-[120px]'></div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-20 relative md:pb-20">
        {/* Mission Section */}
        <div
          ref={missionRef}
          className="flex z-40 flex-col items-center text-center md:items-start md:text-left relative top-0"
        >
          <h4 className="text-red-500 z-40 font-medium text-sm uppercase tracking-wider mb-2">
            Mission
          </h4>
          <h2 className="text-black text-3xl font-bold md:mb-4 mb-6 z-40">Who We Are</h2>
          <p className="text-black z-40 leading-relaxed md:mb-6 mb-8 text-start md:pe-8">
          We are concentrating of all our capacities on developing customer-oriented tailored Solutions and offering fast and accurate maintenance and repair service.
          </p>
          <img
            src="/assets/mission_10.jpg"
            alt="Who We Are Placeholder"
            className="rounded-lg shadow-lg shadow-black w-full max-w-md md:h-72 h-52 bg-cover"
          />
        </div>

        {/* Vision Section */}
        <div
          ref={visionRef}
          className="flex flex-col items-center text-center md:items-start md:text-left relative"
        >
          <h4 className="text-red-500 font-medium text-sm uppercase tracking-wider mb-2">
            Vision
          </h4>
          <h2 className="text-gray-800 text-3xl font-bold md:mb-4 mb-6">Why Us</h2>
          <p className="text-gray-600 leading-relaxed md:mb-6 mb-8 text-start md:pe-8">
          To deliver quality & efficient technology solutions tailored to the retail industry, ensuring streamlined while prioritizing customer satisfaction of our mission
          </p>
          <img
            src="/assets/vision_10.jpg"
            alt="Why Us Placeholder"
            className="rounded-lg shadow-lg shadow-black w-full max-w-md md:h-72 h-52"
          />
        </div>
      </div>
    </div>
  );
}

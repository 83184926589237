
import React from "react"
import { StandardTableOptionButton } from "../../../../../components/standard.table"
import { IconEye, IconPencil, IconTools, IconTrash } from "@tabler/icons-react"
import { Popover, PopoverContent, PopoverTrigger } from "../../../../../../shadcn/ui/popover";

export interface DropdownMenuDemoProps {
    rowId: string,
    onEditAction: (rowId: string) => void;
}
export interface DropdownMenuParentProps {
    rowId: string,
    onEditAction: (rowId: string) => void;
}

function DropdownMenuItem({
    icon,
    text,
    onClick
}: {
    icon: React.ReactNode,  //recomended tabler icon size 20
    text: string,
    onClick?: () => any
}) {
    return (
        <button onClick={onClick} className=" w-full text-vstargray-800 hover:text-vstargray-100 flex rounded-md bg-maxgray-100 hover:bg-max-green-500  items-center gap-2 px-3 py-1">
            {/* icon */}
            {icon}
            {/* text */}
            <p className=" text-xs">{text}</p>
        </button>
    )
}

export function DropdownMenuParent({ rowId, onEditAction }: DropdownMenuParentProps) {

    const [openDrowdownMenu, setOpenDropdownMenu] = React.useState(false)



    return (
        <Popover open={openDrowdownMenu} onOpenChange={setOpenDropdownMenu}  >
            <PopoverTrigger asChild>
                <div onClick={(e) => {
                    e.stopPropagation();
                    setOpenDropdownMenu(!openDrowdownMenu)
                }}><StandardTableOptionButton /></div>
            </PopoverTrigger >
            <PopoverContent onOpenAutoFocus={(e) => e?.preventDefault()} className="w-[120px] bg-maxgray-100 p-1">
                <div

                    className="w-full flex flex-col gap-1">
                    <div
                        className=" w-full flex"
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpenDropdownMenu(!openDrowdownMenu);
                            onEditAction(rowId);
                        }}
                    >
                        <DropdownMenuItem icon={<IconEye size={20} />} text="View" />
                    </div>
                </div>
            </PopoverContent>
        </Popover>
    )
}




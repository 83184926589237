import React from 'react'
import SideNavbar from '../../components/side_navBar'
import NavBar from '../../components/nav_bar'
import { Outlet } from 'react-router-dom'
import { ProtectedRoute } from '../../components/protected_route'

export function LayoutRoot() {


  return (
    <ProtectedRoute>
      <div className='  bg-maxgray-200  flex fixed top-0 left-0 right-0 bottom-0 overflow-auto  '>
        <SideNavbar />
        <div className=' flex flex-col grow  overflow-auto'>
          <NavBar />
          <div className=' grow overflow-auto px-2 flex pb-2'>
            <Outlet />
          </div>
        </div>
      </div>
    </ProtectedRoute>
  )
}

import React, { useState, useEffect, useRef } from "react";
import { IconSquare, IconDeviceLaptop, IconPrinter, IconBox, IconDeviceMobile, IconBarcode, IconMobiledata, IconDeviceMobileBolt, IconDeviceMobileCog, IconDeviceMobileStar, IconLabelFilled, IconLabelImportantFilled, IconLabel, IconScanPosition, IconGridScan, IconZoomMoney, IconBrandDaysCounter, IconBrandProducthunt, IconCamera, IconDeviceCctv, IconArrowLeft, IconArrowRight } from "@tabler/icons-react";
import { Link } from "react-router-dom";

const categories = [
  {
    id: 1,
    title: "POS System",
    icon: <IconDeviceLaptop size={28} />,
    link: "/products/pos-system",
  },
  {
    id: 2,
    title: "Portable Data POS",
    icon: <IconDeviceMobile size={25} />,
    link: "/products/portable-pos",
  },
  {
    id: 3,
    title: "POS Printer",
    icon: <IconPrinter size={25} />,
    link: "/products/pos-printer",
  },
  {
    id: 4,
    title: "Mobile Printer",
    icon: <IconDeviceMobileStar size={25} />,
    link: "/products/mobile-printer",
  },
  {
    id: 5,
    name: "Barcode Printers",
    link: "/products/barcode-printers",
    icon: <IconGridScan size={25} className="mr-2" />,
  },
  {
    id: 6,
    title: "Barcode Scanner",
    icon: <IconBarcode size={25} />,
    link: "/products/barcode-scanner",
  },
  {
    id: 7,
    title: "Weighing Scale",
    icon: <IconBox size={25} />,
    link: "/products/weighing-scale",
  },
  {
    id: 8,
    name: "ID Card Printer",
    link: "/products/id-card-printer",
    icon: <IconLabelFilled size={25} className="mr-2" />,
  },
  {
    id: 9,
    name: "Money Counters",
    link: "/products/money-counters",
    icon: <IconBrandDaysCounter size={25} className="mr-2" />,
  },
  {
    id: 10,
    name: "Peripherals",
    link: "/products/peripherals",
    icon: <IconBrandProducthunt size={25} className="mr-2" />,
  },
  {
    id: 11,
    name: "Time Attendance & Door Access",
    link: "/products/time-attendance",
    icon: <IconMobiledata size={25} className="mr-2" />,
  },
  {
    id: 12,
    name: "CCTV Cameras",
    link: "/products/cctv-cameras",
    icon: <IconCamera size={25} className="mr-2" />,
  },
];

const NavbarCategories: React.FC = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  useEffect(() => {
    const updateScrollButtons = () => {
      const container = containerRef.current;
      if (container) {
        setCanScrollLeft(container.scrollLeft > 0);
        setCanScrollRight(
          container.scrollWidth > container.clientWidth + container.scrollLeft
        );
      }
    };

    const container = containerRef.current;
    container?.addEventListener("scroll", updateScrollButtons);

    updateScrollButtons(); // Initial check

    return () => container?.removeEventListener("scroll", updateScrollButtons);
  }, []);

  const scrollContainer = (direction: "left" | "right") => {
    const container = containerRef.current;
    if (container) {
      const scrollAmount = direction === "left" ? -200 : 200;
      container.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  return (
    <div className="bg-transperent !z-40 backdrop-blur-sm text-white flex items-center top-14 left-0 w-full">
      {/* Left Scroll Button */}
      {canScrollLeft && (
        <button
          onClick={() => scrollContainer("left")}
          className="text-[#d85f19] p-2 cursor-pointer transform rounded transition-all hidden md:block"
        >
          <IconArrowLeft size={24} />
        </button>
      )}

      {/* Categories Container */}
      <div
        ref={containerRef}
        className="categories-container z-40 flex md:gap-4 gap-2 overflow-x-auto px-4 py-3 scrollbar-hide"
      >
        {categories.map((category) => (
          <div
            key={category.id}
            className="flex flex-col z-40 items-center md:min-w-[180px] min-w-40 w-fit justify-center shadow-sm rounded-md px-2  cursor-pointer group transition-all duration-300  bg-gray-200"
          >

            <Link to={`/allproducts/${category.id}`} className="flex flex-row items-center gap-1">
              <div className="mb-1 text-black transition-colors duration-300">
                {category.icon}
              </div>
              <p className="text-xs md:text-sm font-medium text-black">
                {category.title || category.name}
              </p>
            </Link>
          </div>
        ))}
      </div>

      {/* Right Scroll Button */}
      {canScrollRight && (
        <button
          onClick={() => scrollContainer("right")}
          className="text-[#d85f19] p-2 cursor-pointer rounded transition-all hidden md:block"
        >
          <IconArrowRight size={24} />
        </button>
      )}
    </div>
  );
};

export default NavbarCategories;

import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

export function Success() {
  const counters = useRef<(HTMLHeadingElement | null)[]>([]);
  const successSectionRef = useRef<HTMLDivElement | null>(null);
  const hasAnimated = useRef(false);

  useEffect(() => {
    const counts = [600, 3000, 5]; // Final values for the counters
    const gaps = [100, 100, 1]; // Gaps for each counter
    const duration = 2.5; // Total animation duration in seconds

    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting && !hasAnimated.current) {
          hasAnimated.current = true; // Prevent re-animation

          counters.current.forEach((counter, index) => {
            if (counter) {
              const startValue = 0;
              const endValue = counts[index];
              const increment = gaps[index];
              const steps = Math.ceil((endValue - startValue) / increment);
              const timePerStep = duration / steps;

              let currentValue = startValue;
              const interval = setInterval(() => {
                currentValue += increment;
                if (currentValue >= endValue) {
                  currentValue = endValue;
                  clearInterval(interval);
                }
                counter.innerText = `${currentValue}${index === 1 ? '+' : '+'}`;
              }, timePerStep * 1000);
            }
          });
        }
      },
      { threshold: 0.3 } // Trigger when 30% of the section is visible
    );

    if (successSectionRef.current) {
      observer.observe(successSectionRef.current);
    }

    return () => {
      if (successSectionRef.current) {
        observer.unobserve(successSectionRef.current);
      }
    };
  }, []);

  return (
    <div ref={successSectionRef} className="w-full z-20 relative flex flex-col gap-6 md:mt-16 mt-5 md:mb-10 lg:px-28 md:px-10 px-5">
      <div className="w-full flex bg-gray-300 rounded-lg">
        <div className="w-[33.33%] md:py-5 py-2 flex justify-center items-center flex-col">
          <h1 ref={el => (counters.current[0] = el)} style={{ fontSize: 'clamp(24px, 4vw, 50px)' }} className="font-bold font-sans">
            600+
          </h1>
          <p style={{ fontSize: 'clamp(10px, 1.7vw, 18px)'}}>Products</p>
        </div>
        <div className="w-[33.33%] md:py-5 py-2 bg-white border-y-2 border-gray-200 flex justify-center items-center flex-col ">
          <h1 ref={el => (counters.current[1] = el)} style={{ fontSize: 'clamp(24px, 4vw, 50px)' }} className="font-bold font-sans">
            3000+
          </h1>
          <p style={{ fontSize: 'clamp(10px, 1.7vw, 18px)'}}>Happy Customers</p>
        </div>
        <div className="w-[33.33%] md:py-5 py-2 flex justify-center items-center flex-col">
          <h1 ref={el => (counters.current[2] = el)} style={{ fontSize: 'clamp(24px, 4vw, 40px)' }} className="font-bold font-sans">
            5+
          </h1>
          <p style={{ fontSize: 'clamp(10px, 1.7vw, 18px)'}}>Countries</p>
        </div>
      </div>
    </div>
  );
}

import { useMutation } from "@tanstack/react-query";
import { restAPI } from "./axiosAPI";


export type ProductVariationPostType = {
    M08_name: string;
    M08_M05_product_id: string;
    M08_is_active: 1 | 0;
    options: ({
        M09_name: string;
        M09_is_active: 1 | 0;
    })[];
}


export function useProductVariationAPI({

}) {

    const api = restAPI()
    const { mutateAsync: createVariation, isPending: isCreatingVariation } = useMutation({


        mutationFn: async ({ variation }: { variation: ProductVariationPostType }) => {
            const response = await api.post<{}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/product-variation`,
                data: variation,
                mode: 'protected',
                withCredentials: true
            })


            if (response.success === false) {
                return response
            }
            return response
        }
    })
    const { mutateAsync: updateVariation, isPending: isUpdatingVariation } = useMutation({


        mutationFn: async ({ variation_id, variation }: {
            variation_id: string,
            variation: {
                M08_M05_product_id: string,
                M08_name: string,
                M08_is_active: 1 | 0,
            },
        }) => {
            const response = await api.put<{}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/product-variation/${variation_id}`,
                data: variation,
                mode: 'protected',
                withCredentials: true
            })


            if (response.success === false) {
                return response
            }
            return response
        }
    })
    const { mutateAsync: deleteVariation, isPending: isDeletingVariation } = useMutation({


        mutationFn: async ({ variation_id }: {
            variation_id: string,
        }) => {
            const response = await api.delete<{}>({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/product-variation/${variation_id}`,
                mode: 'protected',
                withCredentials: true
            })


            if (response.success === false) {
                return response
            }
            return response
        }
    })

    const { mutateAsync: createVariationOption, isPending: isCreatingVariationOption } = useMutation({


        mutationFn: async ({ variation_option }: {
            variation_option: {
                "M09_M08_product_variation_id": string,
                "M09_name": string,
                "M09_is_active": 1|0
            }
        }) => {
            const response = await api.post<{}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/product-variation-option`,
                data: variation_option,
                mode: 'protected',
                withCredentials: true
            })


            if (response.success === false) {
                return response
            }
            return response
        }
    })
    const { mutateAsync: updateVariationOption, isPending: isUpdatingVariationOption } = useMutation({


        mutationFn: async ({ variation_option_id, variation_option }: {
            variation_option_id: string,
            variation_option: {
                "M09_M08_product_variation_id": string,
                "M09_name": string,
                "M09_is_active": 1|0
            },
        }) => {
            const response = await api.put<{}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/product-variation-option/${variation_option_id}`,
                data: variation_option,
                mode: 'protected',
                withCredentials: true
            })


            if (response.success === false) {
                return response
            }
            return response
        }
    })
    const { mutateAsync: deleteVariationOption, isPending: isDeletingVariationOption } = useMutation({


        mutationFn: async ({ variation__option_id }: {
            variation__option_id: string,
        }) => {
            const response = await api.delete<{}>({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/product-variation-option/${variation__option_id}`,
                mode: 'protected',
                withCredentials: true
            })


            if (response.success === false) {
                return response
            }
            return response
        }
    })

    const { mutateAsync: updateVariationConfiguration, isPending: isUpdatingVariationConfiguration } = useMutation({


        mutationFn: async ({ variation_configuration_id, configurations }: {
            variation_configuration_id: string,
            configurations: ({
                _id: string,
                M10_M06_product_sku_id: string,
                M10_M08_product_variation_id: string,
                M10_M09_variation_option_id: string
            }[])
        }) => {
            const response = await api.put<{}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/product-variation-configuration-bysku/${variation_configuration_id}`,
                data: {configurations},
                mode: 'protected',
                withCredentials: true
            })


            if (response.success === false) {
                return response
            }
            return response
        }
    })

    return {
        createVariation,
        isCreatingVariation,
        updateVariation,
        isUpdatingVariation,
        deleteVariation,
        isDeletingVariation,
        createVariationOption,
        isCreatingVariationOption,
        updateVariationOption,
        isUpdatingVariationOption,
        deleteVariationOption,
        isDeletingVariationOption,
        updateVariationConfiguration,
        isUpdatingVariationConfiguration
    }


}
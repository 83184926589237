import axios, { AxiosError, AxiosProgressEvent, AxiosRequestConfig, ResponseType } from "axios"
import { useNavigate } from "react-router-dom"

type mode = 'protected' | 'public'

type RestCommonProps = {
    url: string,
    params?: AxiosRequestConfig['params'],
    mode?: mode,
    responseType?:ResponseType,
    withCredentials?: boolean
}

type RestDataProps<T> = RestCommonProps & {
    data?: T
}

type postMethodProps = {
    onUploadProgress?:((progressEvent: AxiosProgressEvent) => void)
}

type ResponseCommonKeys = {
    success: boolean,
    msg: string,
    errors?: any,
    validationErrors?: {key:string,message:string}[],
    statusCode:number
}

export type AxiosResponseCustom<T> = ResponseCommonKeys & T



export function restAPI() {


    // const navigate = useNavigate()

    // GET request
    const get = async <T>({ url, params, mode = 'public',responseType,withCredentials=false }: RestCommonProps): Promise<AxiosResponseCustom<T>> => {
        try {

            const headers: any = {

            }
            if (mode === 'protected') {
                headers.Authorization = `Bearer ${localStorage.getItem('accessTocken')}`
            }

            const response = await axios.get<AxiosResponseCustom<T>>(url, {
                params: params,
                headers,
                responseType:responseType,
                withCredentials,
                
            })

            return response.data
        } catch (error: AxiosError | any) {
            return handleError(error,mode)
        }
    }

    // POST request
    const post = async <T, D>({ url, data, params, mode = 'public',onUploadProgress,withCredentials=false }: RestDataProps<D>&postMethodProps): Promise<AxiosResponseCustom<T>> => {
        try {
            const headers: any = {

            }
            if (mode === 'protected') {
                headers.Authorization = `Bearer ${localStorage.getItem('accessTocken')}`
            }
            const response = await axios.post<AxiosResponseCustom<T>>(url, data, {
                params: params,
                headers,
                onUploadProgress,
                withCredentials
            })
            console.log('Authorization header from response:', response.headers.Authorization)
            return response.data
        } catch (error: AxiosError | any) {
            return handleError(error,mode)
        }
    }

    // PUT request
    const put = async <T, D>({ url, data, params, mode = 'public',withCredentials=false }: RestDataProps<D>): Promise<AxiosResponseCustom<T>> => {
        try {
            const headers: any = {

            }
            if (mode === 'protected') {
                headers.Authorization = `Bearer ${localStorage.getItem('accessTocken')}`
            }
            const response = await axios.put<AxiosResponseCustom<T>>(url, data, {
                params: params,
                headers,
                withCredentials
            })
            return response.data
        } catch (error: AxiosError | any) {
            return handleError(error,mode)
        }
    }
    // PATCH request
    const patch = async <T, D>({ url, data, params, mode = 'public',withCredentials=false }: RestDataProps<D>): Promise<AxiosResponseCustom<T>> => {
        try {
            const headers: any = {

            }
            if (mode === 'protected') {
                headers.Authorization = `Bearer ${localStorage.getItem('accessTocken')}`
            }
            const response = await axios.patch<AxiosResponseCustom<T>>(url, data, {
                params: params,
                headers,
                withCredentials
            })
            return response.data
        } catch (error: AxiosError | any) {
            return handleError(error,mode)
        }
    }

    // DELETE request
    const del = async <T>({ url, params, mode = 'public',withCredentials=false }: RestCommonProps): Promise<AxiosResponseCustom<T>> => {
        try {
            const headers: any = {

            }
            if (mode === 'protected') {
                headers.Authorization = `Bearer ${localStorage.getItem('accessTocken')}`
            }
            const response = await axios.delete<AxiosResponseCustom<T>>(url, {
                params: params,
                headers,
                withCredentials
            })
            return response.data
        } catch (error: AxiosError | any) {
            return handleError(error,mode)
        }
    }

    // Error handling function
    const handleError = (error: AxiosError | any,mode:mode): AxiosResponseCustom<any> => {
        console.log(error)
        if(error.response?.status==401 && mode === 'protected') {
            window.location.href = '/admin/login';
            return error.response.data;
        }
        if (error.response?.data && error.response.data.success === false && error.response.data.msg) {
            return error.response.data
        }
        if (error.code === 'ERR_NETWORK') {
            return {
                success: false,
                msg: 'Network Error'
            }
        }
        return {
            success: false,
            msg: 'Server Error'
        }
    }

    return {
        get,
        post,
        put,
        patch,
        delete: del
    }
}
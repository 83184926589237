
import { ButtonIconSquare } from '../../../../../components/buttons'
import { ModalStandard, ModalStandardContent, ModalStandardDescription, ModalStandardFooter, ModalStandardHeader, ModalStandardTitle } from '../../../../../components/standard.modal'

import { IconEdit, IconLoader, IconPlus, IconX } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSKUGetAllAPI } from '../../../../../API/rest.product_sku'
import { useFeaturedProductGetAPI } from '../../../../../API/rest.featuredProducts'
import { toast } from '../../../../../../shadcn/ui/use-toast'
import { MultiselectStandard } from '../../../../../components/standard.multiselect'

type Props = {

    show?: boolean,
    setShow?: (show: boolean) => void,
    refetchProducts: () => void,
    mode: 'add' | 'edit',
    productId?: string | null,
    parentId?: string | null
}
export type formData = {
    productItem: string
}

export function ModalAddProduct(props: Props) {

    const { register, handleSubmit, setValue, reset, formState: { errors }, control, watch } = useForm<formData>({
        defaultValues: {
            productItem: '',
        }
    });

    const [selectedImageLink, setSelectedImageLink] = useState<string>()
    // const [isImageDeleted, setIsImageDeleted] = useState(false)
    const {
        skus,
        error,
        isErrorSku,
        isFetched,
        isFetching,
        isLoading,
        refetch
    } = useSKUGetAllAPI({
        page_size: 100
    })

    const {
        createFeaturedProduct,
    } = useFeaturedProductGetAPI()

    const [isSubmitting, setIsSubmitting] = React.useState(false);

    useEffect(
        () => {


            if (props.show && props.mode === 'add') {
                reset({
                    productItem: ''
                })
            }
            if (props.mode === 'edit' && props.productId && props.show) {
                // readProductCategoryById({
                //     id: props.productId
                // }).then((response) => {
                //     if (response.success === true) {
                //         reset({
                //             _id: response.data._id,
                //             M04_category_name: response.data.M04_category_name,
                //             M04_image: response.data.M04_image,
                //             M04_M04_parent_category_id: response.data.M04_M04_parent_category_id,
                //             M04_is_active: response.data.M04_is_active
                //         })
                //         setSelectedImageLink(response.data.M04_image as string)
                //     } else {
                //         toast({
                //             description: response.msg,
                //             title: "error",
                //             // variant:''
                //         })
                //     }
                // })
            }
        }, [props.show]
    )

    const onSubmit = async (data: formData) => {

        setIsSubmitting(true)
        if (props.mode === 'add') {
                const response = await createFeaturedProduct({
                    p01_is_active:1,
                    p01_product_id:data.productItem
                })
                if (response.success === true) {
                    toast({
                        description: response.msg,
                        // variant:''
                    })
                    props.setShow?.(false);
                    props.refetchProducts()
                } else {
                    toast({
                        description: response.msg,
                        title: "error",
                        // variant:''
                    })
                }

            

        } else if (props.mode === 'edit' && props.productId) {
            // const response = await updatedProductCategory({
            //     id: props.productId,
            //     ProductCategory: {
            //         ...data,
            //         M04_image: selectedImage ? selectedImage : null,
            //     },
            //     isImageDeleted: data.M04_image !== null && selectedImageLink === undefined && selectedImage === undefined ? 1 : 0
            // })
            // if (response.success === true) {
            //     toast({
            //         description: response.msg,
            //         // variant:''
            //     })
            //     props.setShow?.(false);
            //     props.refetchProducts()
            // } else {
            //     toast({
            //         description: response.msg,
            //         title: "error",
            //         // variant:''
            //     })
            // }
        }
        setIsSubmitting(false)
    };

    const isAnyThingFetching = isFetching

    useEffect(() => {
        if (props.show === false) {
            reset({
                productItem:''
            })
        }
    }, [props.show])

    return (
        <ModalStandard
            open={props.show}
            onOpenChange={props.setShow}
        >
            <ModalStandardContent onOpenAutoFocus={(e) => e?.preventDefault()} className='bg-maxgray-100'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalStandardHeader >
                        <ModalStandardTitle >{props.mode === 'edit' ? 'Edit Product' : 'Add Product'}</ModalStandardTitle>
                        <ModalStandardDescription>
                            {/* loading indication */}
                            {isAnyThingFetching && <div className=' px-2 p-1 rounded-md bg-maxgray-800/30 whitespace-nowrap w-min absolute left-1/2 -translate-x-1/2'>
                                Updating...
                            </div>}
                            <form onSubmit={handleSubmit(onSubmit)} className=" pb-3">
                                <div className='grid gap-4 py-4 xs2:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 '>


                                    <MultiselectStandard
                                        {...register('productItem',{required:"Please select a product"})}
                                        onValueChange={(value) => {
                                            setValue('productItem', value.target.value?.[0])
                                        }}
                                        options={
                                            skus?.products_skus.map((item) => {
                                                return {
                                                    label: item.m06_product_sku_name!,
                                                    value: String(item.id)!
                                                }
                                            })||[]
                                        }
                                        singleSelect
                                        error={errors.productItem?.message}
                                        value={watch('productItem')?[watch('productItem')]:[]}
                                    />

                                </div>

                            </form>
                        </ModalStandardDescription>
                    </ModalStandardHeader>
                    <ModalStandardFooter>
                        <ButtonIconSquare
                            variant='white'
                            icon={<IconX />}
                            text='close'
                            onClick={(e) => { e?.preventDefault(); props.setShow?.(false) }}
                            disabled={isSubmitting}
                        />
                        <ButtonIconSquare
                            variant='secondary'
                            icon={isSubmitting ? <IconLoader className=' animate-spin ' /> : props.mode === 'add' ? <IconPlus /> : <IconEdit />}
                            text={props.mode === 'add' ? 'Add' : 'Edit'}
                            type="submit"
                            disabled={isSubmitting}
                        />
                    </ModalStandardFooter>
                </form>
            </ModalStandardContent>
        </ModalStandard>
    )
}



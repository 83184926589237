// import { Label } from "../../shadcn/ui/label";
import React from "react";
import { UseControllerProps } from "react-hook-form";
import { Switch } from "../../shadcn/ui/switch";
import { Label } from "../../shadcn/ui/label";

export interface InputProps extends UseControllerProps {
  title?: string;
  error?: string;
  value?: boolean;
  name: string;
  onChange?: (value: { target: { name?: string; value?: boolean } }) => void;
  className?: string;
}

export const SwitchDemo = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...props }, ref) => {

    return (
      <div className="flex items-center space-x-2">
        <Switch
          // ref={inputRef}
          onCheckedChange={(value) => {
            props?.onChange?.({
              target: {
                name: props.name,
                value: value,
              },
            });
          }}
          checked={props.value}
          disabled={props.disabled}
          id="airplane-mode"
        />
        <Label htmlFor="airplane-mode">{props.title}</Label>
      </div>
    );
  }
);

SwitchDemo.displayName = "SwitchDemo";
import React, { useEffect } from 'react'
import AboutUsSection from './components/AboutUs'
import ProductsSection from './components/MainProducts'
import ClientsSection from './components/Ourclients'
import ContactForm from './components/Formsection'
import Footer from './components/Footer'
import NewHome from './components/newHome'
import MissionVision from './components/MissionVision'
// import SubNavbar from './components/subNavbar'


export default function Homepage() {
    
    useEffect(() => {
        // Scroll to the top when the route changes
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>

            {/* <Navbarsm/> */}

            {/* <Carousalfirst /> */}
            {/* <Carousalsecond/> */}
            {/* <CarouselThird/> */}
            <NewHome />
            <section id='about'>

                <AboutUsSection />
            </section>
            <MissionVision />
            {/* <VissionCards/> */}
            {/* <VisionMission /> */}


            <ProductsSection />
            {/* <CategoriesSection /> */}

            <ClientsSection />
            {/* <ContactUs /> */}
            <ContactForm />
            <Footer />
        </div>
    )
}

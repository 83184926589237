import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { IconX } from "@tabler/icons-react";
import { CarouselData } from '../../../../../API/rest.carousels';
import { FeaturedProductData } from '../../../../../API/rest.featuredProducts';


interface CarouselCardProps {
    data: FeaturedProductData;
    index: number;
    moveCard: (dragIndex: number, hoverIndex: number) => void;
    dropCard: (dragIndex: number, hoverIndex: number) => void;
    onDelete: (index: number) => void;
    disabled: boolean;
}

const FeaturedProductCard: React.FC<CarouselCardProps> = ({ data, index, moveCard, dropCard, onDelete, disabled = false }) => {
    const ref = useRef<HTMLDivElement>(null);

    const [{ isDragging }, drag] = useDrag({
        type: 'carousel',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop({
        accept: 'carousel',
        hover: (draggedItem: { index: number }) => {
            if (draggedItem.index !== index) {
                moveCard(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
        drop(item) {
            dropCard(item.index, index);
        },
    });

    drag(drop(ref));

    return (
        <div
            ref={ref}
            style={{
                opacity: isDragging ? 0.5 : 1,
                width: 150,
                margin: '10px',
                cursor: disabled ? 'default' : 'move',
                position: 'relative',
                
            }}
            className=' flex flex-col h-[200px] bg-white border rounded-md'
        >
            <img
                src={`${process.env.REACT_APP_BACKEND_URL_API_ONLY}/${data.P01_M06_product_id.m06_thumbnail_image}`}
                alt={data.P01_M06_product_id.m06_product_sku_name}
                style={{ width: '100%', height: '130px', objectFit: 'contain' }}
            />
            <p className=' px-3 py-2 font-semibold whitespace-nowrap truncate'>{data.P01_M06_product_id.m06_product_sku_name}</p>
            <p className=' text-vstargray-500 text-xs px-3 py-1'>sku: {data.P01_M06_product_id.m06_sku}</p>
            {!disabled && (
                <IconX
                    style={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        cursor: 'pointer',
                        color: 'red',
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        onDelete(index);
                    }}
                />
            )}
        </div>
    );
};

export default FeaturedProductCard;

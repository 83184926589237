
import { useEffect, useState } from 'react'
import { StandardTablePagination } from '../../../../../components/standard.pagination'
import { ModalAddProduct } from './component.addModal'
import { DropdownMenuParent } from './component.dropdownMenu'
import { Pagination, PaginationKeys } from '../../../../../../types/pagination'
import { useEnquiryGetAllAPI } from '../../../../../API/rest.enquiry'
import { useProductCategoryAPI } from '../../../../../API/rest.product_category'
import { StandardTable, StandardTableHeader, StandardTableRow, TablePlater } from '../../../../../components/standard.table'
import { toast } from '../../../../../../shadcn/ui/use-toast'
// import { ImportExcelModal } from './component.importExcelModal'




export function EnquiryPage() {

    const searchParams = new URLSearchParams(window.location.search)
    const currentPageFromUrl = searchParams.get(PaginationKeys.CURRENT_PAGE)
    const dataPerPage = 30
    const [pagination, setPagination] = useState<Pagination>({
        currentPage: currentPageFromUrl ? parseInt(currentPageFromUrl) : 1,
        totalPages: Math.ceil(1 / dataPerPage) || 1,
        dataPerPage: dataPerPage
    })

    const [previousDataLength, setPreviousDataLength] = useState(0)
    const [openAddModal, setOpenAddModal] = useState(false)
    const [selectedProductId, setSelectedProductId] = useState<null | string>()
    const [selectedParentId, setSelectedParentId] = useState<null | string>()
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [mode, setMode] = useState<'add' | 'edit'>('add')
    const [openImportModal, setOpenImportModal] = useState(false)

    const {
        enquiries,
        isLoading,
        isFetching,
        isFetched,
        isError,
        error,
        refetch
        
    } = useEnquiryGetAllAPI({})



    const [productsRow, setProductsRow] = useState<StandardTableRow[]>([])



    const headers: StandardTableHeader[] = [
        { label: 'SINO', id: 'SINO' },
        { label: 'phone', id: 'phone' },
        { label: 'email', id: 'email' },
        { label: '', id: 'new' },
        { label: '', id: 'actions', width: '20px' },
    ]



    useEffect(
        () => {
            // setting prevouse data length for loading optimization
            if (isFetching && !isFetching) {
                setPreviousDataLength(enquiries?.enquiries.length || 0)
            }

            //if successfully loaded
            if (isFetched && !isError) {
                setPagination({
                    currentPage: currentPageFromUrl ? parseInt(currentPageFromUrl) : 1,
                    totalPages: Math.ceil(enquiries?.pagination.totalPages!) || 1,
                    dataPerPage: dataPerPage
                })
                setProductsRow(enquiries?.enquiries?.map(
                    (product) => {
                        return ({
                            id: String(product.id),
                            datas: {
                                email: { id: 'email', data: product.m08_mail },
                                phone: { id: 'name', data: product.m08_name },
                                actions: {
                                    id: 'actions', data: <DropdownMenuParent
                                        rowId={String(product.id)}
                                        
                                        onEditAction={(rowid) => {
                                            setSelectedProductId(rowid);
                                            setMode('edit');
                                            setOpenAddModal(true);
                                        }}
                                    />
                                },
                                new: { id: 'new', data: product.m08_is_read ? undefined : <div className=' h-1 w-1 bg-max-green-500 rounded-full'></div> },
                            }
                        })

                    }
                ) || [])

            }
            if (isError && isFetching === false) {
                toast({
                    description: String(error?.message || 'Error while fetching data'),
                    title: 'Error',
                })

            }
        }, [isFetching]
    )







    return (
        <div className=' grow  flex flex-col overflow-auto'>
            
            <ModalAddProduct
                show={openAddModal}
                setShow={setOpenAddModal}
                refetchProducts={refetch}
                mode={mode}
                productId={selectedProductId}
            />
            {/* <ImportExcelModal
                show={openImportModal}
                setShow={setOpenImportModal}
            /> */}
            <TablePlater className=' flex flex-col gap-2'>
                            {/* Buttons section */}
                            <div className=' flex justify-end w-full gap-4'>
                                {/* <ButtonIconSquare var   iant="white" icon={<IconCloudUp />} text="Import" onClick={() => { setOpenImportModal(true) }} /> */}
                                {/* <ButtonIconSquare variant="secondary" icon={<IconPlus />} text="Add SKU" onClick={() => { setSelectedProductId(undefined); setSelectedParentId(undefined); setMode('add'); setOpenAddModal(true); }} /> */}
                            </div>
            
                            <StandardTable
                                headers={headers}
                                rows={productsRow}
                                isLoading={isLoading}
                                rowsPerPrevousePage={previousDataLength}
                                isUpdating={isFetching}
                                isError={isError && isFetching === false}
                                onRetry={() => refetch()}
                            />
                            <StandardTablePagination currentPage={pagination.currentPage} totalPages={pagination.totalPages} onChangePage={(page) => {
                                setPagination({ ...pagination, currentPage: page })
                            }} />
            
                        </TablePlater>
        </div>
    )

}




import { IconPlus } from '@tabler/icons-react';
import React, { useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import FeaturedProductCard from './carouselcard';
import { FeaturedProductData } from '../../../../../API/rest.featuredProducts';

interface CarouselProps {
    items: FeaturedProductData[];
    setItems: (items: FeaturedProductData[]) => void;
    disabled?: boolean;
    onClickAdd?: () => void;
    onDropImage: (images: FeaturedProductData[],imagePrev:FeaturedProductData[],setPrevImages: (images: FeaturedProductData[]) => any) => void,
    ondeleteImage: (index: number) => void,

}

const FeaturedProducts: React.FC<CarouselProps> = ({ items, setItems, disabled = false,onClickAdd,onDropImage,ondeleteImage }) => {
   
    const [imagesPrev, setImagesPrev] = useState<FeaturedProductData[]>([]);


    useEffect(() => {
        setImagesPrev(items);
    },[items?.length])


    const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
        const updatedItems = [...items];
        const [draggedItem] = updatedItems.splice(dragIndex, 1);
        updatedItems.splice(hoverIndex, 0, draggedItem);
        setItems(updatedItems.map((item, index) => ({ ...item, p01_order: index + 1 })));
    }, [items, setItems]);

    const dropCard = useCallback((dragIndex: number, hoverIndex: number) => {
        const updatedItems = [...items];
        const [draggedItem] = updatedItems.splice(dragIndex, 1);
        updatedItems.splice(hoverIndex, 0, draggedItem);
        const ImageWithOrder = updatedItems.map((item, index) => ({ ...item, p01_order: index + 1 }))
        onDropImage(ImageWithOrder,imagesPrev,setImagesPrev);
    }, [items, setItems,onDropImage,imagesPrev]);

    const deleteCard = (index: number) => {
        ondeleteImage(index);
        
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))', // Responsive column layout
                    gap: '10px', // Space between grid items
                    padding: '10px',
                }}
            >
                {items
                    .sort((a, b) => a.p01_order - b.p01_order)
                    .map((item, index) => (
                        <FeaturedProductCard
                            key={item.id || index}
                            data={item}
                            index={index}
                            moveCard={moveCard}
                            dropCard={dropCard}
                            onDelete={deleteCard}
                            disabled={disabled}
                        />
                    ))}
                    <div onClick={()=>{
                        if(disabled) return;
                        onClickAdd?.()
                    }} className=' border-2 border-dashed border-gray-300 rounded-lg p-6 text-center cursor-pointer hover:border-gray-400 transition-colors flex items-center justify-center'>
                        <IconPlus size={24} className=" h-12 w-12 text-gray-400" />
                    </div>
            </div>
        </DndProvider>
    );
};
export default FeaturedProducts;

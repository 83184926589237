import React, { useState } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import Button from './Button';

const ContactForm: React.FC = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const payload = {
      m08_name: formData.name,
      m08_message: formData.message,
      m08_mail: formData.email,
    };

    try {
      await axios.post('https://trios-backend-dev.prosevo.in/api/v1/customer/create-enquiry', payload);
      toast.success('Message sent successfully!', { duration: 3000 });
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      toast.error('Failed to send message. Please try again.', { duration: 3000 });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col lg:flex-row items-center justify-center min-h-screen bg-black p-8 pt-20">
      <Toaster position="top-center" reverseOrder={false} />

      <div className="lg:w-1/2 text-center lg:text-left">
        <Button text='Message Us' />
        <h1 className="md:text-4xl text-gray-100 text-2xl font-bold mb-4 mt-4">Let's Talk<br />Get In Touch</h1>
        <p className="text-gray-300 mb-6 text-left">
          Need help or have a question? Our team is here to make your experience seamless and enjoyable! Whether you're curious about our products, need assistance with an order, or just want to say hello, we'd love to hear from you.
        </p>
        <div className="rounded-md overflow-hidden shadow-md">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d461786.03932123195!2d55.387260000000005!3d25.28056!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5d31217aef4d%3A0xb78b5ae307035660!2sTrios%20International%20corporate%20office!5e0!3m2!1sen!2sin!4v1733999340629!5m2!1sen!2sin"
            title="Map location"
            className="w-full h-64 border-0"
            allowFullScreen
            loading="lazy"
          ></iframe>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="lg:w-1/2 rounded-md md:p-6 mt-8 lg:mt-0 lg:ml-8 w-full max-w-lg">
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-300 font-medium mb-2">
            Name
          </label>
          <input
            type="text"
            id="name"
            value={formData.name}
            onChange={handleInputChange}
            className="w-full px-4 py-2 bg-gray-300 border border-gray-300 rounded-md focus:ring-2 focus:ring-red-800 focus:outline-none"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-300 font-medium mb-2">
            Email
          </label>
          <input
            type="email"
            id="email"
            value={formData.email}
            onChange={handleInputChange}
            className="w-full px-4 py-2 bg-gray-300 border border-gray-300 rounded-md focus:ring-2 focus:ring-red-800 focus:outline-none"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="message" className="block text-gray-300 font-medium mb-2">
            Message
          </label>
          <textarea
            id="message"
            rows={4}
            value={formData.message}
            onChange={handleInputChange}
            className="w-full px-4 py-2 bg-gray-300 border border-gray-300 rounded-md focus:ring-2 focus:ring-red-800 focus:outline-none"
            required
          ></textarea>
        </div>

        <button
          type="submit"
          className={`w-full bg-red-900 text-white px-4 py-2 rounded-md transition duration-300 ${loading ? 'opacity-50' : ''}`}
          disabled={loading}
        >
          {loading ? 'Sending...' : 'Message Us'}
        </button>
      </form>
    </div>
  );
};

export default ContactForm;

import { useMutation, useQuery } from "@tanstack/react-query"
import { restAPI } from "./axiosAPI"
import { useState } from "react"
import { backendPagination } from "../../types/pagination"
import { ProductCategoryData } from "./rest.product_category"


export type ProductVariationOption = {
    _id: string;
    M09_name: string;
    M09_M05_product_id: string;
    M09_M08_product_variation_id: string;
    M09_is_active: 1 | 0;
    M09_deleted_at: null | string;
    createdAt: string;
    updatedAt: string;
};
export type ProductVariation = {
    _id: string;
    M08_name: string;
    M08_M05_product_id: string;
    M08_is_active: 1 | 0;
    M08_deleted_at: null | string;
    createdAt: string;
    updatedAt: string;
};

export type ProductData = {
    _id?: string,
    M05_product_name: string,
    M05_image: string | null | File,
    M05_M04_product_category: string,
    M05_is_active: 1 | 0,
    product_category: ProductCategoryData,
    variations: (ProductVariation & {
        Option: ProductVariationOption[]
    })[]
}
export type ProductPostData = {
    _id?: string,
    M05_product_name: string,
    M05_image: string | null | File,
    M05_M04_product_category: string,
    M05_is_active: 1 | 0,
    variations: ({
        M08_name: string,
        M08_is_active: 1 | 0,
        Option: ({
            M09_name: string,
            M09_is_active: 1 | 0
        })[]
    })[]
}
export type ProductPutData = {
    _id?: string,
    M05_product_name: string,
    M05_image: string | null | File,
    M05_M04_product_category: string,
    M05_is_active: 1 | 0,
}


const QUERY_KEY = 'product'

export function useProductGetAllAPI({
    page_size,
    page,
    retryCount,
    trigger = true
}: {
    page_size?: number,
    page?: number,
    retryCount?: number,
    trigger?: boolean
}) {
    const api = restAPI()

    const {
        data: products,
        isLoading,
        isError,
        refetch: refetchProducts,
        isFetching,
        isFetched,
        error
    } = useQuery({
        queryKey: [QUERY_KEY, page_size, page],
        queryFn: async () => {
            const response = await api.get<{
                data: {
                    products: ProductData[],
                    pagination: backendPagination
                }
            }>({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/product`,
                mode: 'protected',
                params: {
                    limit: page_size,
                    page
                },
                withCredentials: true
            })
            if (response.success === false) {
                throw response
            }
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger

    })

    return {
        isLoading,
        isError,
        products,
        refetch: refetchProducts,
        isFetching,
        isFetched,
        error
    }

}

export function useProductAPI({

}) {

    const api = restAPI()




    const { mutateAsync: createProduct, isPending: isCreatingProduct } = useMutation({


        mutationFn: async ({ Product }: { Product: ProductPostData }) => {
            const formData = new FormData();
            Object.keys(Product).forEach(key => {
                switch (key) {
                    case 'M05_image':
                        if (Product[key] instanceof File) {
                            formData.append(key, Product[key] as File);
                        }
                        break;
                    case 'M05_M04_product_category':
                    case 'M05_is_active':
                    case 'M05_product_name':
                        // Append all other data as string
                        const value = Product[key as keyof ProductPostData];
                        formData.append(key, value !== null && value !== undefined ? String(value) : '');
                        break;

                    case 'variations':
                        if (Product.variations?.length) {
                            Product.variations.forEach((variation, indexVariation) => {
                                formData.append(`variations[${indexVariation}][M08_name]`, variation.M08_name);
                                formData.append(`variations[${indexVariation}][M08_is_active]`, String(variation.M08_is_active));
                                variation.Option.forEach((option, indexOption) => {
                                    formData.append(`variations[${indexVariation}][Option][${indexOption}][M09_name]`, option.M09_name);
                                    formData.append(`variations[${indexVariation}][Option][${indexOption}][M09_is_active]`, String(option.M09_is_active));
                                })
                            })
                        }
                        break;
                    default:
                        break;
                }
            })

            const response = await api.post<{ access: string, refresh: string }, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/product`,
                data: formData,
                mode: 'protected',
                withCredentials: true
            })


            if (response.success === false) {
                return response
            }
            return response
        }
    })
    const { mutateAsync: updatedProduct, isPending: isUpdatingProduct } = useMutation({
        mutationFn: async ({
            id,
            Product,
            isImageDeleted
        }: {
            id: string,
            Product: ProductPutData,
            isImageDeleted?: 1 | 0
        }) => {

            const formData = new FormData();
            Object.keys(Product).forEach(key => {
                switch (key) {
                    case 'M05_image':
                        if (Product[key] instanceof File) {
                            formData.append(key, Product[key] as File);
                        }
                        break;
                    case 'M05_M04_product_category':
                    case 'M05_is_active':
                    case 'M05_product_name':
                        // Append all other data as string
                        const value = Product[key as keyof ProductPutData];
                        formData.append(key, value !== null && value !== undefined ? String(value) : '');
                        break;
                    default:
                        break;
                }
            })

            if (isImageDeleted === 1) {
                formData.append('isImageDeleted', String(isImageDeleted))
            }

            const response = await api.put<{ access: string, refresh: string }, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/product/${id}`,
                data: formData,
                mode: 'protected',
                withCredentials: true
            })


            if (response.success === false) {
                return response
            }
            return response
        }
    })

    const { mutateAsync: deleteProductById, isPending: isDeletingProduct } = useMutation({
        mutationFn: async (id: string) => {
            const response = await api.delete({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/product/${id}`,
                mode: 'protected',
                withCredentials: true
            })


            if (response.success === false) {
                return response
            }
            return response
        }
    })
    const { mutateAsync: readProductById, isPending: isReadingProductById } = useMutation({
        mutationFn: async ({
            id
        }: {
            id: string
        }) => {
            const response = await api.get<{ data: ProductData }>({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/product/${id}`,
                mode: 'protected',
                withCredentials: true
            })


            if (response.success === false) {
                return response
            }
            return response
        }
    })


    return {
        createProduct,
        isCreatingProduct,
        updatedProduct,
        isUpdatingProduct,
        readProductById,
        isReadingProductById,
        deleteProductById,
        isDeletingProduct,
    }


}


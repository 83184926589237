import React, { useEffect } from 'react'
import ContactUs from './components/ContactUs'
import Footer from './components/Footer'
import Branches from './Brnaches'
import ConnectAndWtsp from './components/connectWtsp';

export default function Contact() {
  useEffect(() => {
    // Scroll to the top when the route changes
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='relative'>
      <ContactUs />
      <Branches />
      <ConnectAndWtsp />
      <Footer />
    </div>
  )
}

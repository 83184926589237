
import React from 'react'
import { cn } from '../../lib/utils'

type ButtonRoundedProps = {
  variant?: 'primary' | 'secondary',
  icon: React.ReactNode,
  onClick?: ((e?: React.MouseEvent<HTMLButtonElement>) => any),
  disabled?: boolean
}
type ButtonProps = {
  variant?: 'primary' | 'secondary' | 'white',
  icon?: React.ReactNode,
  text?: string,
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void,
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'],
  className?: string,
  disabled?: boolean
}

export function ButtonRounded({ variant = 'primary', icon, onClick, disabled }: ButtonRoundedProps) {
  return (
    <button
      disabled={disabled}
      className={
        `rounded-full p-2 ${variant === 'primary' ? ' hover:bg-maxgray-500/30 dark:text-vstargray-100 ' : 'bg-white border border-gray-300'}`
      }
      onClick={(e) => onClick?.(e)}
      
    >
      {icon}
    </button>
  )
}

export function ButtonIconSquare({ variant = 'primary', icon, onClick, text, type, className, disabled }: ButtonProps) {

  let className1 = ''
  switch (variant) {
    case 'primary':
      className1 = ' hover:bg-maxgray-500/30 dark:text-vstargray-100 dark text-sm '
      break;

    case 'secondary':
      className1 = ' border bg-max-green-500 hover:bg-max-green-500  text-vstargray-100 text-sm'
      break;
    case 'white':
      className1 = ' border bg-maxgray-100 hover:bg-maxgray-200  text-vstargray-900 text-sm'
      break;

    default:
      break;
  }

  return (
    <button
      className={
        cn(
          `rounded-md flex gap-1 items-center p-2 px-3 w-min whitespace-nowrap `,
          icon ? 'pe-4' : '',
          className,
          className1
        )
      }
      disabled={disabled}
      onClick={onClick}
      type={type}

    >
      {icon}
      {
        text ?
          <p className=" text-xs">{text}</p>
          :
          null
      }
    </button>
  )
}




import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function ConnectAndWtsp() {
    const navigate = useNavigate();
    const number = "+971505411910";

    const handelChange = ()=>{
        navigate('/contactform')
    }

    return (
        <div className='w-full flex md:justify-end justify-between md:gap-5 text-black md:px-20 px-6 fixed md:bottom-12 bottom-16 '>

            <div
                className='h-10 w-10 cursor-pointer opacity-[0.8] z-50'
                onClick={() => {
                    const whatsappUrl = `https://wa.me/${number}?text=Hello`;
                    window.location.href = whatsappUrl;
                }}
            >
                <img src="/assets/whatsapp.png" className='w-full h-full' alt="WhatsApp" />
            </div>

            <button
                className="bg-black text-center md:w-48 w-36 rounded-lg h-10 relative text-black text-xl font-semibold group"
                type="button"
                onClick={handelChange}
            >
                <div
                    className="bg-white rounded-md h-8 w-1/4 flex items-center justify-center absolute left-1 top-[4px] md:group-hover:w-[184px] group-hover:w-[136px] z-10 duration-500"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right-pipe"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 6l6 6l-6 6" /><path d="M17 5v13" /></svg>
                </div>
                <p className="translate-x-2 text-base text-white font-semibold ps-5">Connect us</p>
            </button>

        </div>
    )
}

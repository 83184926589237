// import { InputStandard } from '../../components/standard.input';
// import { toast } from '../../../shadcn/ui/use-toast';
import { IconLoader, IconLogin } from '@tabler/icons-react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { setadminDetails } from '../../../redux/admin.slice/admin.slice';
import { toast } from '../../../shadcn/ui/use-toast';
import { setadminDetails } from '../../../redux/admin.slice/admin.slice';
import { InputStandard } from '../../components/standard.input';
import { ButtonIconSquare } from '../../components/buttons';
import { useAuthenticationHook, useGetAdminByToken } from '../../API/rest.authentication';


type formData = {
  M01_email: string;
  M01_password: string;
}



export default function Loginpage() {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const {
    loginByPassword,
    isLoginning,
  } = useAuthenticationHook()

  const {
    adminData,
    adminError,
    isgetAdminbyTokenFetching,
    isgetAdminbyTokenLoading
  } = useGetAdminByToken({
    mode:'public'
  })



  const { register, handleSubmit,  setError,  formState: { errors } } = useForm<formData>({
    defaultValues: {
      M01_email: '',
      M01_password: '',
    }
  });

  const onSubmit = async (data: formData) => {

    const response = await loginByPassword(data)
    console.log(response)
    if (response.success === true) {

      dispatch(setadminDetails(response.data))
      navigate('/admin/home/dashboard')

    } else {

      response.validationErrors?.forEach((error) => {
        setError(error.key as keyof formData, {
          type: 'manual',
          message: error.message,
        })
      })

      toast({
        title: 'Error',
        description: response.msg,
      })
    }

  }

  // handle if login redirect to dashboard
  useEffect(
    () => {

      if (!isgetAdminbyTokenFetching && !adminError && adminData && !isgetAdminbyTokenLoading) {

        if (adminData.statusCode !== 401) {
          navigate('/admin/home/dashboard',{replace:true})
        }
      }

    }, [isgetAdminbyTokenFetching]
  )

  if (isgetAdminbyTokenFetching) {
    return <div>Loading...</div>
  }


  return (
    <div className="md:p-8 bg-gray-200 h-screen relative z-[10000]">
      <div
        className="h-full bg-cover bg-center flex items-center justify-center rounded-md">
        <div className="">
          <div className="bg-[#ffffff69] w-[400px] h-1/2 md:h-full rounded-md p-3">

            <div className="flex items-center mt-6 h-[50%]">
              <form
                className="w-full text-center flex justify-center items-center flex-col gap-2"
                onSubmit={handleSubmit(onSubmit)}
              >
                <InputStandard
                  placeholder="Enter Email"
                  title='Email'
                  type="text"
                  {...register("M01_email", {
                    required: "Email is required",
                    // pattern: {
                    //   value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    //   message: "Invalid email address",
                    // },
                  })}
                  error={errors.M01_email?.message}
                />
                <InputStandard
                  placeholder="Enter Password"
                  title='Password'
                  type="text"
                  {...register("M01_password", {
                    required: "Password is required",
                    minLength: {
                      value: 6,
                      message: "Password must be at least 6 characters"
                    }
                  })}
                  error={errors.M01_password?.message}
                />
                <ButtonIconSquare
                  text='Login'
                  type='submit'
                  variant='secondary'
                  icon={isLoginning ? <IconLoader className='animate-spin' /> : <IconLogin />}
                />

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

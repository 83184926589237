import { IconLoader2, IconTrashFilled, IconX } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { StandardAlertDialog } from '../../../../../components/standard.alert-dialog'
import { TablePlater } from '../../../../../components/standard.table'
import { ModalAddProduct } from './component.addModal'
import FeaturedProducts from './featuredProducts'
import { FeaturedProductData, useFeaturedProductGetAllAPI, useFeaturedProductGetAPI } from '../../../../../API/rest.featuredProducts'
import { toast } from '../../../../../../shadcn/ui/use-toast'
// import { ImportExcelModal } from './component.importExcelModal'


export function FeaturedProductPage() {


    const [openAddModal, setOpenAddModal] = useState(false)
    const [openDeleteImageModal, setOpenDeleteImageModal] = useState(false)
    const [deleteImageIndex, setdeleteImageIndex] = useState<number>()
    const [selectedCarouselId, setSelectedProductId] = useState<null | string>(null)
    const [mode, setMode] = useState<'add' | 'edit'>('add')
    const {
        featuredProducts,
        error,
        isError,
        isFetching,
        isFetched,
        isLoading,
        refetch: refetchCarousel
    } = useFeaturedProductGetAllAPI({
        trigger:true
    })
    const [carouselDat, setCarouselDat] = useState<FeaturedProductData[]>([])

    const {
        createFeaturedProduct,
        deleteFeaturedProduct,
        isCreatingFeaturedProduct,
        isDeletingCarousel,
        isReordering,
        reorderFeaturedProductAPI
    } = useFeaturedProductGetAPI()






    const deleteCarouselI = async () => {
        console.log(deleteImageIndex)
        if (deleteImageIndex!==undefined) {
            const deleteImage = carouselDat.find((image, index) => index === deleteImageIndex)

            const response = await deleteFeaturedProduct(String(deleteImage?.id!))
            if (response.success === true) {
                toast({
                    title: 'Success',
                    description: response.msg,
                })
                refetchCarousel()
                setOpenDeleteImageModal(false) 
            } else {
                toast({
                    title: 'Error',
                    description: response.msg,
                })
            }
            setSelectedProductId(null)
        }

    }


    useEffect(
        () => {

            //if successfully loaded
            if (isFetched && !isError) {



            }
            if (isError && isFetching === false) {
                toast({
                    description: String(error),
                })

            }
        }, [isFetching]
    )

    useEffect(
        () => {

            if (!isLoading && !isError) {

                setCarouselDat(featuredProducts || [])

            }

        }, [isFetching, isReordering]
    )

    const isAnythingFetching = isFetching || isReordering

    return (
        <div className=' grow  flex flex-col overflow-auto'>
            <StandardAlertDialog
                title="Are you absolutely sure?"
                description="This action cannot be undone. Are you sure you want to delete this Carousel?"
                positiveLabel="Delete"
                negativeLabel="Cancel"
                positiveAction={deleteCarouselI}
                negativeAction={() => setOpenDeleteImageModal(false)}
                positiveIcon={isDeletingCarousel ? <IconLoader2 size={16} className=" animate-spin" /> : <IconTrashFilled size={16} />}
                negativeIcon={<IconX size={16} />}
                disableNegativeButton={isDeletingCarousel}
                disablePositiveButton={isDeletingCarousel}
                show={openDeleteImageModal}
            />
            <ModalAddProduct
                show={openAddModal}
                setShow={setOpenAddModal}
                refetchProducts={refetchCarousel}
                mode={mode}
                productId={selectedCarouselId}
            />
            {isAnythingFetching && <div className=' px-2 p-1 rounded-md bg-maxgray-800/30 whitespace-nowrap w-min absolute left-1/2 -translate-x-1/2'>
                Updating...
            </div>}
            {/* <ImportExcelModal
            show={openImportModal}
            setShow={setOpenImportModal}
        /> */}
            <TablePlater className=' flex flex-col gap-2'>
                <FeaturedProducts
                    items={carouselDat || []}
                    setItems={items => setCarouselDat(items)}
                    disabled={isAnythingFetching}
                    onDropImage={async (items: FeaturedProductData[]) => {
                        console.log(carouselDat)

                        const response = await reorderFeaturedProductAPI(carouselDat.map(item => String(item.id!)))

                        if (response.success === true) {
                            toast({
                                title: 'Success',
                                description: response.msg,
                            })
                            refetchCarousel()
                        } else {
                            toast({
                                title: 'Error',
                                description: response.msg,
                            })
                        }

                    }}
                    onClickAdd={() => setOpenAddModal(true)}
                    ondeleteImage={
                        (index) => {
                            setdeleteImageIndex(index);
                            setOpenDeleteImageModal(true);
                        }
                    }
                />
            </TablePlater>
        </div>
    );


}




import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { IconX } from "@tabler/icons-react";
import { imageType } from '../component.addModal';

interface ImageBoxProps {
    image: imageType;
    index: number;
    moveImage: (dragIndex: number, hoverIndex: number) => void;
    dropImage: (dragIndex: number, hoverIndex: number) => void;
    onDelete: (index: number) => void,
    disabled: boolean,

}

const DraggableImageBox: React.FC<ImageBoxProps> = ({ image, index, moveImage, onDelete,dropImage,disabled=false }) => {
    const ref = useRef<HTMLDivElement>(null);

    const [{ isDragging }, drag] = useDrag({
        type: 'image',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end(item, monitor) {

            if (!monitor.didDrop()) {
                moveImage(item.index, index);
            }
            console.log(item.index)
        },
    });

    const [, drop] = useDrop({
        accept: 'image',
        hover: (draggedItem: { index: number }) => {
            if (draggedItem.index !== index) {
                moveImage(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
        drop(item, monitor) {
            dropImage(item.index, index);
        },
    });

    drag(drop(ref));

    return (
        <div
            ref={ref}
            style={{
                opacity: isDragging ? 0.5 : 1,
                height: 100,
                width: 100,
                margin: '10px',
                position: 'relative',
                cursor: 'move',
            }}
        >
            <img
                src={image.imageLink}
                alt={`Image ${image.order}`}
                style={{ height: '100%', width: '100%', objectFit: 'cover' }}
            />
            <IconX
                style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    cursor: 'pointer',
                    color: 'red',
                }}
                onClick={(e) => {
                    e.preventDefault();
                    if(disabled===false)
                        onDelete(index);
                }}
                
            />
        </div>
    );
};

export default DraggableImageBox;


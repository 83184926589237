import { useMutation, useQuery } from "@tanstack/react-query"
import { restAPI } from "./axiosAPI"
export type AdminData = {
    _id: string;
    M01_name: string;
    M01_email: string;
    M01_phone: string;
    M01_address: string;
    M01_role: string;
    M01_is_active: number;
    createdAt: string;
    updatedAt: string;
}
export function useAuthenticationHook() {

    const api = restAPI()

    const { mutateAsync: loginByPassword,
        isPending: isLoginning,
        isError: isLoginError,
        error: loginError,


    } = useMutation({
        mutationFn: async ({
            M01_email,
            M01_password
        }: {
            M01_email: string;
            M01_password: string;
        }) => {
            const response = await api.post<{ data: AdminData }, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/login`,
                data: {
                    M01_email,
                    M01_password
                },
                withCredentials: true
            })


            if (response.success === false) {
                return response
            }
            return response
        },

    })
    const { mutateAsync: adminTest,
        isPending: isAdminTesting,
        isError: isAdminTestingError,
        error: adminTestingError,


    } = useMutation({
        mutationFn: async () => {
            const response = await api.post<{ data: AdminData }, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/admin-test`,
                withCredentials: true
            })




            if (response.success === false) {
                return response
            }
            return response
        },

    })

    const { 
        mutateAsync: logoutAdmin,
        isPending:isLogoutAdminPending,
    } = useMutation({
        mutationFn: async () => {
            const response = await api.post({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/logout`,
                withCredentials: true
            })
            if (response.success === false) {
                return response
            }
            window.location.href = '/admin/login'
            return response
        },
    })



    return {
        loginByPassword,
        isLoginning,
        isLoginError,
        loginError,
        adminTest,
        isAdminTesting,
        isAdminTestingError,
        adminTestingError,
        logoutAdmin,
        isLogoutAdminPending
    }


    // const {data,error,isError,isFetched,isFetching,isLoading,refetch} = useQuery({
    //     queryKey:[ QUERY_KEY],
    //     queryFn:async()=>{
    //         const response = await api.get<any>({
    //             url:'http://localhost:3005/api/products',
    //         })

    //         if(response.success===false){
    //             console.log('api error')
    //             throw response
    //         }
    //         // console.log(response)
    //         return response.products
    //     },
    //     refetchOnWindowFocus:false,
    //     enabled:false
    // })

    // const fetchProduct = async ({
    //     pageNo
    // }:{pageNo:number})=>{

    //     const response = await refetch()
    //     return response;

    // }


}


export function useGetAdminByToken({
    trigger = true,
    mode = 'protected'
}: {
    trigger?: boolean,
    mode?:'protected' | 'public'
}) {
    const api = restAPI()
    const {
        data: adminData,
        error: adminError,
        isError: isgetAdminbyTokenError,
        isFetched: isgetAdminbyTokenFetched,
        isFetching: isgetAdminbyTokenFetching,
        isLoading: isgetAdminbyTokenLoading,
        refetch: getAdminbyTokenRefetch
    } = useQuery({
        queryKey: ['get-admin-by-token'],
        queryFn: async () => {
            const response = await api.get<{ data: AdminData }>({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/get-admin`,
                withCredentials: true,
                mode: mode,
            
            })





            if (response.success === false) {
                throw response
            }
            return response
        },
        enabled: trigger,
        retry: false,
        refetchOnWindowFocus: false
    })

    return {
        adminData,
        adminError,
        isgetAdminbyTokenError,
        isgetAdminbyTokenFetched,
        isgetAdminbyTokenFetching,
        isgetAdminbyTokenLoading,
        getAdminbyTokenRefetch
    }

}
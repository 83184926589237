import { IconCloudUp, IconLoader2, IconPlus, IconTrashFilled, IconX } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { StandardTablePagination } from '../../../../../components/standard.pagination'
import { ModalAddSKU } from './component.addModal'
import { DropdownMenuDemo } from './component.dropdownMenu'
import { Pagination, PaginationKeys } from '../../../../../../types/pagination'
import { StandardTable, StandardTableHeader, StandardTableRow, TablePlater } from '../../../../../components/standard.table'
import { useProductSKUAPI, useSKUGetAllAPI } from '../../../../../API/rest.product_sku'
import { toast } from '../../../../../../shadcn/ui/use-toast'
import { StandardAlertDialog } from '../../../../../components/standard.alert-dialog'
import { ButtonIconSquare } from '../../../../../components/buttons'
// import { ImportExcelModal } from './component.importExcelModal'




export function ProductSKUPage() {

    const searchParams = new URLSearchParams(window.location.search)
    const currentPageFromUrl = searchParams.get(PaginationKeys.CURRENT_PAGE)
    const dataPerPage = 15
    const [pagination, setPagination] = useState<Pagination>({
        currentPage: currentPageFromUrl ? parseInt(currentPageFromUrl) : 1,
        totalPages: Math.ceil(1 / dataPerPage) || 1,
        dataPerPage: dataPerPage
    })
    const [productsRow, setProductsRow] = useState<StandardTableRow[]>([])

    const [previousDataLength, setPreviousDataLength] = useState(0)
    const [openAddModal, setOpenAddModal] = useState(false)
    const [selectedProductId, setSelectedProductId] = useState<null | string>()
    const [selectedParentId, setSelectedParentId] = useState<null | string>()
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [mode, setMode] = useState<'add' | 'edit'>('add')
    const [openImportModal, setOpenImportModal] = useState(false)



    const {
        isLoading: isLoadingSku,
        isErrorSku,
        skus,
        refetch: refetchSkus,
        isFetching: isFetchingSku,
        isFetched: isFetchedSku,
        error: errorSku
    } = useSKUGetAllAPI({
        page_size: pagination.dataPerPage,
        page: pagination.currentPage
    })

    const {
        deleteProductSKUById,
        isDeletingProductSKU
    } = useProductSKUAPI({})


    const headers: StandardTableHeader[] = [
        { label: 'Product Name', id: 'product_name' },
        { label: 'SKU', id: 'sku' },
        { label: 'Price', id: 'price' },
        { label: 'Quantity', id: 'quantity' },
        { label: '', id: 'actions', width: '20px' },
    ]



    const deleteProduct = async () => {

        if (selectedProductId) {
            const response = await deleteProductSKUById(selectedProductId)
            if (response.success === true) {
                toast({
                    title: 'Success',
                    description: response.msg,
                })
                refetchSkus()
                setOpenDeleteModal(false)
                setSelectedProductId(null)
            } else {
                toast({
                    title: 'Error',
                    description: response.msg,
                })
            }
        }

    }


    useEffect(
        () => {
            // setting prevouse data length for loading optimization
            if (isFetchedSku && !isFetchingSku) {
                setPreviousDataLength(skus?.products_skus.length || 0)
            }

            //if successfully loaded
            if (isFetchedSku && !isErrorSku) {
                setPagination({
                    currentPage: currentPageFromUrl ? parseInt(currentPageFromUrl) : 1,
                    totalPages: Math.ceil(skus?.pagination.totalPages!) || 1,
                    dataPerPage: dataPerPage
                })
                setProductsRow(skus?.products_skus?.map(
                    (product) => {
                        return ({
                            id: String(product.id),
                            datas: {
                                product_name: { id: 'product_name', data: product.m06_product_sku_name },
                                sku: { id: 'sku', data: product.m06_sku },
                                price: { id: 'price', data: product.m06_price },
                                quantity: { id: 'quantity', data: product.m06_quantity },
                                actions: {
                                    id: 'actions', data: <DropdownMenuDemo
                                        rowId={String(product.id)}
                                        onDeleteAction={(rowid) => {
                                            setSelectedProductId(rowid);
                                            setOpenDeleteModal(true);
                                        }}
                                        onEditAction={(rowid) => {
                                            setSelectedProductId(rowid);
                                            setMode('edit');
                                            setOpenAddModal(true);
                                        }}
                                    />
                                }
                            }
                        })

                    }
                ) || [])

            }
            if (isErrorSku && isFetchingSku === false) {
                toast({
                    description: String(errorSku?.message || 'Error while fetching data'),
                    title: 'Error',
                })

            }
        }, [isFetchingSku]
    )







    return (
        <div className=' grow  flex flex-col overflow-auto'>
            <StandardAlertDialog
                title="Are you absolutely sure?"
                description="This action cannot be undone. Are you sure you want to delete this Category?"
                positiveLabel="Delete"
                negativeLabel="Cancel"
                positiveAction={deleteProduct}
                negativeAction={() => setOpenDeleteModal(false)}
                positiveIcon={isDeletingProductSKU ? <IconLoader2 size={16} className=" animate-spin" /> : <IconTrashFilled size={16} />}
                negativeIcon={<IconX size={16} />}
                disableNegativeButton={isDeletingProductSKU}
                disablePositiveButton={isDeletingProductSKU}
                show={openDeleteModal}
            />
            <ModalAddSKU
                show={openAddModal}
                setShow={setOpenAddModal}
                refetchProducts={refetchSkus}
                mode={mode}
                productId={selectedProductId}
                parentId={selectedParentId}
            />
            {/* <ImportExcelModal
                show={openImportModal}
                setShow={setOpenImportModal}
            /> */}
            <TablePlater className=' flex flex-col gap-2'>
                {/* Buttons section */}
                <div className=' flex justify-end w-full gap-4'>
                    {/* <ButtonIconSquare var   iant="white" icon={<IconCloudUp />} text="Import" onClick={() => { setOpenImportModal(true) }} /> */}
                    <ButtonIconSquare variant="secondary" icon={<IconPlus />} text="Add SKU" onClick={() => { setSelectedProductId(undefined); setSelectedParentId(undefined); setMode('add'); setOpenAddModal(true); }} />
                </div>

                <StandardTable
                    headers={headers}
                    rows={productsRow}
                    isLoading={isLoadingSku}
                    rowsPerPrevousePage={previousDataLength}
                    isUpdating={isFetchingSku}
                    isError={isErrorSku && isFetchingSku === false}
                    onRetry={() => refetchSkus()}
                />
                <StandardTablePagination currentPage={pagination.currentPage} totalPages={pagination.totalPages} onChangePage={(page) => {
                    setPagination({ ...pagination, currentPage: page })
                }} />

            </TablePlater>
        </div>
    )

}




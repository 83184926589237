
import { IconEdit, IconLoader, IconLoader2, IconPlus, IconTrashFilled, IconX } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import ImageContainer from './imageComponent/page.imageComponent'
import { ProductData, useProductAPI } from '../../../../../API/rest.product'
import { useProductCategoryGetAllAPI } from '../../../../../API/rest.product_category'
import { useProductSKUAPI } from '../../../../../API/rest.product_sku'
import { toast } from '../../../../../../shadcn/ui/use-toast'
import { ModalStandard, ModalStandardContent, ModalStandardDescription, ModalStandardFooter, ModalStandardHeader, ModalStandardTitle } from '../../../../../components/standard.modal'
import { StandardAlertDialog } from '../../../../../components/standard.alert-dialog'
import { SelectStandard } from '../../../../../components/standard.select'
import { InputStandard, TextAreaStandard } from '../../../../../components/standard.input'
import { Label } from '../../../../../../shadcn/ui/label'
import { ImageBox } from '../../../../../components/image_box'
import { FileInputDragDropStandard } from '../../../../../components/standard.fileDroper'
import { ButtonIconSquare } from '../../../../../components/buttons'
import { useProductVariationAPI } from '../../../../../API/rest.productVariation'


export type variationOptionItem = {
    name: string,
    id: string | number,
    error?: string,
    disabled: boolean,
    variationId: string | undefined
}

export interface VariationItem {
    id: string | number;
    variation: string;
    options: variationOptionItem[];
    error?: string;
    disabled: boolean
    productId: string
}


type Props = {

    show?: boolean,
    setShow?: (show: boolean) => void,
    refetchProducts: () => void,
    mode: 'add' | 'edit',
    productId?: string | null,
    parentId?: string | null
}


export type formData = {
    Sku: {
        _id?: string,
        m06_sku: string,
        m06_product_sku_name: string,
        m06_description: string,
        m06_thumbnail_image: string,
        m06_mrp: number,
        m06_price: number,
        m06_quantity: number,
        m06_is_new: 1 | 0,
        m06_single_order_limit: number | null,
        m06_is_active: 1 | 0,
        m06_m04_product_category_id: string,
        m06_meta_description: string,
        Images: ({
            m07_order: number,
            m07_is_active: 1 | 0,
            ImageData: File
        })[]
    }
}

export type imageType = {
    id?: string,
    order: number,
    is_active: 1 | 0,
    ImageData: File | null,
    imageLink: string,
    m07_m06_product_sku_id?: string
}

export function ModalAddSKU(props: Props) {

    const { register, handleSubmit, setValue, getValues, reset, formState: { errors }, control, watch } = useForm<formData>({
        defaultValues: {
            Sku: {
                m06_sku: '',
                m06_product_sku_name: '',
                m06_description: '',
                m06_thumbnail_image: '',
                m06_mrp: 0,
                m06_price: 0,
                m06_quantity: 0,
                m06_is_new: 0,
                m06_single_order_limit: null,
                m06_is_active: 1,
                Images: [],
            }
        }
    });

    const [selectedThumbnailImage, setSelectedThumbnailImage] = useState<File>()
    const [selectedThumbnailLink, setSelectedThumbnailLink] = useState<string>()
    const [selectedImage, setSelectedImage] = useState<File>()
    const [images, setImages] = useState<imageType[]>([])
    const [productData, setProductData] = useState<ProductData>()
    const [openDeleteImageModal, setOpenDeleteImageModal] = useState(false)
    const [deleteImageIndex, setdeleteImageIndex] = useState<number>()

    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [updateUi, setUpdateUi] = useState(false)

    const {
        productCategories,
        isLoading: isProductCategoryLoading,
        isFetching: isProductCategoryFetching,
    } = useProductCategoryGetAllAPI({
        trigger: props.show,
        page:1,
        page_size:200
    })

    const {
        createProductSKU,
        isCreatingProductSKU,
        readProductSKUById,
        isReadingProductSKUById,
        updateProductSKUById,
        isUpdatingProductSKU,
        reorderImageSKU,
        isReorderingImageSKU,
        deleteProductSKUImageById,
        isDeletingProductSKUImageById,
        createProductSKUImage,
        isCreatingProductSKUImage
    } = useProductSKUAPI({

    })

    const {
        readProductById,
        isReadingProductById
    } = useProductAPI({})

    const readProductSKU = async () => {
        // load product sku
        const response = await readProductSKUById({
            id: props.productId!
        })

        if (response.success === true) {
            reset({
                Sku: {
                    _id: response.data.id,
                    m06_sku: response.data.m06_sku,
                    m06_product_sku_name: response.data.m06_product_sku_name,
                    m06_meta_description: response.data.m06_meta_description,
                    m06_description: response.data.m06_description,
                    m06_thumbnail_image: `${process.env.REACT_APP_BACKEND_URL_API_ONLY}/${response.data.m06_thumbnail_image}`,
                    m06_mrp: response.data.m06_mrp,
                    m06_price: response.data.m06_price,
                    m06_quantity: response.data.m06_quantity,
                    m06_is_new: response.data.m06_is_new ? 1 : 0,
                    m06_single_order_limit: response.data.m06_single_order_limit,
                    m06_is_active: response.data.m06_is_active,
                    m06_m04_product_category_id: String(response.data.m06_m04_product_category),
                    Images: [],
                }
            })
            setSelectedThumbnailLink(`${process.env.REACT_APP_BACKEND_URL_API_ONLY}/${response.data.m06_thumbnail_image}`)
            setImages(response.data.Images.map((image, index) => ({
                id: image.id,
                ImageData: null,
                imageLink: `${process.env.REACT_APP_BACKEND_URL_API_ONLY}/${image.m07_image_path}`,
                order: image.m07_order,
                is_active: image.m07_is_active,
                m07_m06_product_sku_id: image.m07_m06_product_sku_id
            })))


        } else {
            toast({
                description: response.msg,
                title: "error",
                // variant:''
            })
        }
    }

    const {
        updateVariationConfiguration,
        isUpdatingVariationConfiguration
    } = useProductVariationAPI({})

    useEffect(
        () => {


            if (props.show && props.mode === 'add') {
                reset({
                    Sku: {
                        m06_sku: '',
                        m06_product_sku_name: '',
                        m06_description: '',
                        m06_thumbnail_image: '',
                        m06_mrp: 0,
                        m06_price: 0,
                        m06_quantity: 0,
                        m06_is_new: 0,
                        m06_single_order_limit: null,
                        m06_is_active: 1,
                        m06_meta_description:'',
                        Images: [],
                    }
                })
            }
            if (props.mode === 'edit' && props.productId && props.show) {
                readProductSKU()
            }
        }, [props.show, updateUi]
    )

    const onSubmit = async (data: formData) => {

        setIsSubmitting(true)
        if (props.mode === 'add') {
            const response = await createProductSKU({
                ProductSKU: {
                    Sku: {
                        m06_meta_description: data.Sku.m06_meta_description,
                        m06_m04_product_category_id: data.Sku.m06_m04_product_category_id!,
                        m06_sku: data.Sku.m06_sku,
                        m06_product_sku_name: data.Sku.m06_product_sku_name,
                        m06_description: data.Sku.m06_description,
                        m06_mrp: data.Sku.m06_mrp,
                        m06_price: data.Sku.m06_price,
                        m06_quantity: data.Sku.m06_quantity,
                        m06_is_new: data.Sku.m06_is_new,
                        m06_single_order_limit: data.Sku.m06_single_order_limit,
                        m06_is_active: data.Sku.m06_is_active,
                        Images: images.map(image => {
                            return {
                                ImageData: image.ImageData!,
                                m07_is_active: image.is_active,
                                m07_order: image.order
                            }
                        }),
                        m06_thumbnail_image: selectedThumbnailImage!
                    }
                }
            })
            if (response.success === true) {
                toast({
                    description: response.msg,
                    // variant:''
                })
                props.setShow?.(false);
                props.refetchProducts()
            } else {
                toast({
                    description: response.msg,
                    title: "error",
                    // variant:''
                })
            }
        } else if (props.mode === 'edit' && props.productId) {
            const response = await updateProductSKUById({
                id: props.productId,
                data: {
                    m06_sku: data.Sku.m06_sku,
                    m06_meta_description: data.Sku.m06_meta_description,
                    m06_product_sku_name: data.Sku.m06_product_sku_name,
                    m06_description: data.Sku.m06_description,
                    m06_mrp: data.Sku.m06_mrp,
                    m06_price: data.Sku.m06_price,
                    m06_quantity: data.Sku.m06_quantity,
                    m06_is_new: data.Sku.m06_is_new,
                    m06_single_order_limit: data.Sku.m06_single_order_limit,
                    m06_m04_product_category_id:data.Sku.m06_m04_product_category_id,
                    m06_is_active: data.Sku.m06_is_active,
                    is_thumnail_new: selectedThumbnailImage ? 1 : 0,
                    m06_thumbnail_image: selectedThumbnailImage ? selectedThumbnailImage : data.Sku.m06_thumbnail_image,
                },
            })
            if (response.success === true) {
                toast({
                    description: response.msg,
                    // variant:''
                })
                props.setShow?.(false);
                props.refetchProducts()
            } else {
                toast({
                    description: response.msg,
                    title: "error",
                    // variant:''
                })
            }
        }
        setIsSubmitting(false)
    };

    const deleteImage = async ()=>{

        const deleteImage  = images.find((image,index) =>index===deleteImageIndex )
        if(!deleteImage){
            toast({
                description:'image not found',
                title:'error',
            })
        }
        const response = await deleteProductSKUImageById(deleteImage?.id!)

        if(response.success===true){
            toast({
                description:response.msg,
            })

            setImages(images.filter((image,index)=>index!==deleteImageIndex))
            setOpenDeleteImageModal(false)
            setdeleteImageIndex(undefined)
        }else{
            toast({
                description:response.msg,
                title:'error',
            })
        }
    }

    const isAnyThingFetching = 
        isReadingProductById ||
        isReadingProductSKUById ||isCreatingProductSKUImage||
        isCreatingProductSKU || isUpdatingProductSKU
        || isUpdatingVariationConfiguration || isReorderingImageSKU

    useEffect(() => {
        if (props.show === false) {
            setSelectedThumbnailImage(undefined)
            setSelectedThumbnailLink(undefined)
            setImages([])
            reset({
                Sku: {
                    m06_product_sku_name: '',
                    m06_sku: '',
                    m06_description: '',
                    m06_mrp: 0,
                    m06_price: 0,
                    m06_quantity: 0,
                    m06_is_new: 0,
                    m06_single_order_limit: null,
                    m06_is_active: 1,
                    m06_thumbnail_image: '',
                    Images: [],
                }
            })
            setProductData(undefined)
            // setIsImageDeleted(false)
        }
    }, [props.show])


    return (
        <ModalStandard
            open={props.show}
            onOpenChange={props.setShow}
        >

            <ModalStandardContent onOpenAutoFocus={(e) => e?.preventDefault()} className='bg-maxgray-100'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalStandardHeader >
                        <StandardAlertDialog
                            title="Are you absolutely sure?"
                            description="This action cannot be undone. Are you sure you want to delete the image?"
                            positiveLabel="Delete"
                            negativeLabel="Cancel"
                            positiveAction={deleteImage}
                            negativeAction={() => setOpenDeleteImageModal(false)}
                            positiveIcon={isDeletingProductSKUImageById ? <IconLoader2 size={16} className=" animate-spin" /> : <IconTrashFilled size={16} />}
                            negativeIcon={<IconX size={16} />}
                            disableNegativeButton={isDeletingProductSKUImageById}
                            disablePositiveButton={isDeletingProductSKUImageById}
                            show={openDeleteImageModal}
                        />
                        <ModalStandardTitle >{props.mode === 'edit' ? 'Edit Product' : 'Add Product'}</ModalStandardTitle>
                        <ModalStandardDescription>
                            {/* loading indication */}
                            {isAnyThingFetching && <div className=' px-2 p-1 rounded-md bg-maxgray-800/30 whitespace-nowrap w-min absolute left-1/2 -translate-x-1/2'>
                                Updating...
                            </div>}
                            <form onSubmit={handleSubmit(onSubmit)} className=" pb-3">
                                <div className='grid gap-4 py-4 xs2:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 '>
                                <div className=" lg:col-span-1 xl:col-span-1">
                                        <SelectStandard
                                            title='Category'
                                            {...register("Sku.m06_m04_product_category_id", { required: "Category  name is required" })}
                                            error={errors.Sku?.m06_m04_product_category_id?.message}
                                            disabled={isAnyThingFetching || productCategories?.some((category) => category.m04_m04_parent_category_id === props.productId)}
                                            value={watch("Sku.m06_m04_product_category_id")}
                                            options={productCategories?.map((category) => ({
                                                label: category.m04_category_name,
                                                value: String(category.id)!
                                            })) || []}
                                            onChange={(value) => {
                                                console.log(value)
                                                setValue("Sku.m06_m04_product_category_id", value.target.value)
                                            }}
                                        />
                                    </div>

                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <InputStandard
                                            placeholder='name'
                                            title='SKU name'
                                            {...register("Sku.m06_product_sku_name", { required: "SKU name is required" })}
                                            error={errors.Sku?.m06_product_sku_name?.message}
                                            disabled={isAnyThingFetching}
                                        />
                                    </div>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <InputStandard
                                            placeholder='name'
                                            title='Meta description'
                                            {...register("Sku.m06_meta_description",)}
                                            error={errors.Sku?.m06_meta_description?.message}
                                            disabled={isAnyThingFetching}
                                        />
                                    </div>
                                    <div className=" col-span-1 sm:col-span-2 xl:col-span-3">
                                        <TextAreaStandard
                                            placeholder='Description'
                                            title='Description'
                                            {...register("Sku.m06_description", { required: "Description is required" })}
                                            error={errors.Sku?.m06_description?.message}
                                            disabled={isAnyThingFetching}
                                        />
                                    </div>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <InputStandard
                                            placeholder='SKU'
                                            title='SKU'
                                            {...register("Sku.m06_sku", { required: "SKU is required" })}
                                            error={errors.Sku?.m06_sku?.message}
                                            disabled={isAnyThingFetching}
                                        />
                                    </div>

                                    

                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <InputStandard
                                            placeholder='MRP'
                                            title='MRP'
                                            type='number'
                                            {...register("Sku.m06_mrp", {
                                                valueAsNumber: true,
                                                required: "MRP is required",
                                                validate: value => value >= getValues("Sku.m06_price") || "MRP should be greater than or equal to Price"
                                            })}
                                            error={errors.Sku?.m06_mrp?.message}
                                            disabled={isAnyThingFetching}
                                        />
                                    </div>

                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <InputStandard
                                            placeholder='Price'
                                            title='Price'
                                            type='number'
                                            {...register("Sku.m06_price", { valueAsNumber: true, required: "Price is required" })}
                                            error={errors.Sku?.m06_price?.message}
                                            disabled={isAnyThingFetching}
                                        />
                                    </div>

                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <InputStandard
                                            placeholder='Quantity'
                                            title='Quantity'
                                            type='number'
                                            {...register("Sku.m06_quantity", { valueAsNumber: true, required: "Quantity is required" })}
                                            error={errors.Sku?.m06_quantity?.message}
                                            disabled={isAnyThingFetching}
                                        />
                                    </div>



                                    {/* <div className=" lg:col-span-1 xl:col-span-1">
                                        <InputStandard
                                            placeholder='Single Order Limit'
                                            title='Single Order Limit'
                                            type='number'
                                            {...register("Sku.m06_single_order_limit")}
                                            error={errors.Sku?.m06_single_order_limit?.message}
                                            disabled={isAnyThingFetching}
                                        />
                                    </div> */}

                                    <div className=" lg:col-span-1 xl:col-span-1 ">
                                        <Label className="text-xs font-medium dark:text-vstargray-300 " >
                                            Thumbnail image
                                        </Label>
                                        {
                                            selectedThumbnailLink ?
                                                <ImageBox
                                                    height={100}
                                                    width={100}
                                                    imagelink={selectedThumbnailLink}
                                                    onDelete={() => {
                                                        setSelectedThumbnailLink(undefined)
                                                        setSelectedThumbnailImage(undefined)

                                                    }}
                                                    disabled={isAnyThingFetching}
                                                />
                                                : <FileInputDragDropStandard
                                                    height={100}
                                                    width={100}
                                                    title=' '
                                                    description=' '
                                                    accept={{
                                                        'image/png': ['.png'],
                                                        'image/jpg': ['.jpg'],
                                                        'image/jpeg': ['.jpeg'],
                                                    }}
                                                    onDrop={(file) => {
                                                        if (file[0].size > 500000) {
                                                            toast({
                                                                description: 'Image size should not be above 500Kb'
                                                            })
                                                            return;
                                                        }
                                                        // File type validation
                                                        const allowedFileTypes = ['image/png', 'image/jpg', 'image/jpeg'];
                                                        if (!allowedFileTypes.includes(file[0].type)) {
                                                            toast({
                                                                description: 'Only PNG, JPG, and JPEG files are allowed'
                                                            });
                                                            return;
                                                        }
                                                        // Create Blob URL for shorter link
                                                        const blobUrl = URL.createObjectURL(file[0]);
                                                        setSelectedThumbnailLink(blobUrl)
                                                        setSelectedThumbnailImage(file[0]);
                                                    }}
                                                    onClear={() => setSelectedThumbnailImage(undefined)}
                                                    file={selectedThumbnailImage}
                                                    disabled={isAnyThingFetching}
                                                />
                                        }

                                    </div>

                                    <div className='col-span-1 xs:col-span-2 xl:col-span-3'>
                                        <Label>Images</Label>
                                        <ImageContainer
                                            images={images}
                                            setImages={(images) => {
                                                setImages(images)
                                            }}
                                            disabled={isAnyThingFetching}
                                            onDropImage={(images, imagePrev, setImagesPrev) => {
                                                if (props.mode === 'edit') {
                                                    reorderImageSKU(
                                                        {
                                                            skuId: getValues('Sku._id')!,
                                                            Images: images.map((image, index) => ({
                                                                id: image.id!,
                                                                m07_m06_product_sku_id: image.m07_m06_product_sku_id!,
                                                                m07_order: image.order
                                                            }))
                                                        }
                                                    ).then((response) => {
                                                        if (response.success === true) {
                                                            toast({
                                                                description: response.msg
                                                            })
                                                            setImagesPrev(images)
                                                        } else {
                                                            toast({
                                                                description: response.msg,
                                                                title: 'Error'
                                                            })
                                                            setImages(imagePrev)
                                                            setImagesPrev(imagePrev)
                                                        }
                                                    })
                                                } else {
                                                    setImagesPrev(images)
                                                    // setImages(images)

                                                }
                                            }}
                                            ondeleteImage={(index) => {
                                                if (props.mode === 'edit') {
                                                    setdeleteImageIndex(index);
                                                    setOpenDeleteImageModal(true);
                                                } else {
                                                    setImages(images.filter((_, i) => i !== index));
                                                }
                                            }}
                                        />
                                        <FileInputDragDropStandard
                                            title=' '
                                            description=' '
                                            accept={{
                                                'image/png': ['.png'],
                                                'image/jpg': ['.jpg'],
                                                'image/jpeg': ['.jpeg'],
                                            }}
                                            onDrop={async(file) => {
                                                const files = file as File[]
                                                if (files.some(f => f.size > 1048576)) {
                                                    toast({
                                                        description: 'Image size should not be above 1MB'
                                                    })
                                                    return;
                                                }
                                                // File type validation
                                                const allowedFileTypes = ['image/png', 'image/jpg', 'image/jpeg'];
                                                if (files.some(f => !allowedFileTypes.includes(f.type))) {
                                                    toast({
                                                        description: 'Only PNG, JPG, and JPEG files are allowed'
                                                    });
                                                    return;
                                                }
                                                const newImages = files.map((f, index) => {
                                                    const blobUrl = URL.createObjectURL(f);
                                                    return {
                                                        order: Math.max(...images.map(i => i.order), 0) + index + 1,
                                                        is_active: 1,
                                                        ImageData: f,
                                                        imageLink: blobUrl,
                                                        _id:''
                                                    }
                                                })

                                                if(props.mode === 'edit'){
                                                    const response = await createProductSKUImage({
                                                        skuId: getValues('Sku._id')!,
                                                        images:newImages.map(image => {
                                                            return {
                                                                m07_image_path:image.ImageData,
                                                                m07_order:image.order,
                                                                m07_is_active:1
                                                            }
                                                        })
                                                    })
                                                    if(response.success === true){
                                                        response.data.forEach((image,index) => {
                                                            const indexNewImage = newImages.findIndex(newImage => newImage.order === image.m07_order)
                                                            if(indexNewImage !== -1)
                                                                newImages[indexNewImage]._id = String(image.id)
                                                        })
                                                        toast({
                                                            description: response.msg
                                                        })
                                                        console.log(response.data)
                                                
                                                        setImages([...response.data.map(image => ({
                                                            ImageData: null,
                                                            imageLink: `${process.env.REACT_APP_BACKEND_URL_API_ONLY}/${image.m07_image_path}`,
                                                            order: image.m07_order,
                                                            is_active: image.m07_is_active,
                                                            id:image.id,
                                                            m07_m06_product_sku_id:image.m07_m06_product_sku_id
                                                        })) as imageType[]])
                                                    }else{
                                                        toast({
                                                            description: response.msg,
                                                            title: 'error'
                                                        })
                                                    }
                                                }else{
                                                    setImages([...images, ...newImages as imageType[]])
                                                }

                                            }}
                                            onClear={() => setSelectedImage(undefined)}
                                            file={selectedImage}
                                            disabled={isAnyThingFetching}
                                            height={100}
                                            width={100}
                                        />

                                    </div>
                                </div>
                                {/* <div className=' flex gap-3 items-center '>
                                    <div className="flex flex-col gap-2 items-start">
                                        <SwitchDemo
                                            title='Is New'
                                            {...register("Sku.m06_is_new")}
                                            value={watch("Sku.m06_is_new") == 1 ? true : false}
                                            disabled={isAnyThingFetching}
                                        />
                                    </div>
                                    <div className=' flex flex-col gap-2 items-start'>
                                        <SwitchDemo
                                            {...register("Sku.m06_is_active")}
                                            title={'Is Active'}
                                            value={watch('Sku.m06_is_active') == 1 ? true : false}
                                            disabled={isAnyThingFetching}
                                        />
                                    </div>

                                </div> */}
                            </form>
                        </ModalStandardDescription>
                    </ModalStandardHeader>
                    <ModalStandardFooter>
                        <ButtonIconSquare
                            variant='white'
                            icon={<IconX />}
                            text='close'
                            onClick={(e) => { e?.preventDefault(); props.setShow?.(false) }}
                            disabled={isSubmitting}
                        />
                        <ButtonIconSquare
                            variant='secondary'
                            icon={isSubmitting ? <IconLoader className=' animate-spin ' /> : props.mode === 'add' ? <IconPlus /> : <IconEdit />}
                            text={props.mode === 'add' ? 'Add' : 'Edit'}
                            type="submit"
                            disabled={isSubmitting}
                        />
                    </ModalStandardFooter>
                </form>
            </ModalStandardContent>
        </ModalStandard>
    )
}



import React from 'react'
import ContactForm from './Formsection'

export default function FormContact() {
    return (
        <div>
            <ContactForm />
        </div>
    )
}

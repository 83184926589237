import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { AdminData } from '../../admin/API/rest.authentication'
type initialData = {
    adminDetails:Partial<AdminData>
}

const initialState:initialData = {
    adminDetails:{}
}

export const adminReducer = createSlice({
  name: 'admin',
  initialState:initialState,
  reducers: {
    setadminDetails: (state, action: PayloadAction<AdminData>) => {
      state.adminDetails = action.payload
      return state
    },
  },
})

// Action creators are generated for each case reducer function
export const { setadminDetails } = adminReducer.actions

export default adminReducer.reducer
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";

export default function Events() {
  const navigate = useNavigate();

  useEffect(() => {
    // Scroll to the top when the route changes
    window.scrollTo(0, 0);
  }, []);

  const events = [
    { id: 1, name: "Communication Meet", description: "This is the first event. here is the information about data science in the growth current world", image: "https://sunnysworldpune.com/wp-content/uploads/2021/11/Corporate-Company-visits-at-sunnys-world-Pune-1.jpg" },
    { id: 2, name: "Marathon Challenge", description: "This is the second event. here is the information about data science in the growth current world", image: "https://www.smergers.com/media/businessphoto/27781-1553474791-65ec2aad-b362-496c-8783-f53895e5909c.png" },
    { id: 3, name: "Spedge Live Section", description: "This is the third event. here is the information about data science in the growth current world", image: "https://img.peerspace.com/image/upload/ar_1.75,c_fill,g_auto/w_500,c_scale,dpr_auto,f_auto,q_auto/sd1sfcroyddgxnhjcscc" },
    { id: 4, name: "Spedge Section", description: "This is the fourth event. here is the information about data science in the growth current world", image: "https://d1ih9tlfsfrtid.cloudfront.net/b5kc1RMaSm2BKaOLVftg_image000009.jpg" },
    { id: 5, name: "Fam Dinner", description: "This is the fifth event. here is the information about data science in the growth current world", image: "https://images.squarespace-cdn.com/content/v1/527945dce4b061ca915e1928/1495212957806-7ZGGEOWEWVB2VIS0OI1X/18528052_1524949077537042_551123166007720720_n.jpg?format=2500w" },
  ];

  const handleChange = (data: string) => {
    navigate(`/events/${data}`)
  }

  return (
    <>
      <div className="p-4 bg-white mt-10 relative">

        <div className='absolute w-52 h-52 bg-gray-900 z-30 rounded-full blur-[100px]'></div>
        <div className='absolute w-52 h-52 right-10 bottom-0 z-30 bg-gray-900 rounded-full blur-[100px]'></div>
        <div className='absolute w-52 h-52 right-[50%] top-[40%] z-30 bg-gray-900 rounded-full blur-[100px]'></div>
        <div className='absolute w-52 h-52 right-0 top-10 z-30 bg-gray-900 rounded-full blur-[100px]'></div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-7 md:px-10 py-5 z-20">
          {events.map((event) => (
            <div
              key={event.id}
              onClick={() => handleChange(event.id.toString())}
              className="bg-white hover:scale-[0.95] z-40 cursor-pointer transition-all shadow-2xl rounded-lg overflow-hidden border border-gray-200"
            >
              <img
                src={event.image}
                alt={event.name}
                className="w-full h-52 object-cover"
              />
              <div className="p-4">
                <h3 className="text-lg font-semibold text-gray-800">{event.name}</h3>
                <p className="text-gray-600 mt-2 text-sm">{event.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}


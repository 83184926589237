import { useMutation, useQuery } from "@tanstack/react-query"
import { restAPI } from "./axiosAPI"
import { useState } from "react"
import { backendPagination } from "../../types/pagination"
import { ProductCategoryData } from "./rest.product_category"


export type ProductVariationOption = {
    _id: string;
    M09_name: string;
    M09_M05_product_id: string;
    M09_M08_product_variation_id: string;
    M09_is_active: 1 | 0;
    M09_deleted_at: null | string;
    createdAt: string;
    updatedAt: string;
};
export type ProductVariation = {
    _id: string;
    M08_name: string;
    M08_M05_product_id: string;
    M08_is_active: 1 | 0;
    M08_deleted_at: null | string;
    createdAt: string;
    updatedAt: string;
};

export type ProductSKU = {
    id?: number,
    m06_sku: string,
    m06_m04_product_category: number,
    m06_product_sku_name: string,
    m06_thumbnail_image: string,
    m06_description: string,
    m06_mrp: number,
    m06_price: number,
    m06_quantity: number,
    m06_is_new: 1 | 0,
    m06_single_order_limit: number,
    m06_is_active: 1 | 0,
    created_at: string,
    updated_at: string,
    deleted_at: null | string,
}
export type ProductSkuPostData = {
    Sku: {
        m06_sku: string,
        m06_product_sku_name: string,
        m06_meta_description: string,
        m06_description: string,
        m06_thumbnail_image: string | File,
        m06_mrp: number,
        m06_price: number,
        m06_quantity: number,
        m06_is_new: number,
        m06_single_order_limit: number | null,
        m06_is_active: 1 | 0,
        m06_m04_product_category_id: string,
        Images: ({
            m07_order: number,
            m07_is_active: 1 | 0,
            ImageData: File
        })[]
    }
}
export type ProductSkuReadData = {
    id: string,
    m06_sku: string,
    m06_product_sku_name: string,
    m06_meta_description: string,
    m06_description: string,
    m06_thumbnail_image: string,
    m06_mrp: number,
    m06_price: number,
    m06_quantity: number,
    m06_is_new: boolean,
    m06_single_order_limit: number | null,
    m06_is_active: 1 | 0,
    m06_M05_product_id: string,
    m06_m04_product_category:number,
    Images: Array<{
        id: string,
        m07_image_path: string,
        m07_m06_product_sku_id: string,
        m07_order: number,
        m07_is_active: 1 | 0
    }>,
}


const QUERY_KEY = 'product-sku'

export function useSKUGetAllAPI({
    page_size,
    page,
    retryCount,
    trigger = true,
    productId
}: {
    page_size?: number,
    page?: number,
    productId?:string,
    retryCount?: number,
    trigger?: boolean,
}) {
    const api = restAPI()

    const {
        data: skus,
        isLoading: isLoadingSku,
        isError: isErrorSku,
        refetch: refetchSkus,
        isFetching: isFetchingSku,
        isFetched: isFetchedSku,
        error: errorSku
    } = useQuery({
        queryKey: [QUERY_KEY, page_size, page,productId],
        queryFn: async () => {
            const response = await api.get<{
                data: {
                    products_skus: ProductSKU[],
                    pagination: backendPagination
                }
            }>({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/product-sku`,
                mode: 'protected',
                params: {
                    limit: page_size,
                    page,
                    productId
                },
                withCredentials: true
            })
            if (response.success === false) {
                throw response
            }
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger

    })

    return {
        isLoading: isLoadingSku,
        isErrorSku,
        skus,
        refetch: refetchSkus,
        isFetching: isFetchingSku,
        isFetched: isFetchedSku,
        error: errorSku
    }

}


export function useProductSKUAPI({

}) {

    const api = restAPI()
    



    const { mutateAsync: createProductSKU, isPending: isCreatingProductSKU } = useMutation({


        mutationFn: async ({ ProductSKU }: { ProductSKU: ProductSkuPostData }) => {
            const formData = new FormData();
            Object.keys(ProductSKU.Sku).forEach(key => {
                switch (key) {
                    case 'm06_thumbnail_image':
                        if (ProductSKU.Sku[key] instanceof File) {
                            formData.append(`Sku[${key}]`, ProductSKU.Sku[key] as File);
                        }
                        break;
                    case 'Images':
                        if (ProductSKU.Sku[key] instanceof Array) {
                            ProductSKU.Sku[key].forEach((image, index) => {
                                formData.append(`Sku[${key}][${index}][ImageData]`, image.ImageData);
                                formData.append(`Sku[${key}][${index}][m07_order]`, String(image.m07_order));
                                formData.append(`Sku[${key}][${index}][m07_is_active]`, String(image.m07_is_active));
                            })
                        }
                        break;
                    case 'm06_sku':
                    case 'm06_product_sku_name':
                    case 'm06_description':
                    case 'm06_mrp':
                    case 'm06_price':
                    case 'm06_quantity':
                    case 'm06_single_order_limit':
                    case 'm06_m04_product_category_id':
                    case 'm06_is_active':
                    case 'm06_is_new':
                        // Append all other data as string
                        const value = ProductSKU.Sku[key];
                        formData.append(`Sku[${key}]`, value !== null && value !== undefined ? String(value) : '');
                        break;

                    default:
                        break;
                }
            })

            const response = await api.post<{ access: string, refresh: string }, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/product-sku`,
                data: formData,
                mode: 'protected',
                withCredentials: true
            })


            if (response.success === false) {
                return response
            }
            return response
        }
    })


    const { mutateAsync: deleteProductSKUById, isPending: isDeletingProductSKU } = useMutation({
        mutationFn: async (id: string) => {
            const response = await api.delete({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/product-sku/${id}`,
                mode: 'protected',
                withCredentials: true
            })


            if (response.success === false) {
                return response
            }
            return response
        }
    })

    const { mutateAsync: readProductSKUById, isPending: isReadingProductSKUById } = useMutation({
        mutationFn: async ({
            id
        }: {
            id: string
        }) => {
            const response = await api.get<{ data: ProductSkuReadData }>({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/product-sku/${id}`,
                mode: 'protected',
                withCredentials: true
            })


            if (response.success === false) {
                return response
            }
            return response
        }
    })

    const {
        mutateAsync: updateProductSKUById,
        isPending: isUpdatingProductSKU,
        failureCount: updateProductSkuFailureCount,
        isError: IsupdateProductSkuError,
        error: updateProductSkuError
    } = useMutation({
        mutationFn: async ({
            id,
            data
        }: {
            id: string,
            data: {
                m06_sku: string,
                m06_product_sku_name: string,
                m06_thumbnail_image: File | string,
                m06_description: string,
                m06_mrp: number,
                m06_price: number,
                m06_quantity: number,
                m06_is_new: 1 | 0,
                m06_single_order_limit: number | null,
                m06_m04_product_category_id: string,
                m06_is_active: 1 | 0,
                is_thumnail_new: 1 | 0,
                m06_meta_description: string
            }
        }) => {
            const formData = new FormData()
            formData.append('m06_sku', data.m06_sku)
            formData.append('m06_product_sku_name', data.m06_product_sku_name)
            formData.append('m06_thumbnail_image', data.m06_thumbnail_image)
            formData.append('m06_description', data.m06_description)
            formData.append('m06_mrp', String(data.m06_mrp))
            formData.append('m06_price', String(data.m06_price))
            formData.append('m06_quantity', String(data.m06_quantity))
            formData.append('m06_is_new', String(data.m06_is_new))
            formData.append('m06_single_order_limit', data.m06_single_order_limit ? String(data.m06_single_order_limit) : '')
            formData.append('m06_is_active', String(data.m06_is_active))
            formData.append('m06_m04_product_category_id', String(data.m06_m04_product_category_id))
            formData.append('m06_meta_description', String(data.m06_meta_description))
            formData.append('is_thumnail_new', String(data.is_thumnail_new))

            const response = await api.put<{ access: string, refresh: string }, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/product-sku/${id}`,
                data: formData,
                mode: 'protected',
                withCredentials: true
            })


            if (response.success === false && (response.msg === 'Network Error' || response.msg === 'Server Error') && updateProductSkuFailureCount < 1) {
                throw response.msg
            }
            return response
        },
        retry: 1
    })

    const {
        mutateAsync: reorderImageSKU,
        isPending: isReorderingImageSKU,
        failureCount: reorderImageSKUFailureCount,
        isError: isReorderImageError,
        error: errorReorderImage
    } = useMutation({
        mutationFn: async ({
            skuId,
            Images
        }: {
            skuId: string,
            Images: Array<{
                id: string,
                m07_m06_product_sku_id: string,
                m07_order: number
            }>
        }) => {

            const response = await api.put<{ access: string, refresh: string }, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/product-sku-image-reorder/${skuId}`,
                data: {
                    images: Images
                },
                mode: 'protected',
                withCredentials: true
            })


            if (response.success === false && (response.msg === 'Network Error' || response.msg === 'Server Error') && reorderImageSKUFailureCount < 1) {
                throw response.msg
            }
            return response
        },
        retry: 1
    })

    const { mutateAsync: deleteProductSKUImageById, isPending: isDeletingProductSKUImageById } = useMutation({
        mutationFn: async (id: string) => {
            const response = await api.delete({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/product-sku-image/${id}`,
                mode: 'protected',
                withCredentials: true
            })


            if (response.success === false) {
                return response
            }
            return response
        }
    })

    type ProductSKUImagePostData = {
        skuId: string,
        images: ({
            m07_image_path: File,
            m07_order: number,
            m07_is_active: 1 | 0,
        })[]
    }

    const { mutateAsync: createProductSKUImage, isPending: isCreatingProductSKUImage } = useMutation({


        mutationFn: async (postData: ProductSKUImagePostData) => {
            const formData = new FormData();
            formData.append('skuId', postData.skuId !== null && postData.skuId !== undefined ? String(postData.skuId) : '');

            postData.images.forEach((imageData, index) => {
                Object.keys(imageData).forEach((key) => {
                    switch (key) {
                        case 'm07_image_path':
                            if (imageData[key] instanceof File) {
                                formData.append(`images[${index}][${key}]`, imageData[key] as File);
                            }
                            break;
                        case 'm07_order':
                        case 'm07_is_active':
                            // Append all other data as string
                            formData.append(`images[${index}][${key}]`, imageData[key] ? String(imageData[key] ) : '');
                            break;
                        default:
                            break;
                    }
                })
            })

            const response = await api.post<{data:({
                m07_image_path: string,
                m07_order: number,
                m07_is_active: 1 | 0,
                m07_m06_product_sku_id: string,
                id:string
            })[]}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/product-sku-image`,
                data: formData,
                mode: 'protected',
                withCredentials: true,
                onUploadProgress(progressEvent) {
                    if (progressEvent.lengthComputable) {
                        const percentComplete = (progressEvent.loaded / progressEvent.total!) * 100;
                        console.log(`Upload is ${percentComplete}% done`);
                    }
                },
            })


            if (response.success === false) {
                return response
            }
            return response
        }
    })


    return {
        createProductSKU,
        isCreatingProductSKU,
        deleteProductSKUById,
        isDeletingProductSKU,
        readProductSKUById,
        isReadingProductSKUById,
        updateProductSKUById,
        isUpdatingProductSKU,
        IsupdateProductSkuError,
        updateProductSkuError,
        updateProductSkuFailureCount,
        reorderImageSKU,
        isReorderingImageSKU,
        deleteProductSKUImageById,
        isDeletingProductSKUImageById,
        createProductSKUImage,
        isCreatingProductSKUImage
    }

}


import { IconEdit, IconLoader, IconPlus, IconX } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useProductCategoryAPI, useProductCategoryGetAllAPI } from '../../../../../API/rest.product_category'
import { toast } from '../../../../../../shadcn/ui/use-toast'
import { ModalStandard, ModalStandardContent, ModalStandardDescription, ModalStandardFooter, ModalStandardHeader, ModalStandardTitle } from '../../../../../components/standard.modal'
import { SelectStandard } from '../../../../../components/standard.select'
import { InputStandard, TextAreaStandard } from '../../../../../components/standard.input'
import { Label } from '../../../../../../shadcn/ui/label'
import { ImageBox } from '../../../../../components/image_box'
import { FileInputDragDropStandard } from '../../../../../components/standard.fileDroper'
import { SwitchDemo } from '../../../../../components/standard.switch'
import { ButtonIconSquare } from '../../../../../components/buttons'

type Props = {

    show?: boolean,
    setShow?: (show: boolean) => void,
    refetchProducts: () => void,
    mode: 'add' | 'edit',
    productId?: string | null,
    parentId?: string | null
}
type formData = {
    id?: string,
    m04_category_name: string,
    m04_image: string|null|File,
    m04_m04_parent_category_id: string | null,
    m04_is_active: 1 | 0,
    m04_description?:string,
    m04_deleted_at?: null,
    createdAt?: string,
    updatedAt?: string
}

export function ModalAddProduct(props: Props) {

    const { register, handleSubmit, setValue, reset, formState: { errors }, control, watch } = useForm<formData>({
        defaultValues: {
            m04_category_name: '',
            m04_image: null,
            m04_m04_parent_category_id: null,
            m04_is_active: 1,
        }
    });

    const [selectedImage, setSelectedImage] = useState<File>()
    const [selectedImageLink, setSelectedImageLink] = useState<string>()
    // const [isImageDeleted, setIsImageDeleted] = useState(false)

    const [selectedParentCategory, setSelectedParentCategory] = React.useState<string>();

    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const {
        productCategories,
        isLoading: isProductCategoryLoading,
        isFetching: isProductCategoryFetching,
    } = useProductCategoryGetAllAPI({
        trigger: props.show,
        page:1,
        page_size:200
    })

    const {
        createProductCategory,
        readProductCategoryById,
        updatedProductCategory,
        isDeleting,
        isReadingById,
        isUpdating
    } = useProductCategoryAPI({
        trigger: props.show
    })

    useEffect(
        () => {
            

            if (props.show && props.mode === 'add') {
                reset({
                    m04_category_name: '',
                    m04_image: null,
                    m04_m04_parent_category_id: props.parentId||null,
                    m04_description:'',
                    m04_is_active: 1,
                })
            }
            if (props.mode === 'edit' && props.productId && props.show) {
                readProductCategoryById({
                    id: props.productId
                }).then((response) => {
                    if (response.success === true) {
                        reset({
                            id: String(response.data.id),
                            m04_category_name: response.data.m04_category_name,
                            m04_image: response.data.m04_image?`${process.env.REACT_APP_BACKEND_URL_API_ONLY}/${response.data.m04_image}`:null,
                            m04_m04_parent_category_id: response.data.m04_m04_parent_category_id,
                            m04_is_active: response.data.m04_is_active,
                            m04_description:response.data.m04_description,
                        })
                        setSelectedImageLink(response.data.m04_image?`${process.env.REACT_APP_BACKEND_URL_API_ONLY}/${response.data.m04_image}`:undefined)
                        setSelectedParentCategory(response.data.m04_m04_parent_category_id?response.data.m04_m04_parent_category_id:undefined)
                    } else {
                        toast({
                            description: response.msg,
                            title: "error",
                            // variant:''
                        })
                    }
                })
            }
        }, [props.show]
    )

    const onSubmit = async (data: formData) => {

        setIsSubmitting(true)
        if (props.mode === 'add') {
            const response = await createProductCategory({
                m04_category_name: data.m04_category_name,
                m04_image: selectedImage?selectedImage:null,
                m04_m04_parent_category_id: data.m04_m04_parent_category_id,
                m04_is_active: data.m04_is_active,
                m04_description:data.m04_description
            
            })
            if (response.success === true) {
                toast({
                    description: response.msg,
                    // variant:''
                })
                props.setShow?.(false);
                props.refetchProducts()
            } else {
                toast({
                    description: response.msg,
                    title: "error",
                    // variant:''
                })
            }
        } else if (props.mode === 'edit' && props.productId) {
            const response = await updatedProductCategory({
                id: props.productId,
                ProductCategory: {
                    ...data,
                    m04_image: selectedImage?selectedImage:null,
                    id: Number(data.id)
                },
                isImageDeleted: data.m04_image!==null&&selectedImageLink === undefined && selectedImage === undefined ? 1 : 0
            })
            if (response.success === true) {
                toast({
                    description: response.msg,
                    // variant:''
                })
                props.setShow?.(false);
                props.refetchProducts()
            } else {
                toast({
                    description: response.msg,
                    title: "error",
                    // variant:''
                })
            }
        }
        setIsSubmitting(false)
    };

    const isAnyThingFetching = isProductCategoryLoading || isProductCategoryFetching || isReadingById

    useEffect(()=>{
        if(props.show===false){
            setSelectedParentCategory(undefined)
            setSelectedImage(undefined)
            setSelectedImageLink(undefined)
            // setIsImageDeleted(false)
        }
    },[props.show])

    return (
        <ModalStandard
            open={props.show}
            onOpenChange={props.setShow}
        >
            <ModalStandardContent onOpenAutoFocus={(e) => e?.preventDefault()} className='bg-maxgray-100'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalStandardHeader >
                        <ModalStandardTitle >{props.mode === 'edit' ? 'Edit Category' : 'Add Category'}</ModalStandardTitle>
                        <ModalStandardDescription>
                            {/* loading indication */}
                            {isAnyThingFetching && <div className=' px-2 p-1 rounded-md bg-maxgray-800/30 whitespace-nowrap w-min absolute left-1/2 -translate-x-1/2'>
                                Updating...
                            </div>}
                            <form onSubmit={handleSubmit(onSubmit)} className=" pb-3">
                                <div className='grid gap-4 py-4 xs2:grid-cols-2 lg:grid-cols-2  '>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <SelectStandard
                                            title='Parent Category (optional)'
                                            {...register("m04_m04_parent_category_id",)}
                                            error={errors.m04_m04_parent_category_id?.message}
                                            disabled={isAnyThingFetching || productCategories?.some((category) => category.m04_m04_parent_category_id === props.productId)}
                                            value={selectedParentCategory ? selectedParentCategory : props.parentId||undefined}
                                            options={productCategories?.filter((category) => (category.m04_m04_parent_category_id === null && category.id != props.productId)).map((category) => ({
                                                label: category.m04_category_name,
                                                value: String(category.id)!
                                            })) || []}
                                            onChange={(value) => {
                                                setSelectedParentCategory(value.target.value)
                                                setValue("m04_m04_parent_category_id", value.target.value)
                                            }}
                                        />
                                    </div>

                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <InputStandard
                                            placeholder='name'
                                            title='Category name'
                                            {...register("m04_category_name", { required: "Category  name is required" })}
                                            error={errors.m04_category_name?.message}
                                            disabled={isAnyThingFetching}
                                        />
                                    </div>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <TextAreaStandard
                                            placeholder='description'
                                            title='Description'
                                            {...register("m04_description")}
                                            error={errors.m04_description?.message}
                                            disabled={isAnyThingFetching}
                                        />
                                    </div>
                                    <div className=" lg:col-span-1 xl:col-span-1 ">
                                        <Label className="text-xs font-medium dark:text-vstargray-300 " >
                                            Category Image (optional)
                                        </Label>
                                        {
                                            selectedImageLink ?
                                                <ImageBox
                                                    height={200}
                                                    width={200}
                                                    imagelink={selectedImageLink}
                                                    onDelete={() => {
                                                        setSelectedImageLink(undefined)
                                                        setSelectedImage(undefined)
                                                        
                                                    }}
                                                />
                                                : <FileInputDragDropStandard
                                                    title=' '
                                                    description=' '
                                                    onDrop={(file) => {
                                                        if (file[0].size > 1048576) {
                                                            toast({
                                                                description: 'Image size should not be above 1MB'
                                                            })
                                                            return;
                                                        }
                                                        // File type validation
                                                        const allowedFileTypes = ['image/png', 'image/jpg', 'image/jpeg'];
                                                        if (!allowedFileTypes.includes(file[0].type)) {
                                                            toast({
                                                                description: 'Only PNG, JPG, and JPEG files are allowed'
                                                            });
                                                            return;
                                                        }
                                                        // Create Blob URL for shorter link
                                                        const blobUrl = URL.createObjectURL(file[0]);
                                                        setSelectedImageLink(blobUrl)
                                                        setSelectedImage(file[0]);
                                                    }}
                                                    onClear={() => setSelectedImage(undefined)}
                                                    file={selectedImage}
                                                />
                                        }

                                        {/* <InputStandard
                                            placeholder='Image'
                                            type='file'
                                            title='Category image (optional)'
                                            {...register("m04_image",)}
                                            error={errors.m04_image?.message}
                                            disabled={isAnyThingFetching}
                                        /> */}
                                    </div>

                                </div>
                                <div className=' flex flex-col items-center '>
                                    <div className=' flex flex-col gap-2 items-start'>
                                        <SwitchDemo
                                            {...register("m04_is_active")}
                                            title={'Is Active'}
                                            value={watch('m04_is_active') == 1 ? true : false}
                                            disabled={isAnyThingFetching}
                                        />
                                    </div>

                                </div>
                            </form>
                        </ModalStandardDescription>
                    </ModalStandardHeader>
                    <ModalStandardFooter>
                        <ButtonIconSquare
                            variant='white'
                            icon={<IconX />}
                            text='close'
                            onClick={(e) => { e?.preventDefault(); props.setShow?.(false) }}
                            disabled={isSubmitting}
                        />
                        <ButtonIconSquare
                            variant='secondary'
                            icon={isSubmitting ? <IconLoader className=' animate-spin ' /> : props.mode === 'add' ? <IconPlus /> : <IconEdit />}
                            text={props.mode === 'add' ? 'Add' : 'Edit'}
                            type="submit"
                            disabled={isSubmitting}
                        />
                    </ModalStandardFooter>
                </form>
            </ModalStandardContent>
        </ModalStandard>
    )
}



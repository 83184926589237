
import { IconCloudUp, IconLoader2, IconPlus, IconTrashFilled, IconX } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { StandardTablePagination } from '../../../../../components/standard.pagination'
import { ModalAddProduct } from './component.addModal'
import { DropdownMenuDemo, DropdownMenuParent } from './component.dropdownMenu'
import { Pagination, PaginationKeys } from '../../../../../../types/pagination'
import { useProductCategoryAPI, useProductCategoryGetAllAPI } from '../../../../../API/rest.product_category'
import { toast } from '../../../../../../shadcn/ui/use-toast'
import { StandardAlertDialog } from '../../../../../components/standard.alert-dialog'
import { TablePlater } from '../../../../../components/standard.table'
import { ButtonIconSquare } from '../../../../../components/buttons'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../../../../../shadcn/ui/accordion'
// import { ImportExcelModal } from './component.importExcelModal'

type categoryInstance = {
    category_name: string,
    description: string,
    categroyId: string,
    parent_Id?: string,
    childrens?: categoryInstance[]
}

type ProductCategoryData = {
    id?: string,
    m04_category_name: string,
    m04_image: string | null | File,
    m04_m04_parent_category_id: string | null,
    m04_description?:string
    m04_is_active: 1 | 0,
    m04_deleted_at?: null,
    createdAt?: string,
    updatedAt?: string
};


function buildNestedCategoryTree(categories: ProductCategoryData[]): categoryInstance[] {
    const categoryMap: { [key: string]: categoryInstance } = {};
    const nestedCategories: categoryInstance[] = [];
    // Initialize a map of categories with an empty children array for each
    categories.forEach(category => {
        categoryMap[category.id!] = { category_name: category.m04_category_name, categroyId: category.id!, childrens: [], parent_Id: category.m04_m04_parent_category_id || undefined,description:category.m04_description || '' };
    });

    // Loop through the categories and build the tree
    categories.forEach(category => {
        if (category.m04_m04_parent_category_id) {
            // Find the parent category and add the current category to its children array
            const parentCategory = categoryMap[category.m04_m04_parent_category_id];
            if (parentCategory) {
                parentCategory.childrens?.push({
                    category_name: category.m04_category_name,
                    categroyId: category.id!,
                    childrens: [],
                    parent_Id: category.m04_m04_parent_category_id,
                    description:category.m04_description || ''
                });
            }
        } else {
            // If no parent category, it's a top-level category, push it to the root array
            nestedCategories.push(categoryMap[category.id!]);
        }
    });

    return nestedCategories;
}



export function ProductCategoryPage() {

    const searchParams = new URLSearchParams(window.location.search)
    const currentPageFromUrl = searchParams.get(PaginationKeys.CURRENT_PAGE)
    const dataPerPage = 300
    const [pagination, setPagination] = useState<Pagination>({
        currentPage: currentPageFromUrl ? parseInt(currentPageFromUrl) : 1,
        totalPages: Math.ceil(1 / dataPerPage) || 1,
        dataPerPage: dataPerPage
    })

    const [categoryArray, setCategoryArray] = useState<categoryInstance[]>([])
    const [previousDataLength, setPreviousDataLength] = useState(0)
    const [openAddModal, setOpenAddModal] = useState(false)
    const [selectedProductId, setSelectedProductId] = useState<null | string>()
    const [selectedParentId, setSelectedParentId] = useState<null | string>()
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [mode, setMode] = useState<'add' | 'edit'>('add')
    const [openImportModal, setOpenImportModal] = useState(false)


    const {
        isDeleting,
        deleteProductCategory
    } = useProductCategoryAPI({
        page_size: pagination.dataPerPage,
        page: pagination.currentPage
    })

    const {
        productCategories,
        totalProductCategoryCount,
        error,
        isError,
        isFetched,
        isFetching,
        refetch: refetchProducts
    } = useProductCategoryGetAllAPI({
        page: 1,
        page_size: 200
    })



    const deleteProduct = async () => {

        if (selectedProductId) {
            const response = await deleteProductCategory(selectedProductId)
            if (response.success === true) {
                toast({
                    title: 'Success',
                    description: response.msg,
                })
                refetchProducts()
                setOpenDeleteModal(false)
                setSelectedProductId(null)
            } else {
                toast({
                    title: 'Error',
                    description: response.msg,
                })
            }
        }

    }


    useEffect(
        () => {
            // setting prevouse data length for loading optimization
            if (isFetched && !isFetching) {
                console.log(productCategories?.length)
                setPreviousDataLength(productCategories?.length || 0)
            }

            //if successfully loaded
            if (isFetched && !isError) {
                setPagination({
                    currentPage: currentPageFromUrl ? parseInt(currentPageFromUrl) : 1,
                    totalPages: Math.ceil(totalProductCategoryCount / dataPerPage) || 1,
                    dataPerPage: dataPerPage
                })
                const nestedCategories = buildNestedCategoryTree(productCategories?.map(productCategory => {return {
                    ...productCategory,
                    id: String(productCategory.id)
                }}) || [])
                setCategoryArray(nestedCategories)

            }
            if (isError && isFetching === false) {
                toast({
                    description: String(error),
                })

            }
        }, [isFetching]
    )







    return (
        <div className=' grow  flex flex-col overflow-auto'>
            <StandardAlertDialog
                title="Are you absolutely sure?"
                description="This action cannot be undone. Are you sure you want to delete this Category?"
                positiveLabel="Delete"
                negativeLabel="Cancel"
                positiveAction={deleteProduct}
                negativeAction={() => setOpenDeleteModal(false)}
                positiveIcon={isDeleting ? <IconLoader2 size={16} className=" animate-spin" /> : <IconTrashFilled size={16} />}
                negativeIcon={<IconX size={16} />}
                disableNegativeButton={isDeleting}
                disablePositiveButton={isDeleting}
                show={openDeleteModal}
            />
            <ModalAddProduct
                show={openAddModal}
                setShow={setOpenAddModal}
                refetchProducts={refetchProducts}
                mode={mode}
                productId={selectedProductId}
                parentId={selectedParentId}
            />
            {/* <ImportExcelModal
                show={openImportModal}
                setShow={setOpenImportModal}
            /> */}
            <TablePlater className=' flex flex-col gap-2'>
                {/* Buttons section */}
                <div className=' flex justify-end w-full gap-4'>
                    {/* <ButtonIconSquare variant="white" icon={<IconCloudUp />} text="Import" onClick={() => {  setOpenImportModal(true) }} /> */}
                    <ButtonIconSquare variant="secondary" icon={<IconPlus />} text="Add Category" onClick={() => { setSelectedProductId(undefined); setSelectedParentId(undefined); setMode('add'); setOpenAddModal(true); }} />
                </div>
                {
                    categoryArray.map(
                        (category) => (<Accordion key={category.categroyId} type="single" collapsible className="w-full">
                            <AccordionItem value={category.category_name}>
                                <AccordionTrigger>
                                    <div className=' w-full flex justify-between'>
                                        <div className=' flex flex-col'>
                                            <p>{category.category_name}</p>
                                            <p className=' text-xs text-gray-600'>{category.description}</p>
                                        </div>
                                        <DropdownMenuParent
                                            rowId={category.categroyId}
                                            onAddChildAction={(rowId) => {
                                                setSelectedParentId(category.categroyId);
                                                setMode('add');
                                                setOpenAddModal(true);
                                            }}
                                            onDeleteAction={(rowid) => {
                                                setSelectedParentId(undefined)
                                                setSelectedProductId(rowid);
                                                setOpenDeleteModal(true);
                                            }}
                                            onEditAction={(rowid) => {
                                                setSelectedParentId(undefined)
                                                setSelectedProductId(rowid);
                                                setMode('edit');
                                                setOpenAddModal(true);
                                                console.log('first')
                                            }}
                                        />
                                    </div>
                                </AccordionTrigger>
                                <AccordionContent>
                                    <div className=' flex flex-col'>
                                        {category.childrens?.map(
                                            childCategory => {
                                                return (
                                                    <div className=' flex p-2 justify-between hover:bg-slate-200 ' key={childCategory.categroyId}>
                                                        {childCategory.category_name}
                                                        <DropdownMenuDemo
                                                            rowId={childCategory.categroyId}
                                                            onDeleteAction={(rowid) => {
                                                                setSelectedParentId(undefined)
                                                                setSelectedProductId(rowid);
                                                                setOpenDeleteModal(true);
                                                            }}
                                                            onEditAction={(rowid) => {
                                                                setSelectedParentId(undefined)
                                                                setSelectedProductId(rowid);
                                                                setMode('edit');
                                                                setOpenAddModal(true);
                                                                console.log('first')
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            }
                                        )}
                                    </div>
                                </AccordionContent>
                            </AccordionItem>
                        </Accordion>)
                    )
                }
                {/* <StandardTable
                    headers={headers}
                    rows={productsRow}
                    isLoading={isLoading}
                    rowsPerPrevousePage={previousDataLength}
                    isUpdating={isFetching}
                    isError={isError && isFetching === false}
                    onRetry={() => refetchProducts()}
                /> */}
                <StandardTablePagination currentPage={pagination.currentPage} totalPages={pagination.totalPages} onChangePage={(page) => {
                    setPagination({ ...pagination, currentPage: page })
                }} />

            </TablePlater>
        </div>
    )

}




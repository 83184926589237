import React, { useState, useCallback, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableImageBox from './DraggableImageBox'; // Adjust the path accordingly
import { imageType } from '../component.addModal';

const ImageContainer = ({images,setImages,disabled=false,onDropImage,ondeleteImage}:{ 
    images: imageType[], 
    disabled: boolean,
    setImages: (images: imageType[]) => void,
    ondeleteImage: (index: number) => void,
    onDropImage: (images: imageType[],imagePrev:imageType[],setPrevImages: (images: imageType[]) => any) => void,
}) => {

    const [imagesPrev, setImagesPrev] = useState<imageType[]>([]);


    useEffect(() => {
        setImagesPrev(images);
    },[images?.length])

    const moveImage = useCallback((dragIndex: number, hoverIndex: number) => {
        const updatedImages = [...images];
        const [draggedImage] = updatedImages.splice(dragIndex, 1);
        updatedImages.splice(hoverIndex, 0, draggedImage);
        setImages(updatedImages.map((img, index) => ({ ...img, order: index + 1 })));
    }, [images]);
    const dropImage = useCallback((dragIndex: number, hoverIndex: number) => {
        const updatedImages = [...images];
        const [draggedImage] = updatedImages.splice(dragIndex, 1);
        updatedImages.splice(hoverIndex, 0, draggedImage);
        const imagesWithOrder = updatedImages.map((img, index) => ({ ...img, order: index + 1 }))
        
        onDropImage(imagesWithOrder,imagesPrev,setImagesPrev);
    }, [images]);

    const deleteImage = (index: number) => {
        ondeleteImage(index)
    };

    return (
        <DndProvider backend={HTML5Backend}>
            
            <div className='col-span-1 xs2:col-span-2 xl:col-span-3' style={{ display: 'flex', flexWrap: 'wrap' }}>
                {images
                    .sort((a, b) => a.order - b.order)
                    .map((image, index) => (
                        <DraggableImageBox
                            disabled={disabled}
                            key={image.order}
                            image={image}
                            index={index}
                            moveImage={moveImage}
                            onDelete={deleteImage}
                            dropImage={dropImage}
                        />
                    ))}
            </div>
        </DndProvider>
    );
};

export default ImageContainer;

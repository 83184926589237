import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}



export function validationErrorToObject<T>(arr: { key: string; message: string }[]): T {
  return arr.reduce((acc, { key, message }) => {
    acc[key as keyof T] = message as T[keyof T];
    return acc;
  }, {} as T);
}


import { IconMenuDeep, IconPhonePlus, IconSearch, IconX } from '@tabler/icons-react';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export default function SubNavbar() {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [activeLink, setActiveLink] = useState<string>(''); // Track the active link
    const [navbarBg, setNavbarBg] = useState('bg-transparent'); // Track navbar background
    const navigate = useNavigate();
    const location = useLocation();

    // Handle scrolling to a section
    const handleScrollToSection = (sectionId: string) => {
        if (location.pathname !== '/home') {
            navigate('/home');
            setTimeout(() => {
                const element = document.getElementById(sectionId);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 300); // Adjust delay as needed
        } else {
            const element = document.getElementById(sectionId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };

    // Handle navigation and scroll for "Home"
    const handleNavigate = (path: string, scrollToTop = false) => {
        navigate(path);
        if (scrollToTop) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    // Update active link and navbar background based on location or scroll position
    useEffect(() => {
        const handleScroll = () => {
            const aboutSection = document.getElementById('about');

            // Update active link logic
            if (aboutSection && location.pathname === '/home') {
                const { top, bottom } = aboutSection.getBoundingClientRect();
                if (top <= window.innerHeight / 2 && bottom >= window.innerHeight / 2) {
                    setActiveLink('about');
                } else {
                    setActiveLink('home');
                }
            } else {
                setActiveLink(location.pathname.replace('/', ''));
            }

            // Update navbar background logic
            if (location.pathname === '/home' && window.scrollY < 50) {
                setNavbarBg('bg-transparent');
            } else {
                setNavbarBg('bg-black bg-opacity-70 backdrop-blur-md');
            }
        };

        // Initialize background and active link immediately
        handleScroll();

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [location]);

    const links = [
        { to: '/home', label: 'Home', scrollTo: null },
        { to: '/home', label: 'About Us', scrollTo: 'about' },
        { to: '/Software-solution', label: 'Software-Solutions' },
        { to: '/allproducts', label: 'Products' },
        { to: '/events', label: 'Events & Gallery' },
        { to: '/contact', label: 'Contact Us' },
    ];

    return (
        <div
            className={`h-14 z-[70] fixed top-0 w-full font-poppins py-5 px-6 md:px-10 flex justify-between items-center transition-all duration-300 ease-in-out ${navbarBg}`}
        >
            {/* Logo Section */}
            <div className="flex gap-3 justify-center items-center cursor-pointer" onClick={() => handleNavigate('/home')}>
                <img src="/assets/trioslogo.png" alt="Logo" className="sm:h-7 h-6" />
                <h1 className="text-xl md:text-2xl font-bold text-red-500 font-poppins">Trios International</h1>
            </div>

            {/* Desktop Menu */}
            <div className="hidden md:flex gap-6 font-light text-base text-gray-300 font-poppins">
                {links.map((item) => (
                    <button
                        key={item.to}
                        onClick={() =>
                            item.scrollTo
                                ? handleScrollToSection(item.scrollTo)
                                : handleNavigate(item.to, item.to === '/home')
                        }
                        className={`${
                            activeLink === (item.scrollTo || item.to.replace('/', ''))
                                ? 'text-yellow-500'
                                : ''
                        } hover:text-yellow-400 transition`}
                    >
                        {item.label}
                    </button>
                ))}
                <button>
                    <IconPhonePlus stroke={2} />
                </button>
                <button>
                    <IconSearch stroke={2} />
                </button>
            </div>

            {/* Mobile Menu Button */}
            <button
                className="md:hidden text-white"
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            >
                <IconMenuDeep stroke={2} />
            </button>

            {/* Mobile Menu */}
            <div
                className={`absolute top-0 h-screen left-0 w-full bg-[#000000df] text-white flex flex-col items-center justify-center py-4 gap-4 md:hidden transform ${
                    isMobileMenuOpen ? 'translate-x-0' : 'translate-x-full'
                } transition-transform duration-300 ease-in-out`}
            >
                {/* Close Button */}
                <button
                    className="absolute top-4 right-6 text-yellow-500"
                    onClick={() => setIsMobileMenuOpen(false)}
                >
                    <IconX stroke={2} size={30} />
                </button>
                {links.map((item) => (
                    <button
                        key={item.to}
                        onClick={() => {
                            if (item.scrollTo) {
                                handleScrollToSection(item.scrollTo);
                            } else {
                                handleNavigate(item.to, item.to === '/home');
                            }
                            setIsMobileMenuOpen(false);
                        }}
                        className={`${
                            activeLink === (item.scrollTo || item.to.replace('/', ''))
                                ? 'text-yellow-500'
                                : ''
                        } hover:text-yellow-400 transition`}
                    >
                        {item.label}
                    </button>
                ))}
                <button>
                    <IconPhonePlus stroke={2} />
                </button>
                <button>
                    <IconSearch stroke={2} />
                </button>
            </div>
        </div>
    );
}

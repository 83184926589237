import React from "react";
import Button from "./Button";
import { Success } from "./succesCard";

const AboutUsSection = () => {
    return (
        <div className="bg-white md:py-16 py-5 relative font-poppins" id="about">

            {/* absolute portion  */}
            <div className="h-32 w-32 absolute bg-red-950 rounded-full blur-[80px] top-0 z-10 md:block hidden"></div>
            <div className="h-32 w-32 absolute bg-red-950 rounded-full blur-[100px] top-0 z-10 left-[60%] md:block hidden"></div>
            <div className="h-32 w-32 absolute bg-red-950 rounded-full blur-[80px] right-0 top-52 z-10"></div>
            <div className="h-32 w-32 absolute bg-red-950 rounded-full blur-[80px] left-0 top-96 z-10 md:block hidden"></div>
            <div className="h-32 w-32 absolute bg-red-950 rounded-full blur-[100px] left-[50%] bottom-0 z-10"></div>

        {/* <div className="absolute h-40 top-5 z-0 animate-bounce"  style={{ animationDuration: "30s" }}>
            <img src="/assets/glass_10.png" alt="" />
        </div> */}

            <div className="max-w-7xl mx-auto px-5 md:px-20 grid grid-cols-1 md:grid-cols-3 items-center">
                {/* Left Section: Image (1:1 Aspect Ratio) */}
                <div className="flex text-center justify-center">
                    <div className="flex justify-center items-center  rounded-full aspect-square w-64 md:w-72 relative">
                        <img
                            src="/assets/about_11.jpg"
                            alt="POS Illustration"
                            className="w-[220px] rounded-md md:w-[300px]"
                        />
                    </div>

                </div>



                {/* Right Section: Text (spanning 2 cols) */}
                <div className="md:col-span-2">
                    <div className="mb-2 mt-2 md:mt-0">
                        <Button text="About Us" />
                    </div>
                    <h2 className="text-[20px] md:text-4xl font-extrabold text-black z-20">
                        TRIOS International Computers <br />
                        & Requisites Trading LLC
                    </h2>
                    <div className="mt-4 space-y-2 text-black md:pe-10 z-20">
                        <p>The company is always equipped with the up-to-date international standards in the industry, knowing the swift advancement of technologies.It offers flexible innovative solutions in cost-effective way to the emerging market, edify with modern practice while attaining the exponential growth within a short span of time.TRIOS well experienced, talented and dedicated professionals are committed to deliver outstanding product and unparalleled service to its prospective and valued existing customer with a guaranteed continued client support after sales.</p>
                    </div>
                </div>
            </div>
            <Success/>
        </div>
    );
};

export default AboutUsSection;

import { useMutation, useQuery } from "@tanstack/react-query"
import { restAPI } from "./axiosAPI"
import { useState } from "react"
import { ProductCategoryData } from "./rest.product_category"
import { backendPagination } from "../../types/pagination"





export type Enquiry = {
    id: number,
    m08_name: string,
    m08_mail: string,
    m08_is_read: boolean,
    m08_message: string,
    created_at: string,
    updated_at: string,
    deleted_at: null | string,
}
export type ProductSkuPostData = {
    Sku: {
        m06_sku: string,
        m06_product_sku_name: string,
        m06_meta_description: string,
        m06_description: string,
        m06_thumbnail_image: string | File,
        m06_mrp: number,
        m06_price: number,
        m06_quantity: number,
        m06_is_new: number,
        m06_single_order_limit: number | null,
        m06_is_active: 1 | 0,
        m06_m04_product_category_id: string,
        Images: ({
            m07_order: number,
            m07_is_active: 1 | 0,
            ImageData: File
        })[]
    }
}


const QUERY_KEY = 'product-sku'

export function useEnquiryGetAllAPI({
    page_size,
    page,
    retryCount,
    trigger = true,
    productId
}: {
    page_size?: number,
    page?: number,
    productId?:string,
    retryCount?: number,
    trigger?: boolean,
}) {
    const api = restAPI()

    const {
        data: enquiries,
        isLoading: isLoadingSku,
        isError: isErrorSku,
        refetch: refetchenquiries,
        isFetching: isFetchingSku,
        isFetched: isFetchedSku,
        error: errorSku
    } = useQuery({
        queryKey: [QUERY_KEY, page_size, page,productId],
        queryFn: async () => {
            const response = await api.get<{
                data: {
                    enquiries: Enquiry[],
                    pagination: backendPagination
                }
            }>({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/enquiry-list`,
                mode: 'protected',
                params: {
                    limit: page_size,
                    page,
                    productId
                },
                withCredentials: true
            })
            if (response.success === false) {
                throw response
            }
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger

    })

    return {
        isLoading: isLoadingSku,
        isError: isErrorSku,
        enquiries,
        refetch: refetchenquiries,
        isFetching: isFetchingSku,
        isFetched: isFetchedSku,
        error: errorSku
    }

}


export function useEnquiryAPI({

}) {

    const api = restAPI()
    



    const { mutateAsync: readEnqById, isPending: isReadingProductSKUById } = useMutation({
        mutationFn: async ({
            id
        }: {
            id: string
        }) => {
            const response = await api.get<{ data: Enquiry }>({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/enquiry/${id}`,
                mode: 'protected',
                withCredentials: true
            })


            if (response.success === false) {
                return response
            }
            return response
        }
    })

    const {
        mutateAsync: markAsread,
        isPending: ismarkingAsread,
        failureCount: updatemarkAsreadFailureCount,
        isError: IsupdatemarkAsreadError,
        error: updatemarkAsreadError
    } = useMutation({
        mutationFn: async ({
            id,
        }: {
            id: string,

        }) => {
            const formData = new FormData()
            
            const response = await api.post<{data:Enquiry }, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/enquiry-mark-as-read/${id}`,
                data: formData,
                mode: 'protected',
                withCredentials: true
            })


            if (response.success === false && (response.msg === 'Network Error' || response.msg === 'Server Error') && updatemarkAsreadFailureCount < 1) {
                throw response.msg
            }
            return response
        },
        retry: 1
    })

    return {
        markAsread,
        ismarkingAsread,
    }

}


import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { IconBrandWhatsapp } from '@tabler/icons-react';

interface Product {
  id: number;
  m06_product_sku_name: string;
  m06_description: string;
  m06_price: number;
  m06_thumbnail_image: string;
  m07_sku_image: { m07_image_path: string }[] | undefined;
}

const SingleProductPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [product, setProduct] = useState<Product | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL_CUSTOMER}/product-sku/${id}`);
        if (response.data?.success) {
          setProduct(response.data.data);
          setError(null);
        } else {
          throw new Error('Failed to fetch product details');
        }
      } catch (error) {
        console.error('Error fetching product details:', error);
        setError('Failed to load product details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  if (loading) {
    return <p>Loading product details...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!product) {
    return <p>Product not found.</p>;
  }

  const handleChange = ()=>{
    navigate('/allproducts')
  }

  const baseImageUrl = process.env.REACT_APP_BACKEND_URL;

  // Combine thumbnail image as the first image followed by additional images
  const images = [
    { m07_image_path: product.m06_thumbnail_image }, // Include the thumbnail as the first image
    ...(product.m07_sku_image || []), // Include other images
  ];

  const whatsappNumber = '+971505411910';

  return (
    <div className="single-product-page relative flex flex-col lg:flex-row p-6 bg-[#c0c3c83a] text-white rounded-md shadow-lg overflow-hidden mt-10">
      {/* Blurred Red Divs for Design */}
      {/* <div className="absolute -z-[10] w-52 h-52 bg-gray-500 blur-3xl rounded-full top-10 left-10"></div>
      <div className="absolute -z-[10] w-52 h-52 bg-gray-500 blur-3xl rounded-full top-10 right-10 animate-pulse"></div> */}
      <div className="absolute -z-[10] w-52 h-52 bg-gray-500 blur-3xl rounded-full bottom-10 left-10"></div>
      <div className="absolute -z-[10] w-52 h-52 bg-gray-500 blur-3xl rounded-full bottom-10 right-10"></div>

      {/* Image Gallery Section */}
      <div className="image-gallery w-full lg:w-[50%] flex flex-col-reverse md:flex-row relative">
        {/* Thumbnails */}


        {/* Main Image */}
        <div className="main-image-container flex  md:px-20 p-2 flex-col justify-center gap-2 items-center w-full bg-white bg-opacity-10 backdrop-blur-md rounded-md shadow-inner min-h-[530px] relative">

          {/* absolute portion  */}
          <div className='absolute w-8 h-8 md:h-12 md:w-12 flex justify-center items-center rounded-full bg-red-300 top-2 left-2 cursor-pointer' onClick={()=>handleChange()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-back-up"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 14l-4 -4l4 -4" /><path d="M5 10h11a4 4 0 1 1 0 8h-1" /></svg>
          </div>

          <img
            src={`${baseImageUrl}/${images[selectedImage].m07_image_path}`}
            alt={product.m06_product_sku_name}
            className="main-image w-full max-h-80 max-w-80 mx-auto rounded-md"
          />
          <div className="thumbnails flex justify-center items-center flex-row gap-4 mt-4 md:mt-0 w-full">
            {images.map((image, index) => (
              <img
                key={index}
                src={`${baseImageUrl}/${image.m07_image_path}`}
                alt={`Thumbnail ${index + 1}`}
                className={`thumbnail w-16 h-16 cursor-pointer transition-all duration-300 rounded-md shadow-md hover:scale-110 ${index === selectedImage ? 'border-2 border-[#b96335cb]' : ''
                  }`}
                onClick={() => setSelectedImage(index)}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Product Details Section */}
      <div className="product-details w-full lg:w-[50%] lg:pl-6 flex flex-col gap-6 justify-center items-center ">
        <div className="product-info bg-white bg-opacity-10 backdrop-blur-md p-6 rounded-md shadow-md">
          <h2 className="text-3xl font-bold mb-2 text-[#b96335cb]">{product.m06_product_sku_name}</h2>
          <p className="text-gray-900 mb-4">{product.m06_description}</p>
          <Link
            to={`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
              `✨ *Product Inquiry* ✨\n\n📦 *Product Name:* ${product.m06_product_sku_name}\n🔗 *Product Link:* ${window.location.origin}/product/${product.id}\n\n❓ *Is this product still available?* \n🚚 *Could you let me know if there are any extra shipping charges?* \n\nLooking forward to your response! 😊`
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            className="px-4 py-1 bg-[#d82f1918] text-[#d82f19] flex flex-row justify-center max-w-64 font-bold rounded hover:bg-opacity-80 w-auto round-[16px]"
          >
            <IconBrandWhatsapp size={20} className="mr-2" />
            Order on WhatsApp
          </Link>
        </div>


      </div>
    </div>
  );
};

export default SingleProductPage;

import React from 'react'

export default function Branches() {

    const location = [
        {
            place: 'UAE',
            location: 'nura building 19hk'
        },
        {
            place: 'Oman',
            location: 'nura building 19hk'
        },
        {
            place: 'Qatar',
            location: 'nura building 19hk'
        },
        {
            place: 'Saudi',
            location: 'nura building 19hk'
        },
        {
            place: 'Bahrain',
            location: 'nura building 19hk'
        },
        {
            place: 'Africa',
            location: 'nura building 19hk'
        },
        
    ]

    return (
        <div className='min-h-72 w-full flex flex-col bg-white md:px-20 px-6 pb-40 pt-5'>
            <h1 className='mb-8 md:text-3xl text-2xl'>Our Branches</h1>
            <div className='w-full h-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 md:gap-8 gap-5'>
                {location.map((item) => (
                    <div key={item.place} className='bg-gray-200 rounded-xl flex flex-col justify-center items-center py-5 shadow-lg cursor-pointer'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon mb-3 icon-tabler icons-tabler-outline icon-tabler-map-2"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 18.5l-3 -1.5l-6 3v-13l6 -3l6 3l6 -3v7.5" /><path d="M9 4v13" /><path d="M15 7v5.5" /><path d="M21.121 20.121a3 3 0 1 0 -4.242 0c.418 .419 1.125 1.045 2.121 1.879c1.051 -.89 1.759 -1.516 2.121 -1.879z" /><path d="M19 18v.01" /></svg>
                        <h1 className='font-semibold text-xl'>{item.place}</h1>
                        <p className='text-gray-800 text-xs'>{item.location}</p>
                    </div>
                ))}


            </div>
        </div>
    )
}

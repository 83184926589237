// import { useEnquiryAPI } from '@/app/admin/API/rest.enquiry'
// import { useProductCategoryGetAllAPI } from '@/app/admin/API/rest.product_category'
// import { ButtonIconSquare } from '@/app/admin/components/buttons'
// import { ModalStandard, ModalStandardContent, ModalStandardDescription, ModalStandardFooter, ModalStandardHeader, ModalStandardTitle } from '@/app/admin/components/standard.modal'
// import { toast } from '@/hooks/use-toast'
import { IconEdit, IconLoader, IconPlus, IconX } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { useProductCategoryGetAllAPI } from '../../../../../API/rest.product_category';
import { useEnquiryAPI } from '../../../../../API/rest.enquiry';
import { toast } from '../../../../../../shadcn/ui/use-toast';
import { ModalStandard, ModalStandardContent, ModalStandardDescription, ModalStandardFooter, ModalStandardHeader, ModalStandardTitle } from '../../../../../components/standard.modal';
import { ButtonIconSquare } from '../../../../../components/buttons';

type Props = {

    show?: boolean,
    setShow?: (show: boolean) => void,
    refetchProducts: () => void,
    mode: 'add' | 'edit',
    productId?: string | null,
}
type enqueryData = {
    id: number,
    m08_name: string,
    m08_mail: string,
    m08_is_read: boolean,
    m08_message: string,
}

export function ModalAddProduct(props: Props) {


    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [enquiryData, setEnquiryData] = useState<enqueryData | null>(null)

    const {
        productCategories,
        isLoading: isProductCategoryLoading,
        isFetching: isProductCategoryFetching,
    } = useProductCategoryGetAllAPI({
        trigger: props.show,
        page: 1,
        page_size: 200
    })

    const {
        markAsread,
        ismarkingAsread
    } = useEnquiryAPI({
        trigger: props.show
    })

    useEffect(
        () => {


            if (props.mode === 'edit' && props.productId && props.show) {
                markAsread({
                    id: props.productId
                }).then((response) => {
                    if (response.success === true) {
                        setEnquiryData(response.data)
                    } else {
                        toast({
                            description: response.msg,
                            title: "error",
                            // variant:''
                        })
                    }
                })
            }
            if (props.show) {
                props?.refetchProducts()
            }
        }, [props.show]
    )


    const isAnyThingFetching = isProductCategoryLoading || isProductCategoryFetching || ismarkingAsread


    return (
        <ModalStandard
            open={props.show}
            onOpenChange={props.setShow}
        >
            <ModalStandardContent onOpenAutoFocus={(e) => e?.preventDefault()} className='bg-maxgray-100'>
                <ModalStandardHeader >
                    <ModalStandardTitle >{props.mode === 'edit' ? 'View enquiry' : 'Add Category'}</ModalStandardTitle>
                    <ModalStandardDescription>
                        {/* loading indication */}
                        {isAnyThingFetching && <div className=' px-2 p-1 rounded-md bg-maxgray-800/30 whitespace-nowrap w-min absolute left-1/2 -translate-x-1/2'>
                            Updating...
                        </div>}
                        <div className="max-w-4xl mx-auto p-6 bg-white dark:bg-vstargray-800 shadow-md rounded-lg">
                            <h2 className="text-2xl font-bold text-gray-800 dark:text-vstargray-300 mb-4">Enquiry Details</h2>
                            <div className="grid gap-6 xs2:grid-cols-2 lg:grid-cols-2">
                                <div className="flex items-center">
                                    <p className="text-lg font-medium dark:text-vstargray-300">
                                        <span className="text-gray-500">Name:</span> {enquiryData?.m08_name}
                                    </p>
                                </div>
                                
                                <div className="flex items-center">
                                    <p className="text-lg font-medium dark:text-vstargray-300">
                                        <span className="text-gray-500">Enquiry:</span> {enquiryData?.m08_message}
                                    </p>
                                </div>
                                <div className="flex items-center">
                                    <p className="text-lg font-medium dark:text-vstargray-300">
                                        <span className="text-gray-500">Email:</span> {enquiryData?.m08_mail}
                                    </p>
                                </div>
                                <div className="flex items-center">
                                    <p className="text-lg font-medium dark:text-vstargray-300">
                                        <span className="text-gray-500">Is Read:</span> {enquiryData?.m08_is_read ? 'Yes' : 'No'}
                                    </p>
                                </div>
                            </div>
                        </div>

                    </ModalStandardDescription>
                </ModalStandardHeader>
                <ModalStandardFooter>
                    <ButtonIconSquare
                        variant='white'
                        icon={<IconX />}
                        text='close'
                        onClick={(e) => { e?.preventDefault(); props.setShow?.(false) }}
                        disabled={isSubmitting}
                    />
                    
                </ModalStandardFooter>
            </ModalStandardContent>
        </ModalStandard>
    )
}



import { IconGrowth, IconHandStop, IconHeart, IconMessageCircle } from '@tabler/icons-react';
import React from 'react';
import Footer from './components/Footer';
// import { IconHeart, IconGrowth, IconMessageCircle, IconHandStop } from 'tabler-icons-react';

const About: React.FC = () => {
  return (
    <>
      <div className="font-sans bg-red-200 text-white p-6">
        <header className="text-center md:py-8">
          <h1 className="text-2xl md:text-5xl font-bold text-black">
            We help online businesses grow. <span className="text-red-500">Together.</span>
          </h1>
          {/* <button className="mt-4 text-left bg-red-500 text-white px-6 py-2 text-lg rounded hover:bg-red-600">
            See Open Roles
          </button> */}
        </header>

        <section className="bg-red-300 rounded-lg md:p-6 p-3 md:mt-8 mt-4">
          <h2 className="text-center md:text-3xl text-2xl mb-6">Our Culture</h2>

          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-6 gap-4">
            <div className="bg-red-400 rounded-lg p-4 flex items-center">
              <IconHeart size={40} className="text-red-600 mr-4" />
              <div>
                <h3 className="text-xl font-semibold">Flexible & Understanding</h3>
                <p className="text-sm text-gray-200">We are all remote with collaboration spaces in NY and Miami. Flexibility is important to us.</p>
              </div>
            </div>

            <div className="bg-red-400 rounded-lg p-4 flex items-center">
              <IconGrowth size={40} className="text-red-600 mr-4" />
              <div>
                <h3 className="text-xl font-semibold">Growth & Development</h3>
                <p className="text-sm text-gray-200">There’s a wealth of growth potential to develop your career in the long term.</p>
              </div>
            </div>

            <div className="bg-red-400 rounded-lg p-4 flex items-center">
              <IconMessageCircle size={40} className="text-red-600 mr-4" />
              <div>
                <h3 className="text-xl font-semibold">Open & Transparent</h3>
                <p className="text-sm text-gray-200">Everyone stays informed on all the latest projects, and everyone’s input is welcome.</p>
              </div>
            </div>

            <div className="bg-red-400 rounded-lg p-4 flex items-center">
              <IconHandStop size={40} className="text-red-600 mr-4" />
              <div>
                <h3 className="text-xl font-semibold">Helpful & Supportive</h3>
                <p className="text-sm text-gray-200">As part of our team, you’ll never have to go it alone.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="text-center md:mt-20 mt-10 mb-10 flex flex-wrap gap-5 md:gap-0">
          <div className='md:w-1/2 w-full'>
            <img
              src="https://prymaxhealthcare.com/wp-content/uploads/2022/09/Prymax-healthcare-Team.png"
              alt="Team Placeholder"
              className="rounded-lg w-full max-w-md mx-auto"
            />
          </div>
          <div className='md:w-1/2 w-full text-black text-start'>
            <h1 className='text-2xl mb-4'>Our Story</h1>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus cum tempora a minima reiciendis odio similique dolorum nemo esse fugit, sapiente, repellat ea eius sunt accusantium explicabo voluptatum sed obcaecati!</p>
            <br />

            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur veniam recusandae incidunt odit ad at adipisci, eaque laborum ipsam qui animi cumque culpa voluptatem quo quia excepturi saepe. Ad, atque. Porro architecto non, reprehenderit quam perspiciatis vitae. A suscipit voluptatum quasi, placeat commodi dolor possimus sapiente dolores id tenetur eveniet sed velit quia ex obcaecati praesentium quidem temporibus nesciunt perspiciatis.sapiente dolores id tenetur eveniet sed velit quia </p>

          </div>

        </section>
      </div>
      <Footer />
    </>
  );
};

export default About;

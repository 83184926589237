
// import { AlertDialog, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "@/components/ui/alert-dialog";
import { AlertDialog, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "../../shadcn/ui/alert-dialog";
import { ButtonIconSquare } from "./buttons";
export interface AlertDialogProps {
    show: boolean;
    setShow?: (show: boolean) => void;
    title: string;
    description: string;
    positiveLabel: string;
    positiveIcon?: React.ReactNode;
    negativeLabel: string;
    negativeIcon?: React.ReactNode;
    positiveAction: () => void;
    negativeAction: () => void;
    disablePositiveButton?: boolean;
    disableNegativeButton?: boolean;
}

export function StandardAlertDialog(props: AlertDialogProps) {
    return (
        <AlertDialog open={props.show} onOpenChange={props.setShow}>
            <AlertDialogTrigger asChild>
            </AlertDialogTrigger>
            <AlertDialogContent className=" bg-maxgray-100 ">
                <AlertDialogHeader>
                    <AlertDialogTitle>{props.title}</AlertDialogTitle>
                    <AlertDialogDescription>
                        {props.description}
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    
                        <ButtonIconSquare
                            onClick={props.negativeAction}
                            variant="white"
                            text={props.negativeLabel}
                            icon={props.negativeIcon}
                            disabled={props.disableNegativeButton}  
                        />
                    
                   
                        <ButtonIconSquare
                            onClick={props.positiveAction}
                            variant="secondary"
                            text={props.positiveLabel}
                            icon={props.positiveIcon}
                            disabled={props.disablePositiveButton}
                        />
                    
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}



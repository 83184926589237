import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { IconBrandWhatsapp } from '@tabler/icons-react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import NavbarCategories from './Navbarcategories';

interface Product {
  id: string;
  m06_thumbnail_image: string;
  m06_product_sku_name: string;
  m06_description: string;
  m06_price: number;
  m06_mrp: number;
}

const ProductsSection: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showLeftScroll, setShowLeftScroll] = useState(false);
  const [showRightScroll, setShowRightScroll] = useState(false);

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const whatsappNumber = '+971505411910';

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL_CUSTOMER}/product-sku`,
          { params: { page: 1, limit: 4 } }
        );

        if (response.data?.success) {
          setProducts(response.data.data.products_skus || []);
          setError(null);
        } else {
          throw new Error('Failed to fetch products');
        }
      } catch (error) {
        console.error('Error fetching products:', error);
        setError('Failed to load products. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const checkScroll = () => {
    const container = scrollContainerRef.current;
    if (container) {
      setShowLeftScroll(container.scrollLeft > 0);
      setShowRightScroll(
        container.scrollLeft + container.clientWidth < container.scrollWidth
      );
    }
  };

  const scroll = (direction: 'left' | 'right') => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = direction === 'left' ? -280 : 280;
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener('scroll', checkScroll);
      // Initial check
      checkScroll();
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', checkScroll);
      }
    };
  }, [products]);

  if (loading) return <p>Loading products...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="pt-5 bg-white relative">
      {/* absolute portion */}
      <div className='absolute hidden md:block w-40 h-40 bg-gray-500 z-30 rounded-full blur-[80px]'></div>
      <div className='absolute w-40 h-40 right-10 bottom-24 z-30 bg-gray-500 rounded-full blur-[80px]'></div>
      <div className='absolute w-40 h-40 right-[50%] top-[40%] z-30 bg-gray-500 rounded-full blur-[80px]'></div>
      <div className='absolute font hidden md:block w-40 h-40 right-0 top-10 z-30 bg-gray-500 rounded-full blur-[80px]'></div>

      <div className="py-5">
        <NavbarCategories />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 md:px-10">
        {/* Scroll Navigation Buttons */}
        <div className="relative">
          {showLeftScroll && (
            <button
              onClick={() => scroll('left')}
              className="absolute left-0 top-1/2 -translate-y-1/2 z-10 bg-white/80 rounded-full p-2 shadow-lg"
            >
              <ChevronLeft className="w-6 h-6" />
            </button>
          )}
          {showRightScroll && (
            <button
              onClick={() => scroll('right')}
              className="absolute right-0 top-1/2 -translate-y-1/2 z-10 bg-white/80 rounded-full p-2 shadow-lg"
            >
              <ChevronRight className="w-6 h-6" />
            </button>
          )}

          {/* Scrollable Container */}
          <div
            ref={scrollContainerRef}
            className="flex z-20 md:grid md:grid-cols-4 gap-4 md:gap-8 overflow-x-auto bg-white scrollbar-hide snap-x snap-mandatory rounded-lg"
          >
            {products.map((product) => (
              <div
                key={product.id}
                className="min-w-[280px] z-40 md:min-w-0 bg-blue-50 md:shadow-xl rounded-lg flex flex-col justify-between cursor-pointer snap-start"
                onClick={() => navigate(`/product/${product.id}`)}
              >
                <div className="w-full h-40 p-3">
                  <img
                    src={`${process.env.REACT_APP_BACKEND_URL}/${product.m06_thumbnail_image}`}
                    alt={product.m06_product_sku_name}
                    className="w-full h-full object-cover rounded-lg"
                  />
                </div>
                <div className="px-4 pb-3 flex-grow flex flex-col justify-cente">
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900 mb-1">
                      {product.m06_product_sku_name.substring(0, 13)}
                      {product.m06_product_sku_name.length > 13 ? '...' : ''}
                    </h3>
                    <p className="text-gray-700 my-2 text-sm">
                      {product.m06_description.substring(0, 40)}
                      {product.m06_description.length > 40 ? '...' : ''}
                    </p> 
                  </div>
                  <div>
                    <div className="font-bold flex justify-between items-center mb-2 text-black">
                      <span className="text-sm text-gray-500 line-through">
                        AED {product.m06_mrp.toFixed(2)}
                      </span>
                      <p className="text-lg text-black">AED {product.m06_price.toFixed(2)}</p>
                    </div>
                    <Link
                      to={`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
                        `✨ *Product Inquiry* ✨\n\n📦 *Product Name:* ${product.m06_product_sku_name}\n🔗 *Product Link:* ${window.location.origin}/product/${product.id}\n\n❓ *Is this product still available?* \n🚚 *Could you let me know if there are any extra shipping charges?* \n\nLooking forward to your response! 😊`
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="px-4 py-2 flex justify-center items-center bg-red-800 text-white font-bold rounded-md hover:bg-opacity-80 text-sm"
                    >
                      <IconBrandWhatsapp size={20} className="mr-2" /> Order on WhatsApp
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* <div className="text-end pe-10 mt-6 cursor-pointer underline" onClick={() => navigate('/allproducts')}>
        View all
      </div> */}
    </div>
  );
};

export default ProductsSection;

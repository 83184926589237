// import { ScrollArea } from '@/components/ui/scroll-area'
import { IconCarouselHorizontal, IconCategory, IconChevronsLeft, IconChevronsRight, IconContract, IconHome, IconSettings, IconSquare, IconSquaresFilled, IconVirusSearch } from '@tabler/icons-react'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { useSideNavAPI } from '../API/sideNavAPI'
import { ButtonIconSquare } from './buttons'
import logo from '@/public/logo/logo_only-removebg-preview.png'

import { ScrollArea } from '../../shadcn/ui/scroll-area'

export interface NavbarButtonProps {
    icon: React.ReactNode,
    text: string,
    isSelected: boolean,
    href: string,
    isCollapsed?: boolean
}

export function NavbarButton({
    icon,
    text,
    href,
    isCollapsed
}: NavbarButtonProps) {
    const [isActive, setActive] = React.useState(false)

    return (
        <NavLink to={href} className={(
            state
        ) => {
            setActive(state.isActive)
            return ` ${isCollapsed ? 'justify-center' : 'justify-start px-3'} ${state.isActive ? 'bg-max-green-500 text-white  dark:bg-white/50 shadow-sm' : ''} flex gap-2 items-center  w-full py-1 px-1 hover:bg-max-green-500 dark:hover:bg-white/50 rounded-xl text-xs`
        }}>
            <div className={`${isActive ? 'bg-max-green-500' : 'bg-[#ffffff00]'} w-[30px] h-[30px]  rounded-[13px] flex items-center justify-center overflow-hidden`}>{icon}</div>
            <span className={`text-xs font-medium whitespace-nowrap truncate ${isCollapsed ? 'hidden' : ''} ${isActive ? ' text-white ' : 'text-black'} ${isActive ? ' dark:text-vstargray-100' : 'dark:text-vstargray-300'} `}>{text}</span>
        </NavLink >
    )
}

export default function SideNavbar() {

    const {
        hideSideNav,
        collapseSideNav,
        unCollapseSideNav,
        sideNavState,
        sideNavCollapseState
    } = useSideNavAPI()

    const commonNavBar: NavbarButtonProps[] = [
        {
            icon: <IconHome />,
            text: 'Dashboard',
            isSelected: true,
            href: 'home/dashboard'
        }
    ]
    const MasterSideNavBars: NavbarButtonProps[] = [

        // {
        //     icon: <IconCarouselHorizontal />,
        //     text: 'Carousel',
        //     isSelected: false,
        //     href: '/home/master/carousel'
        // },
        {
            icon: <IconCategory />,
            text: 'Category',
            isSelected: false,
            href: '/admin/home/master/product-category'
        },

        // {
        //     icon: <IconSquare />,
        //     text: 'Product',
        //     isSelected: false,
        //     href: '/home/master/product'
        // },
        {
            icon: <IconSquaresFilled />,
            text: 'SKU',
            isSelected: false,
            href: '/admin/home/master/product-sku'
        },
        {
            icon: <IconVirusSearch />,
            text: 'Featured products',
            isSelected: false,
            href: '/admin/home/master/featured-products'
        },
        {
            icon: <IconContract />,
            text: 'Enquiry',
            isSelected: false,
            href: '/admin/home/master/enquiry'
        },
        // {
        //     icon: <IconArrowDownSquareFilled />,
        //     text: 'Orders',
        //     isSelected: false,
        //     href: 'master/orders'
        // },
    ]

    return (
        <>
            <nav style={{
                left: sideNavState === 'open' ? '0' : '-230px',
                top: 0,
                bottom: 0
            }} className={` transition-all overflow-hidden duration-100 z-50 min-h-screen ${sideNavCollapseState === 'open' ? 'w-[230px] max-w-[230px] min-w-[230px]  px-4' : 'w-[80px] max-w-[80px] min-w-[80px] px-1'} py-1 fixed bg-maxgray-200 sm:bg-transparent  sm:sticky border-r sm:border-none border-vstargray-400 shadow-lg sm:shadow-none`}>
                <ScrollArea type='always' className={`sample h-full w-full  ${sideNavCollapseState === 'open' ? 'pb-14' : ' pb-24'}`}>
                    <div className=' pe-4'>
                        <div className=' w-full flex justify-center py-5 '>
                            {/* <img className=' w-[160px] h-[60px]' style={{display:sideNavCollapseState==='open'?'block':'none'}} src="/assets/logo/logoecom.svg" alt="" /> */}
                            
                            {/* <img className=' w-[40px] object-fill h-[30px]' style={{display:sideNavCollapseState==='close'?'block':'none'}} src="/assets/logo/logoecom.svg" alt="" /> */}
                        </div>
                        <div className='h-[1px] bg-gradient-to-r from-transparent via-white to-transparent rounded-full'></div>
                        <div className=' w-full flex flex-col gap-2 pt-5'>
                            {/* <NavbarButton isSelected={true} icon={<IconHome/>} text='Dashboard' /> */}
                            {
                                commonNavBar.map((item, index) => <NavbarButton  {...item} isCollapsed={sideNavCollapseState === 'close'} key={index} />)
                            }
                        </div>
                        {sideNavCollapseState === 'open' ?
                            <p className=' uppercase text-sm font-semibold px-3  dark:text-vstargray-600 min-h-8 max-h-8 flex items-center'>Master</p>
                            :
                            <p className=' uppercase text-xl font-semibold px-3  dark:text-vstargray-600 text-center min-h-8 max-h-8 flex items-center justify-center'>M</p>}
                        <div className=' w-full flex flex-col gap-2'>
                            {/* <NavbarButton isSelected={true} icon={<IconHome/>} text='Dashboard' /> */}
                            {
                                MasterSideNavBars.map((item, index) => <NavbarButton {...item} isCollapsed={sideNavCollapseState === 'close'} key={index} />)
                            }
                        </div>

                        <div className=' absolute bottom-0 py-2 pe-3 flex justify-between w-full'>
                            <div className={` ${sideNavCollapseState === 'open' ? 'flex-row' : ' flex-col'} flex gap-2 items-center w-full  `}>
                                <IconSettings className=' dark:text-white' />
                                {
                                    sideNavCollapseState === 'open'
                                        ?
                                        <div className=' text-xs grow dark:text-vstargray-100'>Settings</div>
                                        :
                                        null
                                }
                                {sideNavCollapseState === 'open'
                                    ?
                                    <ButtonIconSquare onClick={collapseSideNav} icon={<IconChevronsLeft />} />
                                    :
                                    <ButtonIconSquare onClick={unCollapseSideNav} icon={<IconChevronsRight />} />
                                }
                            </div>
                        </div>
                    </div>
                </ScrollArea>
                {/* bottom buttons */}
            </nav>
            {/* overlay */}
            <div style={{
                zIndex: 10,
                position: 'fixed',
            }} onClick={hideSideNav} className={`fixed inset-0 ${sideNavState === 'open' ? ' block sm:hidden' : ' hidden'}`}></div>
        </>
    )
}

import React, { useEffect } from 'react';
import Footer from './components/Footer';
import { useNavigate } from 'react-router-dom';

const ExpertCards = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Scroll to the top when the route changes
        window.scrollTo(0, 0);
    }, []);

    const handleChange = ()=>{
        navigate('/events')
      }

    const experts = [
        {
            image: "https://www.cvent.com/sites/default/files/styles/column_content_width/public/image/2019-12/48489951752_ff3961bd27_c.jpg?itok=IdsdB7Is",
            position: "top"
        },
        {
            image: "https://www.cvent.com/sites/default/files/styles/column_content_width/public/image/2019-08/Celebrity%20of%20Month.png?itok=bi-MXze1",
            position: "bottom"
        },
        {
            image: "https://theroyalreception.com/assets/img/gallery/events/9.jpg",
            position: "top"
        },
        {
            image: "https://zzeeh.com/wp-content/uploads/2023/02/corporate-events-coordinator.jpg",
            position: "bottom"
        },
        // {
        //   image: "https://via.placeholder.com/150",
        //   position: "top"
        // }
    ];

    return (
        <>
            <div className="w-full max-w-7xl mx-auto px-4 pb-20 relative bg-white md:mt-10 mt-14">
                {/* absolute portion  */}
                <div className='absolute md:flex hidden w-8 h-8 md:h-12 md:w-12 justify-center items-center rounded-full bg-red-300 top-7 left-2 cursor-pointer' onClick={() => handleChange()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-back-up"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 14l-4 -4l4 -4" /><path d="M5 10h11a4 4 0 1 1 0 8h-1" /></svg>
                </div>

                <div className='absolute w-52 h-52 bg-gray-900 z-30 rounded-full blur-[100px]'></div>
                <div className='absolute w-52 h-52 right-10 bottom-0 z-30 bg-gray-900 rounded-full blur-[100px]'></div>
                <div className='absolute w-52 h-52 right-[50%] top-[40%] z-30 bg-gray-900 rounded-full blur-[120px]'></div>
                <div className='absolute w-52 h-52 right-0 top-10 z-30 bg-gray-900 rounded-full blur-[100px]'></div>
                <div className='absolute w-52 h-52 left-0 bottom-0 z-30 bg-gray-900 rounded-full blur-[100px]'></div>

                {/* absolute portion  */}
                {/* <div className="bg-red-300 h-32 w-32 rounded-full blur-[70px] absolute right-0"></div>
            <div className="bg-red-300 h-32 w-32 rounded-full blur-[70px] absolute left-0 top-[300px]"></div>
            <div className="bg-red-300 h-32 w-32 rounded-full blur-[70px] absolute right-0"></div> */}

                <section className="text-center md:mb-10 mb-5 flex flex-wrap gap-5 md:gap-0 z-40">
                    <h1 className="text-center w-full font-semibold md:text-3xl text-2xl md:py-12 pt-5">
                        Our Experts are like no other
                    </h1>
                    <div className='md:w-1/2 w-full z-40'>
                        <img
                            src="https://zzeeh.com/wp-content/uploads/2023/02/corporate-events-planning-companies.jpg"
                            alt="Team Placeholder"
                            className="rounded-lg w-full max-w-md mx-auto"
                        />
                    </div>
                    <div className='md:w-1/2 w-full text-black text-start pe-5'>
                        <h1 className='text-2xl mb-4'>Event Name</h1>
                        <p className='z-40'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus cum tempora a minima reiciendis odio similique dolorum nemo esse fugit, sapiente, repellat ea eius sunt accusantium explicabo voluptatum sed obcaecati!</p>
                        <br />

                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur veniam recusandae incidunt odit ad at adipisci, eaque laborum ipsam qui animi cumque culpa voluptatem quo quia excepturi saepe. Ad, atque. Porro architecto non, reprehenderit quam perspiciatis vitae. A suscipit voluptatum quasi, placeat commodi dolor possimus sapiente dolores id tenetur eveniet .</p>

                    </div>

                </section>
                <h1 className="text-center w-full font-semibold md:text-3xl text-2xl py-5 z-40">
                    Our Experts are like no other
                </h1>
                <p className="text-center text-black mb-12 max-w-3xl mx-auto z-40">
                    Partner with our team of scientists, thinkers and industry experts to take your talent strategy to the next level. We have consulted with the world's top minds and created innovative solutions that transform talent.
                </p>

                <div className="hidden md:block relative">
                    <div className="grid grid-cols-4 md:px-20 gap-6">
                        {experts.map((expert, index) => (
                            <div
                                key={index}
                                className={`transform ${expert.position === "top"
                                    ? "-translate-y-8"
                                    : "translate-y-8"
                                    }`}
                            >
                                <div className="bg-white z-40 rounded-lg overflow-hidden shadow-lg transition-transform duration-300 hover:scale-105">
                                    <img
                                        src={expert.image}
                                        alt={`Expert ${index + 1}`}
                                        className="w-full h-64 object-cover z-40"
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Mobile layout */}
                <div className="md:hidden grid grid-cols-1 sm:grid-cols-2 gap-6">
                    {experts.map((expert, index) => (
                        <div key={index} className="bg-white rounded-lg overflow-hidden shadow-lg">
                            <img
                                src={expert.image}
                                alt={`Expert ${index + 1}`}
                                className="w-full h-64 object-cover z-40"
                            />
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ExpertCards;
// import { setSideNavigation, setSideNavigationCollapse } from "@/redux/configuration.slice/configuration.slice"
// import { RootState } from "@/redux/store"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import { setSideNavigation, setSideNavigationCollapse } from "../../redux/configuration.slice/configuration.slice"
// import { setSideNavigation, setSideNavigationCollapse } from "../redux/configuration.slice/configuration.slice"
// import { RootState } from "@/redux/store"

export  function useSideNavAPI() {

    // const configuration = useSelector((state: RootState) => state.configuration)
    const dispatch = useDispatch()
    const sideNavState = useSelector((state: RootState) => state.configuration.sideNav.isOpen)
    const sideNavCollapseState = useSelector((state: RootState) => state.configuration.sideNav.isCollapsed)



    const showSideNav = ()=>{
        console.log('second')
        dispatch(setSideNavigation('open'))
    }

    const hideSideNav = ()=>{
        dispatch(setSideNavigation('close'))
    }
    const collapseSideNav = ()=>{
        dispatch(setSideNavigationCollapse('close'))
    }

    const unCollapseSideNav = ()=>{
        dispatch(setSideNavigationCollapse('open'))
    }


    return {
        showSideNav,
        hideSideNav,
        collapseSideNav,
        unCollapseSideNav,
        sideNavState,
        sideNavCollapseState
    }

}

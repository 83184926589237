import { useGetAdminByToken } from '../API/rest.authentication';

export const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {

    const {
        isgetAdminbyTokenError,
        isgetAdminbyTokenLoading,
        isgetAdminbyTokenFetching,
    } = useGetAdminByToken({trigger:true});

    console.log('error is',isgetAdminbyTokenError)

    if(isgetAdminbyTokenError||isgetAdminbyTokenLoading||isgetAdminbyTokenFetching){
        return <div className=" fixed inset-0 flex items-center justify-center">L</div>
    }

    if(!isgetAdminbyTokenError && !isgetAdminbyTokenLoading && !isgetAdminbyTokenFetching){
        // dispatch(setadminDetails(adminData?.data!))
    }

    return <>{children}</>;
}
import React from 'react';
import { Link } from 'react-router-dom';
import {
    IconBrandFacebook,
    IconBrandInstagram,
    IconBrandWhatsapp,
} from '@tabler/icons-react';

const Footer: React.FC = () => {

    const number = "+971505411910";
    const whatsappLink = `https://wa.me/${number.replace('+', '')}?text=hlo`;
    
    return (
        <footer className="w-full bg-gray-200 z-40">
            {/* Footer Bottom */}
            <div className="py-4 z-40 text-center text-gray-600 items-center justify-between mx-3 flex flex-col md:flex-row">
                <p>Trios &copy; 2025 All Rights Reserved</p>
                <div className='flex items-end gap-1 md:gap-5 flex-col md:flex-row'>
                    <div className="flex justify-center items-center md:items-baseline space-x-4 mt-4">
                        <a href={whatsappLink} className="text-white bg-black rounded-full p-1 hover:text-[#ffffffd2]" target="_blank" rel="noopener noreferrer">
                            <IconBrandWhatsapp size={20} />
                        </a>
                        <Link to="#" className="text-white bg-black rounded-full p-1 hover:text-[#ffffffd2]">
                            <IconBrandFacebook size={20} />
                        </Link>
                        <Link to="#" className="text-white bg-black rounded-full p-1 hover:text-[#ffffffd2]">
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" fill='white' viewBox="0 0 30 30">
                                <path d="M26.37,26l-8.795-12.822l0.015,0.012L25.52,4h-2.65l-6.46,7.48L11.28,4H4.33l8.211,11.971L12.54,15.97L3.88,26h2.65 l7.182-8.322L19.42,26H26.37z M10.23,6l12.34,18h-2.1L8.12,6H10.23z"></path>
                            </svg>
                        </Link>
                        <Link to="#" className="text-white bg-black rounded-full p-1 hover:text-[#ffffffd2]">
                            <IconBrandInstagram size={20} />
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

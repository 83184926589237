import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Homepage from './Homepage';
import { AlertTriangle } from 'tabler-icons-react';
import SolutionPage from './SolutionPage';
import Allproducts from './Allproducts';
import SingleProductPage from './SingleProductPage';
import Events from './components/events';
import About from './about';
import Contact from './contact';
import Eventdetails from './Eventdetails';
import { AdminRoot } from './admin/adminRoot';
import SubNavbar from './components/subNavbar';
import FormContact from './components/ContactForm';

const NotFound: React.FC = () => (
  <div className="flex items-center justify-center h-screen bg-gray-900 text-white">
    <div className="text-center flex flex-col items-center">
      <AlertTriangle size={54} className="mb-4 text-red-100" />
      <div className="font-bold text-2xl text-red-100">
        404 - Page Not Found
      </div>
    </div>
  </div>
);

const App: React.FC = () => {
  return (
    <Router>
      {/* <Navbar /> */}
      <SubNavbar />
      <div>
        <Routes>
          {/* Redirect / to /home */}
          <Route path="/" element={<Navigate to="/home" />} />

          {/* Home Route */}
          <Route path="/home" element={<Homepage />} />

          {/* Other Routes */}
          <Route path="/Software-solution" element={<SolutionPage />} />

          {/* Dynamic Allproducts Route */}
          <Route path="/allproducts/:categoryId" element={<Allproducts />} />
          <Route path="/allproducts" element={<Allproducts />} />

          {/* Single Product Page Route */}
          <Route path="/product/:id" element={<SingleProductPage />} />
          <Route path="/events" element={<Events />} />
          <Route path="/events/:id" element={<Eventdetails />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/contactform" element={<FormContact />} />

          <Route path='/admin/*' element={<AdminRoot/>} />
          {/* Not Found Route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;

import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {
  TruckDelivery,
  BuildingWarehouse,
  BuildingFactory,
  ClipboardList,
  BuildingHospital,
} from "tabler-icons-react";
import Footer from "./components/Footer";

gsap.registerPlugin(ScrollTrigger);

const solutions = [
  {
    title: "Retail Management",
    description: "Comprehensive tools to streamline retail operations.",
    icon: <BuildingWarehouse size={48} className="text-primary mx-auto" />,
  },
  {
    title: "Supply Chain Management",
    description: "Optimize your supply chain with advanced analytics.",
    icon: <TruckDelivery size={48} className="text-primary mx-auto" />,
  },
  {
    title: "Manufacturing Management",
    description: "Solutions to enhance manufacturing efficiency.",
    icon: <BuildingFactory size={48} className="text-primary mx-auto" />,
  },
  {
    title: "Service Management",
    description: "Tools for delivering exceptional customer service.",
    icon: <ClipboardList size={48} className="text-primary mx-auto" />,
  },
  {
    title: "Hospitality Management",
    description: "Streamline operations for hotels and hospitality.",
    icon: <BuildingHospital size={48} className="text-primary mx-auto" />,
  },
];

const SoftwareSolutionPage: React.FC = () => {
  const cardsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    window.scrollTo(0, 0);

    if (cardsRef.current) {
      const cards = cardsRef.current.querySelectorAll(".solution-card");
      gsap.fromTo(
        cards,
        {
          opacity: 0,
          y: 50,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 0.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: cardsRef.current,
            start: "top 80%",
            end: "bottom 20%",
            scrub: false,
          },
        }
      );
    }
  }, []);

  return (
    <>
    <div className="bg-gray-100 min-h-screen px-4 py-8 mt-10">
      {/* Header Section */}
      <div className="text-center mb-10">
        <h1 className="text-2xl md:text-4xl font-bold text-gray-800">
          Our Software Solutions
        </h1>
        <p className="text-gray-600 mt-4">
          Empowering businesses with cutting-edge management tools.
        </p>
      </div>

      {/* Cards Section */}
      <div
        ref={cardsRef}
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl mx-auto"
      >
        {solutions.map((solution, index) => (
          <div
            key={index}
            className="solution-card bg-white rounded-lg shadow-lg p-6 hover:shadow-2xl transition duration-300"
          >
            <div className="text-center">
              {solution.icon}
              <h2 className="text-xl font-semibold mt-4 text-gray-800">
                {solution.title}
              </h2>
              <p className="text-gray-600 mt-2">{solution.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default SoftwareSolutionPage;

import { useMutation, useQuery } from "@tanstack/react-query"
import { restAPI } from "./axiosAPI"

export type FeaturedProductData = {
    id: number,
    p01_m06_product_id: number,
    p01_is_active: boolean,
    p01_order: number,
    created_at: string,
    updated_at: string,
    deleted_at: null,
    P01_M06_product_id: {
        id: number,
        m06_sku: string,
        m06_m04_product_category: number,
        m06_product_sku_name: string,
        m06_thumbnail_image: string,
        m06_description: string,
        m06_mrp: number,
        m06_price: number,
        m06_quantity: number,
        m06_is_new: number,
        m06_single_order_limit: null | number,
        m06_is_active: number,
        created_at: string,
        updated_at: string,
        deleted_at: null
    }
}


const QUERY_KEY = '/featured-products'



export function useFeaturedProductGetAllAPI({
    retryCount,
    trigger = true
}: {
    retryCount?: number,
    trigger?: boolean
}) {
    const api = restAPI()

    const {
        data: featuredProducts,
        isLoading,
        isError,
        refetch: refetchfeaturedProducts,
        isFetching,
        isFetched,
        error
    } = useQuery({
        queryKey: [QUERY_KEY],
        queryFn: async () => {
            const response = await api.get<{ data: FeaturedProductData[] }>({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/featured-products`,
                mode: 'protected',
                withCredentials: true
            })
            if (response.success === false) {
                throw response.msg
            }
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger

    })

    return {
        isLoading,
        isError,
        featuredProducts,
        refetch: refetchfeaturedProducts,
        isFetching,
        isFetched,
        error
    }

}

export function useFeaturedProductGetAPI() {

    const api = restAPI()

    const { mutateAsync: reorderFeaturedProductAPI, isPending: isReordering } = useMutation({

        mutationFn: async (featuredProductId: string[]) => {
            const requestBody = {
                featuredProducts: featuredProductId.map(id => ({ id: id }))
            };
            const response = await api.put<{}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/featured-product-reorder`,
                data: requestBody,
                mode: 'protected',
                withCredentials: true
            });

            if (response.success === false) {
                return response;
            }
            return response;
        }
    })


        const { mutateAsync: createFeaturedProduct, isPending: isCreatingFeaturedProduct } = useMutation({
            mutationFn: async ({ p01_product_id, p01_is_active }: { p01_product_id: string, p01_is_active: number }) => {
                const requestData = {
                    p01_product_id,
                    p01_is_active,
                };
    
                const response = await api.post<{}, {}>({
                    url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/featured-product`,
                    data: requestData,
                    mode: 'protected',
                    withCredentials: true,
                });
    
                if (response.success === false) {
                    return response;
                }
    
                return response;
            },
        });
    
        
    const { mutateAsync: deleteFeaturedProduct, isPending: isDeletingCarousel } = useMutation({
        mutationFn: async (id: string) => {
            const response = await api.delete({
                url: `${process.env.REACT_APP_BACKEND_URL_API}/v1/admin/featured-product/${id}`,
                mode: 'protected',
                withCredentials: true
            });

            if (response.success === false) {
                return response;
            }
            return response;
        }
    });
    



    return {
        reorderFeaturedProductAPI,
        isReordering,
        createFeaturedProduct,
        isCreatingFeaturedProduct,
        deleteFeaturedProduct,
        isDeletingCarousel,
        // updatedProductCategory,
        // isUpdating,
        // readProductCategoryById,
        // isReadingById,
        // deleteProductCategory,
        // isDeleting,
    }


}


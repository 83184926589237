"use client"

import * as React from "react"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import { X } from "lucide-react"
import { cn } from "../../lib/utils"


const ModalStandard = DialogPrimitive.Root

const ModalStandardTrigger = DialogPrimitive.Trigger

const ModalStandardPortal = DialogPrimitive.Portal

const ModalStandardClose = DialogPrimitive.Close

const ModalStandardOverlay = React.forwardRef<
    React.ElementRef<typeof DialogPrimitive.Overlay>,
    React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
    <DialogPrimitive.Overlay
        ref={ref}
        className={cn(
            "fixed inset-0 z-50 bg-black/80  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
            className
        )}
        {...props}
    />
))
ModalStandardOverlay.displayName = DialogPrimitive.Overlay.displayName

const ModalStandardContent = React.forwardRef<
    React.ElementRef<typeof DialogPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & { hideCloseButton?: boolean }
>(({ className, children, hideCloseButton, ...props }, ref) => (
    <ModalStandardPortal>
        <ModalStandardOverlay />
        <DialogPrimitive.Content
            ref={ref}
            className={cn(
                "fixed left-[50%] top-[50%] z-50 grid w-full  overflow-hidden  translate-x-[-50%] translate-y-[-50%] gap-4 border bg-background  shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg",
                className,
                'max-w-[90vw] md:max-w-xl lg:max-w-2xl xl:max-w-3xl '
            )}
            {...props}
        >
            {children}
            {!hideCloseButton && (
                <DialogPrimitive.Close className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
                    <X className="h-4 w-4" />
                    <span className="sr-only">Close</span>
                </DialogPrimitive.Close>
            )}
        </DialogPrimitive.Content>
    </ModalStandardPortal>
))
ModalStandardContent.displayName = DialogPrimitive.Content.displayName

const ModalStandardHeader = ({
    className,
    ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
    <div
        className={cn(
            "flex flex-col space-y-1.5 text-center sm:text-left",
            className
        )}
        {...props}
    />
)
ModalStandardHeader.displayName = "ModalStandardHeader"

const ModalStandardFooter = ({
    className,
    ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
    <div
        className={cn(
            "flex flex-row justify-end sm:space-x-2 p-2 md:px-6 border-t border-vstargray-400",
            className
        )}
        {...props}
    />
)
ModalStandardFooter.displayName = "ModalStandardFooter"

const ModalStandardTitle = React.forwardRef<
    React.ElementRef<typeof DialogPrimitive.Title>,
    React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
    <DialogPrimitive.Title
        ref={ref}
        className={cn(
            "text-lg font-semibold leading-none tracking-tight py-3 px-2 md:px-6 border-b border-vstargray-500 ",
            className
        )}
        {...props}
    />
))
ModalStandardTitle.displayName = DialogPrimitive.Title.displayName

const ModalStandardDescription = React.forwardRef<
    React.ElementRef<typeof DialogPrimitive.Description>,
    React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
    <DialogPrimitive.Description
        ref={ref}
        className={cn("text-sm text-muted-foreground max-h-[80vh] overflow-auto py-1 px-2 md:px-6", className)}
        {...props}
    />
))
ModalStandardDescription.displayName = DialogPrimitive.Description.displayName

export {
    ModalStandard,
    ModalStandardPortal,
    ModalStandardOverlay,
    ModalStandardClose,
    ModalStandardTrigger,
    ModalStandardContent,
    ModalStandardHeader,
    ModalStandardFooter,
    ModalStandardTitle,
    ModalStandardDescription,
}




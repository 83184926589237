export enum PaginationKeys {
    CURRENT_PAGE = 'currentPage',
    TOTAL_PAGES = 'totalPages',
    DATA_PER_PAGE = 'dataPerPage',
    SEARCH_TERM = 'searchTerm',
}

export type Pagination = {
    [PaginationKeys.CURRENT_PAGE]: number,
    [PaginationKeys.TOTAL_PAGES]: number,
    [PaginationKeys.DATA_PER_PAGE]: number,
    [PaginationKeys.SEARCH_TERM]?: string,
}


export type backendPagination = {
    currentPage: number,
    totalPages: number,
    totalItems: number,
    limit: number,
}
